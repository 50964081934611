import {createFieldSelector, identityFunction} from "../utils";
import {createSelector} from "reselect";

export const selectVoucherState = createSelector(
    (state) => state.voucher
    , identityFunction
);

export const selectVoucherVon = createFieldSelector(selectVoucherState, 'von');
export const selectVoucherFuer = createFieldSelector(selectVoucherState, 'fuer');
export const selectVoucherBetrag = createFieldSelector(selectVoucherState, 'betrag');
export const selectVoucherEmail = createFieldSelector(selectVoucherState, 'email');
export const selectVoucherMessage = createFieldSelector(selectVoucherState, 'message');
export const selectIsVoucherLoadedFromCheckout = createFieldSelector(selectVoucherState, 'loadedFromCheckout');
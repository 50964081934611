import {createFieldSelector, identityFunction} from "../utils";

const BACKEND = process.env.REACT_APP_BASEURL;
const GUTSCHEINVORLAGEN_TYP = 3

const {createSelector} = require("reselect");

export const selectCommonState = createSelector(
    (state) => state.common
    , identityFunction
);

export const selectUserID = createFieldSelector(selectCommonState, 'UserID');
export const selectIsStudioLoaded = createSelector(
    selectUserID
    , userId => !!userId
);

export const selectDB = createFieldSelector(selectCommonState, 'db');
export const selectStudioimageBasePath = createSelector(
    selectUserID
    , (userId) => userId && `${BACKEND}/studio/studioimage`
)
export const selectHeaderUrl = createSelector(
    selectUserID
    , (userId) => `${BACKEND}/static/${userId}/Header.jpg`
)
export const selectTheming = createFieldSelector(selectCommonState, 'theming');
export const selectThemingMode = createFieldSelector(selectTheming, 'mode');
export const selectRubrikenMap = createFieldSelector(selectCommonState, 'rubriken');

export const selectRubrikenList = createSelector(
    selectRubrikenMap
    , rubriken => rubriken ? Object.values(rubriken) : []
);

export const selectTerminArtMap = createFieldSelector(selectCommonState, 'terminarten');
export const selectProduktMap = createFieldSelector(selectCommonState, 'produkte');
export const selectProduktList = createSelector(
    selectProduktMap
    , map => map && Object.values(map).sort((p1, p2) => p1.Sort - p2.Sort) || []
);

export const selectArtistMap = createFieldSelector(selectCommonState, 'allArtists');
export const selectStudio = createFieldSelector(selectCommonState, 'studio');
export const selectTextDeseases = createFieldSelector(selectStudio, 'TextDeseaseOnline');
export const selectIsPaypalAllowed = createSelector(
    selectStudio
    , studio => !!studio?.PaypalAllowed && !!studio?.PaypalClientId
)
export const selectIsSofortAllowed = createSelector(
    selectStudio
    , studio => !!studio?.SofortAllowed
)
export const selectStudioHasPaymentProvider = createSelector(
    selectIsPaypalAllowed
    , selectIsSofortAllowed
    , (isPaypalAllowed, isSofortAllowed) => isPaypalAllowed || isSofortAllowed
)
export const selectLanguage = createFieldSelector(selectCommonState, 'language');
export const selectStudioTatorte = createFieldSelector(selectStudio, 'tatorte');
export const selectVorlagen = createFieldSelector(selectStudio, 'pdfvorlagen');
export const selectGutscheinVorlagen = createSelector(
    selectVorlagen
    , vorlagen => vorlagen && vorlagen.filter(t => t.Typ === GUTSCHEINVORLAGEN_TYP)
)
export const selectStudioTimezone = createFieldSelector(selectStudio, 'Timezone');
export const selectCurrency = createFieldSelector(selectStudio, 'Waehrung');
export const selectCheckVerbindungOngoing = createFieldSelector(selectCommonState, 'checkVerbindungOngoing');
export const selectVoucherStatus = createFieldSelector(selectCommonState, 'voucherStatus')


import React, {useEffect, useState} from "react";
import Voucher from "./Voucher";
import {useDispatch, useSelector} from "react-redux";
import {selectVoucherStatus} from "../../../store/common/commonSelectors";
import history from "../../../history";
import {
    selectVoucherBetrag,
    selectVoucherEmail,
    selectVoucherFuer,
    selectVoucherMessage,
    selectVoucherVon
} from "../../../store/voucher/voucherSelectors";
import {selectEmail, selectVorname} from "../../../store/kunde/kundeSelectors";
import {
    changeVoucherBetrag,
    changeVoucherEmail,
    changeVoucherFuer,
    changeVoucherMessage,
    changeVoucherVon
} from "../../../store/voucher/voucherActions";
import {createVoucherspayment} from "../../../store/checkout/checkoutActions";
import {selectStateLoaded} from "../../../store/appointment/appointmentSelectors";
import {useTranslation} from "react-i18next";
import {newBooking} from "../../../store/appointment/appointmentActions";
import {selectIsPaymentClosed} from "../../../store/checkout/checkoutSelector";

const MINDESTBETRAG = 20

const VoucherContainer = () => {
    const [vonTouched, setVonTouched] = useState(false);
    const [fuerTouched, setFuerTouched] = useState(false);
    const [emailTouched, setemailTouched] = useState(false);
    const voucherStatus = useSelector(state => selectVoucherStatus(state))
    const von = useSelector(state => selectVoucherVon(state))
    const fuer = useSelector(state => selectVoucherFuer(state))
    const betrag = useSelector(state => selectVoucherBetrag(state))
    const email = useSelector(state => selectVoucherEmail(state))
    const kundenEmail = useSelector(state => selectEmail(state))
    const kundenVorname = useSelector(state => selectVorname(state))
    const message = useSelector(state => selectVoucherMessage(state))
    const stateLoaded = useSelector(state => selectStateLoaded(state))
    const isPaymentClosed = useSelector(state => selectIsPaymentClosed(state))
    const isStateLoaded = useSelector(state => selectStateLoaded(state))
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        if (voucherStatus?.length) {
            history.push({
                pathname: 'config-error',
                search: "?" + new URLSearchParams({error: voucherStatus[0]}).toString()
            })
        }
    }, [voucherStatus])
    useEffect(() => {
        if (!email?.length && !!kundenEmail?.length && !emailTouched) {
            dispatch(changeVoucherEmail(kundenEmail))
        }

    }, [email, kundenEmail, emailTouched, dispatch])
    useEffect(() => {
        if (!email && !!kundenVorname) {
            dispatch(changeVoucherVon(kundenVorname))
        }

    }, [von, kundenVorname, dispatch])

    useEffect(() => {
        if (isPaymentClosed && isStateLoaded) {
            newBooking()
        }
    }, [isPaymentClosed, isStateLoaded])

    const vonPar = {
        value: von,
        setValue: e => dispatch(changeVoucherVon(e)),
        label: t('VOUCHER_VON'),
        touched: vonTouched,
        onBlur: () => setVonTouched(true)
    };

    const fuerPar = {
        value: fuer,
        setValue: e => dispatch(changeVoucherFuer(e)),
        label: t('VOUCHER_FUER'),
        touched: fuerTouched,
        onBlur: () => setFuerTouched(true)
    };

    const emailPar = {
        value: email,
        setValue: e => dispatch(changeVoucherEmail(e)),
        label: t('VOUCHER_EMAIL'),
        touched: emailTouched,
        onBlur: () => setemailTouched(true)
    };

    const changeAmount = (amount) => {
        dispatch(changeVoucherBetrag(betrag + amount))
    }

    const canChangeAmount = (amount) => {
        return amount + betrag >= MINDESTBETRAG
    }

    const changeMessage = msg => dispatch(changeVoucherMessage(msg))

    const buyVoucher = () => {
        dispatch(createVoucherspayment())
    }
    return (
        <React.Fragment>
            {stateLoaded ? <Voucher vonPar={vonPar} fuerPar={fuerPar} emailPar={emailPar} changeAmount={changeAmount}
                                    canChangeAmount={canChangeAmount} betrag={betrag}
                                    message={message} changeMessage={changeMessage} buyVoucher={buyVoucher}/> : null}
        </React.Fragment>

    )
}

export default VoucherContainer

import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Typography, useMediaQuery} from "@mui/material";


const useStyles = makeStyles(theme => ({
    readMore: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
}))

const MAX_LINES = {
    xs: {
        maxStringLength: 200,
        reducedLength: 150
    },
    sm: {
        maxStringLength: 300,
        reducedLength: 280
    },
    md: {
        maxStringLength: 400,
        reducedLength: 350
    }
}

const ExpandableText = ({beschreibung, theme}) => {
    const [isSmall, setIsSmall] = useState(true)
    const classes = useStyles(theme)

    const isTablet = useMediaQuery(theme.breakpoints.only('sm'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    const maxLines = !isTablet && !isDesktop ? MAX_LINES.xs : !isDesktop ? MAX_LINES.sm : MAX_LINES.md

    const getText = () => {
        return isSmall && beschreibung && beschreibung?.length >= maxLines.maxStringLength ?
            beschreibung.substr(0, maxLines.reducedLength) : beschreibung
    }

    function renderMore() {
        if (!isSmall || beschreibung?.length < maxLines.maxStringLength) {
            return null
        }
        return (
            <span className={classes.readMore} onClick={() => setIsSmall(false)}>...more</span>
        )
    }

    return (
        <Typography variant="body1">
            {getText()}
            {renderMore()}
        </Typography>
    )
}

export default ExpandableText
// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
export function resizedataURL(datas, wantedWidth, wantedHeight) {
    return new Promise(function (resolve,) {
        /*
                // We create an image to receive the Data URI
                var img = document.createElement('img');

                // When the event "onload" is triggered we can resize the image.
                img.onload = function()
                {
                    // We create a canvas and get its context.
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');

                    // We set the dimensions at the wanted size.
                    canvas.width = wantedWidth;
                    canvas.height = wantedHeight;

                    // We resize the image with the canvas method drawImage();
                    ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                    var dataURI = canvas.toDataURL();

                    // This is the return of the Promise
                    resolve(dataURI);
                };

                // We put the Data URI in the image's src attribute
                img.src = datas;

                */

        let img = new Image();
        img.src = datas;

        img.onload = () => {

            // Check if the image require resize at all
            if (img.height <= wantedHeight && img.width <= wantedWidth) {
                resolve(datas);
            } else {
                // Make sure the width and height preserve the original aspect ratio and adjust if needed
                if (img.height > img.width) {
                    wantedWidth = Math.floor(wantedHeight * (img.width / img.height));
                } else {
                    wantedHeight = Math.floor(wantedWidth * (img.height / img.width));
                }

                let resizingCanvas = document.createElement('canvas');
                let resizingCanvasContext = resizingCanvas.getContext("2d");

                // Start with original image size
                resizingCanvas.width = img.width;
                resizingCanvas.height = img.height;


                // Draw the original image on the (temp) resizing canvas
                resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

                let curImageDimensions = {
                    width: Math.floor(img.width),
                    height: Math.floor(img.height)
                };

                let halfImageDimensions = {
                    width: null,
                    height: null
                };

                // Quickly reduce the dize by 50% each time in few itterations until the size is less then
                // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
                // created with direct reduction of very big image to small image
                while (curImageDimensions.width * 0.5 > wantedWidth) {
                    // Reduce the resizing canvas by half and refresh the image
                    halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
                    halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

                    resizingCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
                        0, 0, halfImageDimensions.width, halfImageDimensions.height);

                    curImageDimensions.width = halfImageDimensions.width;
                    curImageDimensions.height = halfImageDimensions.height;
                }

                // Now do final resize for the resizingCanvas to meet the dimension requirments
                // directly to the output canvas, that will output the final image
                let outputCanvas = document.createElement('canvas');
                let outputCanvasContext = outputCanvas.getContext("2d");

                outputCanvas.width = wantedWidth;
                outputCanvas.height = wantedHeight;

                outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
                    0, 0, wantedWidth, wantedHeight);

                // output the canvas pixels as an image. params: format, quality
                let base64ResizedImage = outputCanvas.toDataURL('image/jpeg', 0.85);
                resolve(base64ResizedImage);

            }
        };

    })
}// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);

export const resizeFile = (f, width, height) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            resizedataURL(reader.result, width, height)
                .then(t => resolve(t));
        }, false);
        if (f) {
            reader.readAsDataURL(f);
        }
    });
}

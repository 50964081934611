import React from "react";
import {Alert, Button} from '@mui/material';
import VoucherDownload from "../voucher/VoucherDownload";

const CheckoutVoucherCompleted = ({
                                      voucherVorlagen,
                                      onChangeVoucherVorlage,
                                      selectedVoucherVorlage,
                                      onClickNextBooking,
                                      email,
                                      onDownloadVoucher,
                                      t
                                  }) => {
    return (
        <React.Fragment>
            <Alert style={{marginTop: '20px'}}
                   severity="success">{t('GUTSCHEIN_VERSANDT', {email})}</Alert>
            <VoucherDownload voucherVorlagen={voucherVorlagen} onChangeVoucherVorlage={onChangeVoucherVorlage} t={t}
                             selectedVoucherVorlage={selectedVoucherVorlage} onDownloadVoucher={onDownloadVoucher}/>

            <Button variant="contained" color="primary" fullWidth
                    onClick={() => onClickNextBooking()}>{t('WEITERER_GUTSCHEIN')}</Button>
        </React.Fragment>
    );
}

export default CheckoutVoucherCompleted
import React from "react";
import Confirmation from "./Confirmation";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrency, selectStudioimageBasePath} from "../../../store/common/commonSelectors";
import {
    selectForwardUrl,
    selectIsDatenschutzRead,
    selectSelectedProduktList,
    selectSelectedTerminArtKaution,
    selectSelecteTerminartHinweisAnzahlung
} from "../../../store/appointment/appointmentSelectors";
import {changeDatenschutzRead, newBooking} from "../../../store/appointment/appointmentActions";
import {
    selectIsPaymentProcessing,
    selectPaymentType,
    selectWillPayLater
} from "../../../store/checkout/checkoutSelector";
import {selectEmail} from "../../../store/kunde/kundeSelectors";
import {useTheme} from "@emotion/react";
import {useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";

const ConfirmationContainer = () => {
    const selectedProdukte = useSelector(state => selectSelectedProduktList(state))
    const isDatenschutzRead = useSelector(state => selectIsDatenschutzRead(state))
    const studioimageBasePath = useSelector(state => selectStudioimageBasePath(state))
    const waehrung = useSelector(state => selectCurrency(state))
    const terminkaution = useSelector(state => selectSelectedTerminArtKaution(state))
    const hinweisAnzahlung = useSelector(state => selectSelecteTerminartHinweisAnzahlung(state))
    const paymentType = useSelector(state => selectPaymentType(state))
    const bookingWithPayLaterCompleted = useSelector(state => selectWillPayLater(state))
    const isPaymentProcessing = useSelector(state => selectIsPaymentProcessing(state))
    const email = useSelector(state => selectEmail(state))
    const forwardButtonConfig = useSelector(state => selectForwardUrl(state))
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const theme = useTheme();
    const isTabletOrGreater = useMediaQuery(theme.breakpoints.up('sm'));

    const setDSRead = (value) => dispatch(changeDatenschutzRead(value))


    const onClickNextBooking = () => {
        dispatch(newBooking())
    }

    return (
        <React.Fragment>
            <Confirmation selectedProdukte={selectedProdukte}
                          studioimageBasePath={studioimageBasePath}
                          checkDS={isDatenschutzRead}
                          waehrung={waehrung}
                          terminkaution={terminkaution}
                          hinweisAnzahlung={hinweisAnzahlung}
                          setCheckDS={setDSRead}
                          bookingWithPayLaterCompleted={bookingWithPayLaterCompleted}
                          isPaymentProcessing={isPaymentProcessing}
                          onClickNextBooking={onClickNextBooking}
                          email={email}
                          paymentType={paymentType}
                          isTabletOrGreater={isTabletOrGreater}
                          forwardButtonConfig={forwardButtonConfig}
                          t={t}
            />
        </React.Fragment>
    )
}

export default ConfirmationContainer;

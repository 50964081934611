import React from "react";
import {useTranslation} from "react-i18next";
import BookingError from "./BookingError";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {newBooking} from "../../store/appointment/appointmentActions";

const BookingErrorContainer = () => {
    const {t} = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const {search} = useLocation()
    const paymentId = new URLSearchParams(search).get('paymentId');

    const onOk = () => {
        dispatch(newBooking())
        history.push(('/'))
    }

    return (
        <BookingError t={t} paymentId={paymentId} onOk={onOk}/>
    )
}

export default BookingErrorContainer
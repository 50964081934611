import React from "react";
import {Checkbox, CircularProgress, FormControlLabel, Grid, Typography} from "@mui/material";
import ProduktCard from "./ProduktCard";
import {getBezeichnungWithPreis} from "../../../models/Produkt";
import {numberFormat} from "../../../utils/Localization";
import Success from "../../success/Success";
import Heading from "../../common/style/Heading";


const Confirmation = ({
                          selectedProdukte, t,
                          checkDS, setCheckDS, waehrung, terminkaution, hinweisAnzahlung,
                          bookingWithPayLaterCompleted, isPaymentProcessing, onClickNextBooking, email, paymentType,
                          isTabletOrGreater, studioimageBasePath, forwardButtonConfig
                      }) => {
    const bild = (produkt) => {
        if (!produkt.Bild) {
            return undefined
        }
        const key = encodeURIComponent(produkt.Bild)
        return `${studioimageBasePath}?key=${key}`
    }

    const renderProdukte = () => {
        if (!selectedProdukte || !selectedProdukte.length) {
            return null;
        }
        return (
            selectedProdukte.map(p => <ProduktCard beschreibung={p.Beschreibung}
                                                   isTabletOrGreater={isTabletOrGreater}
                                                   key={p.ProduktID}
                                                   titel={getBezeichnungWithPreis(p, waehrung)}
                                                   imageLocation={bild(p)}/>)
        );
    }

    const renderHinweisAnzahlung = () => {
        if (!hinweisAnzahlung) {
            return null
        }
        return (
            <span><br/>{hinweisAnzahlung}</span>
        )
    }

    const renderKautionshinweis = () => {
        if (terminkaution) {
            const kaution = numberFormat(terminkaution, 'de-DE', waehrung)
            return (
                <React.Fragment>
                    <Typography variant="subtitle1"
                                style={{
                                    fontWeight: 'bold',
                                    marginTop: '20px'
                                }}>{t('ANZAHLUNG', {anzahlung: kaution})}{renderHinweisAnzahlung()}</Typography>
                </React.Fragment>
            )

        }
        return null;
    }


    const renderLabel = () => {
        return (
            <div style={{textAlign: 'center'}}>
                <a target="_blank" rel="noreferrer"
                   href="https://www.kisscal.de/doc/Html/DS-Vereinbarung-Termin-Portal.html">
                    {t('CONFIRMATION.PRIVACY_AGREEMENT_READ')}
                </a>
            </div>
        )
    }

    const renderSpinner = () => {
        return (
            <Grid container alignItems="center" style={{marginTop: "20px"}}>
                <Grid item xs={2}>
                    <CircularProgress/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1">{t('BUCHUNGSPROZESS_LAEUFT')}</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <Heading>{t('CONFIRMATION.HEADER')}</Heading>
            {renderProdukte()}
            <Typography variant="subtitle2">{t('PREISNACHVEREINBARUNG')}</Typography>
            {renderKautionshinweis()}
            <FormControlLabel style={{marginTop: '20px'}} control={
                <Checkbox color="primary"
                          checked={checkDS}
                          onChange={() => setCheckDS(!checkDS)}
                />
            } label={renderLabel()}/>

            {isPaymentProcessing ? renderSpinner() : null}
            {bookingWithPayLaterCompleted ?
                <Success paymentType={paymentType} email={email} onClickNextBooking={onClickNextBooking}
                         forwardButtonConfig={forwardButtonConfig}/> : null}

        </React.Fragment>
    );
}

export default Confirmation;

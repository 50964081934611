import {numberFormat} from "../../utils/Localization";


const translateKautionshinweis = (lang, gezahlt, kaution) => {
    if (lang === 'de') {
        return `Anzahlung/Terminkaution: ${gezahlt} von ${kaution}`
    }
    return `Deposit: ${gezahlt} of ${kaution}`
}

const getDeHeaderText = (periodText, artist, location) => {
    if (!periodText) {
        return `Terminanfrage bei ${artist.Kuerzel} @ ${location.Ort}:`
    }
    return `Termin-Buchung:
${periodText.text}
bei ${artist.Kuerzel} @ ${location.Ort}`
}

const getEnHeaderText = (periodText, artist, location) => {
    if (!periodText) {
        return `Appointment-Request for ${artist.Kuerzel} @ ${location.Ort}:`
    }
    return `Appointment-Booking:
${periodText.text}
by ${artist.Kuerzel} @ ${location.Ort}`
}

const getTerminBuchungsDetails = ({
                                      lang,
                                      kautionshinweis,
                                      periodText,
                                      artist,
                                      location,
                                      rubrik,
                                      terminArt,
                                      produkte,
                                      comment
                                  }) => {
    const header = lang === 'de' ? getDeHeaderText(periodText, artist, location) : getEnHeaderText(periodText, artist, location)
    if (lang === 'de') {
        return `${header}
${kautionshinweis}
---------------------------------------------------------
Rubrik: ${rubrik.Bezeichnung}

Terminart: ${terminArt.Bezeichnung}

Produkte/Leistungen:
${produkte}

Deine Anmerkungen:
${comment || 'keine'}
----------------------------------------------------------
${rubrik.Abschlusstext || ''}`
    }
    return `Appointment-Booking:
${periodText.text}
by ${artist.Kuerzel} @ ${location.Ort}
${kautionshinweis}
---------------------------------------------------------
Category: ${rubrik.Bezeichnung}
${rubrik.Beschreibung || ''}

Appointment Type": ${terminArt.Bezeichnung}
${terminArt.Beschreibung || ''}

Product/Service:
${produkte}

Your comments:
${comment || 'none'}
----------------------------------------------------------
${rubrik.Abschlusstext || ''}`
}

export function convert2TerminDetails({
                                          artist, location, produktList, waehrung, rubrik, terminArt,
                                          lang, comment, periodText, zeroPriceText, nachVereinbarung, isPaid
                                      }) {
    const produkte = produktList.map(t => convertProdukt2TerminDetails(t, waehrung
        , lang, zeroPriceText, nachVereinbarung)).join('\n');
    const kaution = numberFormat(artist.AnzAgreed, lang, waehrung)
    const gezahlt = isPaid ? kaution : '0 ' + waehrung
    const kautionshinweis = artist.AnzAgreed ? translateKautionshinweis(lang, gezahlt, kaution) : ''

    return getTerminBuchungsDetails({
        lang,
        kautionshinweis,
        periodText,
        artist,
        location,
        rubrik,
        terminArt,
        produkte,
        comment
    })
}

function convertProdukt2TerminDetails(produkt, waehrung, lang, zeroPriceText, nachVereinbarung) {
    const preis = produkt.Kostenfrei ? zeroPriceText : produkt.Preis ? numberFormat(produkt.Preis, lang, waehrung) : nachVereinbarung;
    return `- ${produkt.Bezeichnung}: ${preis}
  ${produkt.Beschreibung || ''}
`;
}
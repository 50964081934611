import React from "react";
import {Alert, Fade, Snackbar, Typography} from "@mui/material";
import Produkt from "./Produkt";
import BackdropSpinner from "../../common/backdrop/BackdropSpinner";
import NoTimeslotAvailableAlert from "../../error/NoTimeslotAvailableAlert";

const Produktwahl = ({
                         t,
                         openMaxProduktAlert,
                         maxProdukte,
                         selectedTerminArtID,
                         produkte,
                         produktList,
                         produktImageBasePath,
                         loadingTermineFinished,
                         currency,
                         isTabletOrGreater,
                         handleProduktSelection,
                         handleCloseMaxProduktAlert,
                         showNoPruductAvailable
                     }) => {


    const renderProduktSelectionHint = () => {
        if (produktList?.length) {
            return null;
        }
        return (
            <Fade in={true}>
                <Alert severity="warning">
                    {maxProdukte ? (maxProdukte === 1 ? t('PRODUKTWAHL_CHOOSE_MAX_ONE_PRODUKT_ALERT')
                        : t('PRODUKTWAHL_CHOOSE_MAX_PRODUKT_ALERT', {maxProdukte})) : t('PRODUKTWAHL_CHOOSE_PRODUKT_ALERT')}
                </Alert>
            </Fade>
        );
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{flexGrow: '0'}}>
                {selectedTerminArtID ? renderProduktSelectionHint() : null}
                {showNoPruductAvailable ? <NoTimeslotAvailableAlert t={t}/> : null}
            </div>
            <div style={{flexGrow: '1', overflowY: 'auto'}}>
                {
                    produkte && produkte.map((p, idx) =>
                        <Fade key={idx} in={true} timeout={(idx + 1) * 1000}>
                            <div key={idx}>
                                <Produkt produkt={p}
                                         checked={produktList.includes(p.ProduktID)}
                                         handleProduktSelection={handleProduktSelection}
                                         currency={currency}
                                         imageBasePath={produktImageBasePath}
                                         isTabletOrGreater={isTabletOrGreater}
                                         key={p.ProduktID}/></div>
                        </Fade>)
                }
            </div>
            {produkte?.length ? <Typography variant="subtitle2">{t('PREISNACHVEREINBARUNG')}</Typography> : null}
            <BackdropSpinner open={loadingTermineFinished !== undefined && !loadingTermineFinished}/>
            <Snackbar open={openMaxProduktAlert} autoHideDuration={6000} onClose={handleCloseMaxProduktAlert}>
                <Alert onClose={handleCloseMaxProduktAlert} severity="warning">
                    Maximale Anzahl an Produkten ist erreicht!
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Produktwahl;

export const numberFormat = (value, locale, currency) =>
    new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency
    }).format(value);

export function getFlagEmoji(countryCode) {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(char =>  127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
}

import React from "react";
import {Checkbox, Fade, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ExpandableText from "../../common/style/ExpandableText";


const useStyles = makeStyles({
    hover: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

const TerminartWahl = ({
                           t,
                           handleChangeTerminArt,
                           selectedTerminArtID,
                           terminarten,
                           isTabletOrGreater,
                           theme
                       }) => {
    const classes = useStyles()
    const renderTerminart = (ta, idx) => {
        return (
            <Fade key={idx} in={true} timeout={(idx + 1) * 1000}>
                <Grid item xs={12} style={{marginTop: '20px'}} key={ta.TerminArtID}>
                    <Grid container direction="row" justifyContent="flext-start" alignItems="center">
                        <Checkbox
                            color="primary"
                            checked={ta.TerminArtID === selectedTerminArtID}
                            onChange={() => handleChangeTerminArt(ta.TerminArtID)}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                        <Typography onClick={() => handleChangeTerminArt(ta.TerminArtID)}
                                    className={classes.hover}
                                    variant={isTabletOrGreater ? 'h5' : 'h6'}>{ta.Bezeichnung}</Typography>
                    </Grid>
                    {ta.Beschreibung ? <ExpandableText theme={theme} beschreibung={ta.Beschreibung}/> : null}
                </Grid>
            </Fade>
        )
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {terminarten.map((ta, idx) => renderTerminart(ta, idx))}
        </div>
    )
}

export default TerminartWahl
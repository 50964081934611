import {
    ADD_IMAGE,
    ADD_S3KEY_TO_IMAGE,
    BLOCKER_BOOKING_FAILED,
    BLOCKERID_CLEARED,
    CHANGE_ACTIVE_STEP,
    CHANGE_ARTIST,
    CHANGE_BLOCKERID,
    CHANGE_CUSTOMER_MESSAGE,
    CHANGE_DAATENSCHUTZ_READ,
    CHANGE_PERIOD,
    CHANGE_RUBRIK,
    CHANGE_TATORT,
    CHANGE_TERMINART,
    CLEAR_BLOCKER_BOOKING_FAILED,
    DESELECT_PRODUKT,
    IMAGE_UPLOADED,
    LOAD_ARTIST_TERMINE_ONGOING,
    LOAD_ARTIST_TERMINE_SUCCESS,
    NEW_BOOKING,
    REMOVE_IMAGE,
    SELECT_PRODUKT,
    SET_UI_STATE,
    UPLOADING_IMAGE
} from "./appointmentActions";
import {replacePayloadFields} from "../utils";
import {BOOKING_START} from "../../components/bookingflow/BookingFlowStepperModel";

const INITIAL = {
    stateLoaded: false
    , activeStep: BOOKING_START
    , images: []
};

export default function AppointmentReducers(state = INITIAL, action) {
    switch (action.type) {
        case SET_UI_STATE:
            return {
                ...state
                , stateLoaded: true
                , ...action.payload.uiState
            }
        case NEW_BOOKING:
            return {
                ...state
                , selectedRubrikID: undefined
                , selectedTerminArtID: undefined
                , selectedTatOrtID: undefined
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , produktList: []
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
                , activeStep: INITIAL.activeStep
                , images: []
                , customerMessage: undefined
                , blockerId: undefined
            }

        case CHANGE_RUBRIK:
            return {
                ...state
                , selectedRubrikID: action.payload.selectedRubrikID
                , selectedTerminArtID: undefined
                , selectedTatOrtID: undefined
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , produktList: []
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
                , images: []
            }
        case CHANGE_TERMINART:
            return {
                ...state
                , selectedTerminArtID: action.payload.selectedTerminArtID
                , selectedTatOrtID: undefined
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , produktList: []
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
                , images: []
            }
        case CHANGE_TATORT:
            return {
                ...state
                , selectedTatOrtID: action.payload.selectedTatOrtID
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
            }
        case CHANGE_ARTIST:
            return {
                ...state
                , selectedPeriod: undefined
                , selectedArtistID: action.payload.selectedArtistID
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
            }
        case CHANGE_PERIOD:
            return {
                ...state
                , selectedPeriod: action.payload.selectedPeriod
                , pendingPayment: undefined
            }
        case CHANGE_BLOCKERID:
            return {
                ...state
                , blockerId: action.payload.blockerId
                , blockerBookingFailed: false
            }
        case BLOCKER_BOOKING_FAILED:
            return {
                ...state
                , blockerId: undefined
                , blockerBookingFailed: true
                , selectedTatOrtID: undefined
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
            }
        case CLEAR_BLOCKER_BOOKING_FAILED:
            return {
                ...state
                , blockerBookingFailed: false
            }
        case BLOCKERID_CLEARED:
            return {
                ...state
                , blockerId: undefined
            }
        case SELECT_PRODUKT:
            return {
                ...state
                , produktList: [...state.produktList, action.payload.produktId]
                , selectedTatOrtID: undefined
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
            }
        case DESELECT_PRODUKT:
            return {
                ...state
                , produktList: state.produktList.filter(t => t !== action.payload.produktId)
                , selectedTatOrtID: undefined
                , selectedArtistID: undefined
                , selectedPeriod: undefined
                , pendingPayment: undefined
                , selectedTimeslotIndex: undefined
            }
        case LOAD_ARTIST_TERMINE_ONGOING:
            return {
                ...state
                , loadingTermineFinished: false
            }
        case LOAD_ARTIST_TERMINE_SUCCESS:
            return {
                ...state
                , freeArtistTermine: action.payload.artisttermine
                , loadingTermineFinished: true
            }
        case CHANGE_ACTIVE_STEP:
            return replacePayloadFields(state, action);

        case ADD_IMAGE:
            return {
                ...state
                , images: [...state.images, action.payload.image]
            }
        case ADD_S3KEY_TO_IMAGE: {
            const img = state.images.filter(t => t.id === action.payload.id2s3key.id)
            if (!img.length) {
                return state
            }
            const images = state.images.filter(t => t.id !== action.payload.id2s3key.id)
            const newImage = {...img[0], key: action.payload.id2s3key.key}

            return {
                ...state
                , images: [...images, newImage]
            }
        }
        case REMOVE_IMAGE:
            return {
                ...state
                , images: state.images.filter(t => t.id !== action.payload.imageId)
            }
        case UPLOADING_IMAGE:
            return {
                ...state
                , uploadingImage: true
            }
        case IMAGE_UPLOADED:
            return {
                ...state
                , uploadingImage: false
            }
        case CHANGE_DAATENSCHUTZ_READ:
            return {
                ...state
                , datenschutzRead: action.payload.datenschutzRead
            }
        case CHANGE_CUSTOMER_MESSAGE:
            return replacePayloadFields(state, action);

        default:
            return state;
    }
}

import {plus} from "@kiss-solutions/dateutils";

export const convertArtistTermin2StringSelectionWithLocale = (termin, locale, timeZone) => {
    if (!termin) {
        return ''
    }
    const fmtLongPart = new Intl.DateTimeFormat(locale, {
        timeZone,
        weekday: 'short',
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
    const fmtTimePart = new Intl.DateTimeFormat(locale, {
        timeZone,
        hour: 'numeric',
        minute: 'numeric'
    });

    return {
        text: fmtLongPart.format(new Date(termin.Start)) + ' - '
            + fmtTimePart.format(new Date(termin.DatBis)) + (termin.Kuerzel ? ' (' + termin.Kuerzel + ')' : '')
        , id: termin.ArtistID ? termin.ArtistID.toString() + '-' + termin.id : termin.id
        , value: termin
    };
}

export const convertTimeSlot2StartTimeSelectionWithLocale = (period,
                                                             duration,
                                                             locale,
                                                             timezone,
                                                             zeitTakt = 15,
                                                             notStartBefore = new Date()) => {
    const result = [];

    let time = new Date(period.Start);
    let idx = 1;
    while (new Date(period.DatBis).getTime() - time.getTime() >= duration * 60_000) {
        const selectedPeriod = {
            id: idx++
            , Start: time.toISOString()
            , DatBis: plus(new Date(time), duration, 'minute').toISOString()
        };
        if (notStartBefore.getTime() < time.getTime()) {
            result.push(convertArtistTermin2StringSelectionWithLocale(selectedPeriod, locale, timezone));
        }
        time = plus(time, zeitTakt, 'minute');
    }
    return result;
}

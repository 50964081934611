import {fork, put, takeLatest} from "redux-saga/effects";
import {COMMON_LOAD_COMPLETED, DB_SET_ABO_CHECKED} from "../common/commonActions";
import {loadPersistedCheckoutStateAction} from "../checkout/checkoutActions";
import {ALL_VOUCHER_CHANGING_ACTIONS} from "../voucher/voucherActions";
import {clearCheckout} from "../checkout/checkoutSaga";
import {loadUiStateAction} from "../appointment/appointmentActions";

function* propagateDbSetAboChecked() {
    yield put(loadPersistedCheckoutStateAction())
}

function* watchDbSetAndAboChecked() {
    yield takeLatest(DB_SET_ABO_CHECKED, propagateDbSetAboChecked)
}

function* propagateCommonLoadCompleted() {
    yield put(loadUiStateAction())
}

function* watchCommonLoadCompleted() {
    yield takeLatest(COMMON_LOAD_COMPLETED, propagateCommonLoadCompleted)
}

function* watchVoucherChanged() {
    yield takeLatest(ALL_VOUCHER_CHANGING_ACTIONS, clearCheckout)
}

const xAppSaga = [fork(watchDbSetAndAboChecked), fork(watchVoucherChanged), fork(watchCommonLoadCompleted)]

export default xAppSaga
import {createSimpleAction} from "../utils";

export const LOAD_PERSISTED_VOUCHER = 'LOAD_PERSISTED_VOUCHER'
export const LOAD_VOUCHER_FROM_CHECKOUT = 'LOAD_VOUCHER_FROM_CHECKOUT'
export const VOUCHER_FROM_CHECKOUT_LOADED = 'VOUCHER_FROM_CHECKOUT_LOADED'
export const CHANGE_VOUCHER_VON = 'CHANGE_VOUCHER_VON'
export const CHANGE_VOUCHER_FUER = 'CHANGE_VOUCHER_FUER'
export const CHANGE_VOUCHER_BETRAG = 'CHANGE_VOUCHER_BETRAG'
export const CHANGE_VOUCHER_EMAIL = 'CHANGE_VOUCHER_EMAIL'
export const CHANGE_VOUCHER_MESSAGE = 'CHANGE_VOUCHER_MESSAGE'
export const ALL_VOUCHER_CHANGING_ACTIONS = [CHANGE_VOUCHER_VON, CHANGE_VOUCHER_FUER, CHANGE_VOUCHER_BETRAG, CHANGE_VOUCHER_EMAIL, CHANGE_VOUCHER_MESSAGE]

export const changeVoucherVon = createSimpleAction(CHANGE_VOUCHER_VON, 'von')
export const changeVoucherFuer = createSimpleAction(CHANGE_VOUCHER_FUER, 'fuer')
export const changeVoucherBetrag = createSimpleAction(CHANGE_VOUCHER_BETRAG, 'betrag')
export const changeVoucherEmail = createSimpleAction(CHANGE_VOUCHER_EMAIL, 'email')
export const changeVoucherMessage = createSimpleAction(CHANGE_VOUCHER_MESSAGE, 'message')
export const loadPersistedVoucherToState = (voucherState) => ({type: LOAD_PERSISTED_VOUCHER, payload: voucherState})

export const loadVoucherFromCheckout = createSimpleAction(LOAD_VOUCHER_FROM_CHECKOUT, 'checkoutId')
export const voucherFromCheckoutLoaded = (voucherState) => ({
    type: VOUCHER_FROM_CHECKOUT_LOADED,
    payload: voucherState
})
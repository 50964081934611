import {createSelector} from "reselect";
import {addUTCDiff, isSameOrBefore, minus} from "@kiss-solutions/dateutils";
import {intersectsPeriod} from "@kiss-solutions/dateutils/lib/common";

export const identityFunction = t => t;
export const mapAndIdToObject = (map, id) => map && id ? map[id] : undefined;
export const createFieldSelector = (baseSelector, field) => createSelector(
    baseSelector
    , state => state ? state[field] : undefined
)
export const createBooleanFieldSelector = (baseSelector, field) => createSelector(
    baseSelector
    , state => state && state[field] && true || false
)
export const createIsFieldDefinedSelector = (baseSelector, field) => createSelector(
    baseSelector
    , state => !!state[field]
)
export const createIsArrayNotEmpty = (baseSelector, field) => createSelector(
    baseSelector
    , state => !!state[field] && state[field].length > 0
)

export const replacePayloadFields = (state, action) => ({...state, ...action.payload});
export const createSimpleAction = (type, fieldName) => (value) => ({type, payload: {[fieldName]: value}});
export const createSimpleValueAction = (type, fieldName, value) => ({type, payload: {[fieldName]: value}});
export const isOlderThan14 = dateString => {
    const minDate = minus(new Date(), 14, 'year');
    return isSameOrBefore(new Date(dateString), minDate, 'date')
}
export const createErrorPathWithCurrentLocationAsBack = (pathname, error) => ({
    pathname, search: `?${new URLSearchParams({error, back: window.location.pathname}).toString()}`
})

export const getAtTimeOfTimezone = (date, time, timezone) => {
    const newDateString = `${date.toISOString().substr(0, 11)}${time}Z`
    let newDate = new Date(newDateString)
    newDate = addUTCDiff(newDate, timezone)
    return newDate
}

export const narrowTimeslot = (von, bis, startTime, endTime, timezone) => {
    const newPeriod = {
        von: getAtTimeOfTimezone(von, startTime, timezone)
        , bis: getAtTimeOfTimezone(bis, endTime, timezone)
    }
    if (intersectsPeriod({von, bis}, newPeriod)) {
        return {
            von: newPeriod.von.getTime() > von.getTime() ? newPeriod.von : von,
            bis: newPeriod.bis.getTime() < bis.getTime() ? newPeriod.bis : bis,
        }
    }
}

export const padNumber = (num, size) => {
    const s = "000000000" + num;
    return s.substr(s.length - size);
}

export const formatLocalDate = (date) => new Intl.DateTimeFormat('fr-CA', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
}).format(date)
import React from "react";
import {useTheme} from "@emotion/react";
import {Typography, useMediaQuery} from "@mui/material";

const Heading = ({children}) => {
    const theme = useTheme();
    const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Typography style={{textAlign: 'center'}} variant={isNotMobile ? 'h4' : 'h5'}>{children}</Typography>
    )
}

export default Heading
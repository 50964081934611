import {
    selectBlockerId,
    selectCustomerMessage,
    selectImages,
    selectSelectedPeriod,
    selectSelectedPeriodAsText,
    selectSelectedProduktList,
    selectSelectedRubrik,
    selectSelectedTatOrt,
    selectSelectedTerminArt,
    selectSelectedTerminArtArtist,
    selectStateLoaded
} from "../appointment/appointmentSelectors";
import {selectCurrency, selectLanguage} from "../common/commonSelectors";
import {selectKundeState} from "../kunde/kundeSelectors";
import {createSelector} from "reselect";
import {createFieldSelector, identityFunction} from "../utils";
import {selectVoucherState} from "../voucher/voucherSelectors";
import {createOpenVoucherCheckoutRecord} from "../../models/voucher";

export const selectCheckoutState = createSelector(
    (state) => state?.checkout
    , identityFunction
);

export const selectPayment = createSelector(
    selectCheckoutState
    , state => state?.payment
)


export const selectCheckoutId = createFieldSelector(selectPayment, 'checkoutId')
export const selectProcessedCheckoutId = createFieldSelector(selectPayment, 'processedCheckoutId')

export const selectPaypalOrder = createSelector(
    selectPayment
    , payment => payment?.paypalOrder
)

export const selectWillPayLater = createSelector(
    selectPayment
    , payment => payment?.payLater && !payment?.processing
)

export const selectStripePaymentStatus = createSelector(
    selectPayment
    , payment => payment?.stripePaymentStatus
)

export const selectIsPaymentSuccess = createSelector(
    selectPayment
    , selectStripePaymentStatus
    , (payment, stripePaymentStatus) => !!payment?.paypalOrderId || stripePaymentStatus === 'succeeded'
)

export const selectStripeClientSecret = createSelector(
    selectPayment
    , payment => payment?.stripeClientSecret
)


export const selectIsPaymentProcessing = createSelector(
    selectPayment
    , payment => !!payment?.processing
)

export const selectPaymentType = createSelector(
    selectPayment
    , payment => payment?.pendingPaymentType
)

export const selectStripePaymentStatusOpenOrRetry = createSelector(
    selectStripePaymentStatus
    , selectIsPaymentProcessing
    , (stripePaymentStatus, processing) => processing && (!stripePaymentStatus || stripePaymentStatus === 'open')
        || stripePaymentStatus === 'pending' || stripePaymentStatus === 'retry'
)

export const selectIsPaymentFailed = createSelector(
    selectStripePaymentStatus
    , stripePaymentStatus => !!stripePaymentStatus && stripePaymentStatus === 'failed'
)

export const selectIsPaymentClosed = createSelector(
    selectIsPaymentSuccess
    , selectWillPayLater
    , (success, payLater) => success || payLater
)

export const selectCheckoutFromTerminData = createSelector(
    selectStateLoaded
    , selectSelectedTerminArtArtist
    , selectSelectedTatOrt
    , selectSelectedProduktList
    , selectSelectedRubrik
    , selectSelectedTerminArt
    , selectLanguage
    , selectSelectedPeriodAsText
    , selectCustomerMessage
    , selectBlockerId
    , selectImages
    , selectSelectedPeriod
    , selectKundeState
    , selectCurrency
    , (loaded, terminartArtist, location, produkte, rubrik,
       terminArt, lang, periodText, customerMessage,
       blockerID, images, period, kunde, waehrung) => loaded && ({
        terminartArtist, location, produkte, rubrik,
        terminArt, lang, periodText, customerMessage,
        blockerID, images, period, kunde, waehrung
    })
)

export const selectCheckoutFromVoucherData = createSelector(
    selectStateLoaded
    , selectLanguage
    , selectVoucherState
    , selectCurrency
    , (loaded, lang, voucher, waehrung) => loaded && createOpenVoucherCheckoutRecord(lang, waehrung, voucher)
)

export const deutsch = {
    "Produktkategorie": "Produktkategorie",
    "Produkt": "Produkt",
    "PRODUKTI": "Produkt I",
    "PRODUKTII": "Produkt II",
    "Termin": "Termin",
    "Egal": "Egal",
    "TERMIN": "Termin",
    "Rubrik": "Rubrik",
    "RUBRIK": "RUBRIK",
    "BESTAETIGUNG": "BESTÄTIGUNG",
    "Terminart": "Terminart",
    "RESERVATIONHINT": "Diesen Termin halten wir 30 Minuten ab Auswahl für dich reserviert. Bitte schließe bis dahin die Buchung ab.",
    "PREISNACHVEREINBARUNG": "n.V.: Für diese Produkte erfolgt die Preisfestlegung nach Vereinbarung.",
    "ANZAHLUNG": "Anzahlung/Terminkaution: {{ anzahlung }}.",
    "KOSTENPFLICHTIG_BUCHEN": "kostenpflichtig buchen",
    "VERBINDLICH_BUCHEN": "verbindlich buchen",
    "ANFRAGE_SENDEN": "sende Anfrage",
    "BEZAHLUNG_ABGESCHLOSSEN": "Die Bezahlung wurde erfolgreich abgeschlossen.",
    "BEZAHLE_SPAETER": "Ich bezahle später",
    "ZAHLUNGSPROZESS_LAEUFT": "Zahlung wird verarbeitet",
    "BUCHUNGSPROZESS_LAEUFT": "Buchung wird verarbeitet",
    "VERBINDUNGSFEHLER": "Verbindungsfehler",
    "VERBINDUNGSAUFBAU_NICHT_MOEGLICH": "Sorry, es kann keine Verbindung zum Server aufgebaut werden. Bitte versuche es später...",
    "VERBINDUNG_PRUEFEN": "Verbindung prüfen",
    "BUCHUNG_ERFOLGREICH": "Die Buchung war erfolgreich. Es wurde eine Email mit den Buchungsdaten an {{email}} versandt.",
    "GUTSCHEIN_VERSANDT": "Es wurde eine Email mit dem Gutschein an {{email}} versandt.",
    "WEITERE_BUCHUNG": "Neue Buchung",
    "WEITERER_GUTSCHEIN": "Neuer Gutschein",
    "CHECK_ABO_FAILED": "Es scheint ein Konfigurationsproblem vorzuliegen. Bitte kontaktieren Sie das Studio. (Code={{error}})",
    "NO_STUDIO_GIVEN": "Es scheint ein Konfigurationsproblem vorzuliegen. Bitte kontaktieren Sie das Studio. (Code={{error}})",
    "CONFIGURATION_ERROR": "Fehler",
    "ZAHLUNG_FEHLGESCHLAGEN": "Die Zahlung wurde abgebrochen oder ist fehlgeschlagen. Es wurde keine Zahlung durchgeführt. Versuchen sie es erneut.",
    "PAYMENT_SERVICE_MISSING": "Es scheint ein Konfigurationsproblem vorzuliegen. Bitte kontaktieren Sie das Studio. (Code={{error}})",
    "VOUCHER_ISSUER_ARTIST_MISSING": "Es scheint ein Konfigurationsproblem vorzuliegen. Bitte kontaktieren Sie das Studio. (Code={{error}})",
    "MAIL_CONFIGURATION": "Es scheint ein Konfigurationsproblem vorzuliegen. Bitte kontaktieren Sie das Studio. (Code={{error}})",
    "Betrag": "Betrag",
    "VOUCHER_MESSAGE_LABEL": "Deine Nachricht",
    "KAUFE_GUTSCHEIN": "kaufe Gutschein",
    "GUTSCHEIN": "Gutschein von {{von}} für {{fuer}}",
    "GUTSCHEIN_DOWNLOAD": "Gutschein - Download",
    "VOUCHER_VON": "Von",
    "VOUCHER_FUER": "Für",
    "VOUCHER_EMAIL": "Email",
    "VOUCHER_NACHRICHT": "Nachricht",
    "VOUCHER_TEMPLATE": "Gutscheinvorlage",
    "CONFIRMATION.HEADER": "Prüfen & Bestätigen",
    "KUNDE": "Kunde",
    "PRODUKT": "Produkt",
    "TERMINART": "Terminart",
    "MESSAGE": "Nachricht",
    "WEITERE_INFOS": "Schicke uns eine Nachricht oder eine Bildvorlage!",
    "KUNDE_MINDESTALTER_HINT": "Du musst mindestens 14 sein",
    "EMAIL_CONFIRMATION_INITIATED": "Wir haben Dir eine Mail mit einem Sicherheitscode gesendet. Bitte trage sie hier ein, um die Prüfung abzuschließen.",
    "EMAIL_CONFIRMATION_PROBLEM": "Es gibt ein Problem bei der Email-Prüfung. Bitte kontaktiere das Studio!",
    "EMAIL_CONFIRMATION_SUCCESS": "Die Email wurde erfolgreich verifiziert. Du kannst den Dialog schließen!",
    "EMAIL_CONFIRMATION_FAILED": "Das Secret ist nicht korrekt!",
    "EMAIL_CONFIRMATION_TITLE": "Emailprüfung",
    "EMAIL_CONFIRMATION_CODE": "Sicherheitscode",
    "CONFIRMATION_USER_MESSAGE": "Deine Nachricht an uns",
    "KUNDE_VORNAME_LABEL": "Vorname",
    "KUNDE_NACHNAME_LABEL": "Nachname",
    "KUNDE_EMAIL_LABEL": "Email",
    "KUNDE_BIRTHDAY_LABEL": "Geburtstag",
    "KUNDE_MOBILE_LABEL": "Mobil",
    "KUNDE_DESEASES_LABEL": "Krankheiten/Allergien",
    "TERMINWAHL_TERMIN_BLOCKED": "Leider ist der Termin inzwischen vergeben. Bitte suche Dir einen anderen aus!",
    "CHECKOUT_TYPE": "Typ",
    "CHECKOUT_TERMINKAUTION": "Anzahlung/Terminkaution",
    "Close": "Schliessen",
    "Check": 'Prüfen',
    "Resend": "Erneut senden",
    "Bezahlung": "Bezahlung",
    "CUSTOMER_MESSAGE_HEADING": "Deine Nachricht an uns",
    "BOOKING_ERROR": "Buchungsfehler",
    "BOOKING_ERROR_MESSAGE": "Vermutlich ist die Buchung erfolgt, aber es gab ein Problem bei dem Versand der Bestätigung. Bitte kontaktiere das Studio.",
    "BOOKING_ERROR_MESSAGE_WITH_CHECKOUTID": "Vermutlich ist die Buchung erfolgt, aber es gab ein Problem beim Versand der Bestätigung. Bitte kontaktiere das Studio und gibt deine Payment-ID {{paymentId}} mit an.",
    "(Preis nach Vereinbarung)": "(Preis nach Vereinbarung)",
    "(Kostenfrei)": "(Kostenfrei)",
    "NO_TIMESLOT_AVAILABLE": "Es ist leider kein Timeslot verfügbar.",
    "KUNDE_HEADING": "Deine Daten",
    "PRODUKTWAHL_CHOOSE_MAX_ONE_PRODUKT_ALERT": "Bitte wähle ein Produkt!",
    "PRODUKTWAHL_CHOOSE_MAX_PRODUKT_ALERT": "Bitte wähle maximal {{maxProdukte}} Produkte!",
    "PRODUKTWAHL_CHOOSE_PRODUKT_ALERT": "Bitte wähle deine Produkt(e)!",
    "TERMINWAHL_CHOOSE_LOCATION_ALERT": "Bitte wähle zuerst eine Location!",
    "TERMINWAHL_HEADING": "Terminwahl",
    "FILEINPUT.BUTTON_TEXT_ADD_PIC":"Bild anfügen",
    "KUNDE.EMAIL_CHECK_HELPER_TEXT": "Die email muss geprüft werden!",
    "KUNDE.EMAIL_CHECK_BUTTON_TEXT": "Prüfen",
    "KUNDE.CONTACT_LABEL_TEXT": "Bevorzugte Kontaktart",
    "KUNDE.CONTACT.SELECTION_TEXT_MOBIL": "Mobiltelefon",
    "KUNDE.CONTACT.SELECTION_TEXT_EMAIL": "Email",
    "BUTTON_TEXT_CLOSE": "Schliessen",
    "BUTTON_TEXT_CHECK": "Prufen",
    "BUTTON_TEXT_RESEND": "Erneut senden",
    "CONFIRMATION.PRIVACY_AGREEMENT_READ": "Ich habe die Datenschutzvereinbarung gelesen.",
    "VOUCHER.HEADER": "Gutschein",
    "LEGAL_FOOTER.LINK_TEXT_DATENSCHUTZ": "Datenschutz",
    "LEGAL_FOOTER.LINK_TEXT_IMPRESSUM": "Impressum"

}

import React, {useState} from "react";
import Kunde from "./Kunde";
import {useDispatch, useSelector} from "react-redux";
import {
    selectBirthday,
    selectDeseases,
    selectEmail,
    selectEmailCheckBadRequest,
    selectEmailHash,
    selectEmailVerificationOngoing,
    selectEmailVerificationSuccess,
    selectKontaktart,
    selectMobile,
    selectNachname,
    selectVorname
} from "../../../store/kunde/kundeSelectors";
import {
    changeBirthday,
    changeDeseases,
    changeEmail,
    changeKontaktart,
    changeMobile,
    changeNachname,
    changeVorname,
    persistKunde,
    resendEmailCheck,
    startEmailCheck,
    startingEmailVerification
} from "../../../store/kunde/kundeActions";
import EmailConfirmation from "./EmailConfirmation";
import {selectLanguage, selectTextDeseases} from "../../../store/common/commonSelectors";
import {useTranslation} from "react-i18next";

const KundeContainer = () => {
    const [vornameTouched, setVornameTouched] = useState(false)
    const [nachnameTouched, setNachnameTouched] = useState(false)
    const [birthdayTouched, setBirthdayTouched] = useState(false)
    const [emailTouched, setEmailTouched] = useState(false)
    const [dialogClose, setDialogClose] = useState(true)

    const dispatch = useDispatch()
    const vorname = useSelector(state => selectVorname(state))
    const nachname = useSelector(state => selectNachname(state))
    const email = useSelector(state => selectEmail(state))
    const mobile = useSelector(state => selectMobile(state))
    const kontaktart = useSelector(state => selectKontaktart(state))
    const birthday = useSelector(state => selectBirthday(state))
    const emailHash = useSelector(state => selectEmailHash(state));
    const emailVerificationSuccess = useSelector(state => selectEmailVerificationSuccess(state))
    const emailVerificationOngoing = useSelector(state => selectEmailVerificationOngoing(state))
    const emailCheckBadRequest = useSelector(state => selectEmailCheckBadRequest(state))
    const infotextDeseases = useSelector(state => selectTextDeseases(state))
    const deseases = useSelector(state => selectDeseases(state))
    const lang = useSelector(state => selectLanguage(state))
    const {t} = useTranslation()


    const onBlurVorname = () => {
        setVornameTouched(true);
        dispatch(persistKunde());
    }
    const onBlurNachname = () => {
        onBlurVorname();
        setNachnameTouched(true);
    };
    const onBlurEmail = () => {
        onBlurNachname();
        setEmailTouched(true);
    };
    const handleBirthdayChange = (value) => {
        setBirthdayTouched(true);
        if (value) {
            dispatch(changeBirthday(value));
        }
    }
    const handleStartEmailCheckButtonClick = () => {
        dispatch(persistKunde());
        if (!emailHash) {
            dispatch(startEmailCheck());
        }
        setDialogClose(false);
    }

    const handleChangeMobile = (value) => {
        dispatch(changeMobile(value));
        if (!value && kontaktart === 1) {
            dispatch(changeKontaktart(2));
        }
    }

    const verifyEmailSecret = (secret) => {
        dispatch(startingEmailVerification(secret));
    }

    const resendEmail = () => {
        dispatch(resendEmailCheck())
    }

    const vornamePar = {
        value: vorname,
        setValue: e => dispatch(changeVorname(e)),
        label: t('KUNDE_VORNAME_LABEL'),
        touched: vornameTouched,
        onBlur: onBlurVorname
    };
    const nachnamePar = {
        value: nachname,
        setValue: e => dispatch(changeNachname(e)),
        label: t('KUNDE_NACHNAME_LABEL'),
        touched: nachnameTouched,
        onBlur: onBlurNachname
    };
    const emailPar = {
        value: email,
        setValue: e => dispatch(changeEmail(e)),
        label: t('KUNDE_EMAIL_LABEL'),
        touched: emailTouched,
        errorState: ((emailVerificationSuccess === false || !emailHash) && email?.length > 0) || emailTouched && !email?.length,
        onBlur: onBlurEmail
    };
    const mobilePar = {
        value: mobile,
        setValue: handleChangeMobile,
        label: t('KUNDE_MOBILE_LABEL'),
        onBlur: () => dispatch(persistKunde())
    };
    const kontaktartPar = {
        value: kontaktart,
        setValue: e => dispatch(changeKontaktart(e)),
        onBlur: () => dispatch(persistKunde())
    };
    const emailCheckPar = {
        disabled: !email,
        onClick: handleStartEmailCheckButtonClick,
        visible: !emailVerificationSuccess
    };
    const birthdayPar = {
        value: birthday && new Date(birthday) || new Date(),
        setValue: handleBirthdayChange,
        touched: birthdayTouched,
        onBlur: () => setBirthdayTouched(true)
    };
    const deseasesPar = {
        value: deseases,
        label: t('KUNDE_DESEASES_LABEL'),
        setValue: e => dispatch(changeDeseases(e)),
        onBlur: () => dispatch(persistKunde())
    };
    return (
        <React.Fragment>
            <Kunde vorname={vornamePar} nachname={nachnamePar}
                   mobile={mobilePar}
                   email={emailPar}
                   kontaktart={kontaktartPar}
                   emailCheck={emailCheckPar}
                   birthday={birthdayPar}
                   deseases={deseasesPar}
                   infotextDeseases={infotextDeseases}
                   lang={lang}
                   t={t}
            />
            <EmailConfirmation open={!dialogClose}
                               setOpen={() => setDialogClose(true)}
                               emailCheckBadRequest={emailCheckBadRequest}
                               email={email}
                               verifyEmailSecret={verifyEmailSecret}
                               emailVerificationSuccess={emailVerificationSuccess}
                               emailVerificationOngoing={emailVerificationOngoing}
                               resendEmail={resendEmail}
                               t={t}
            />
        </React.Fragment>
    )
}

export default KundeContainer;

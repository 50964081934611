import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectRubrikenList, selectStudioimageBasePath} from "../../../store/common/commonSelectors";
import {useTranslation} from "react-i18next";
import {selectSelectedRubrikID, selectStateLoaded} from "../../../store/appointment/appointmentSelectors";
import {changeActiveStep, changeRubrik} from "../../../store/appointment/appointmentActions";
import RubrikWahl from "./RubrikWahl";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";
import {TERMINART} from "../BookingFlowStepperModel";

const RubrikWahlContainer = () => {
    const theme = useTheme()
    const rubriken = useSelector(state => selectRubrikenList(state));
    const {t} = useTranslation();
    const selectedRubrikID = useSelector(state => selectSelectedRubrikID(state));
    const studioimageBasePath = useSelector(state => selectStudioimageBasePath(state));
    const stateLoaded = useSelector(state => selectStateLoaded(state));
    const isTabletOrGreater = useMediaQuery(theme.breakpoints.up('sm'));

    const dispatch = useDispatch();


    const handleRubrikSelection = (rubrikId) => {
        dispatch(changeRubrik(rubrikId));
        dispatch(changeActiveStep(TERMINART))
    }

    if (!stateLoaded) {
        return null
    }

    return (
        <React.Fragment>
            <RubrikWahl t={t}
                        rubriken={rubriken}
                        selectedRubrikID={selectedRubrikID}
                        handleRubrikSelection={handleRubrikSelection}
                        imageBasePath={studioimageBasePath}
                        isTabletOrGreater={isTabletOrGreater}
                        theme={theme}
            />
        </React.Fragment>
    )
}

export default RubrikWahlContainer

import {createSimpleAction} from "../utils";

export const SET_DB = 'SET_DB';
export const SET_STUDIO_DATA = 'SET_STUDIO_DATA';
export const DB_SET_ABO_CHECKED = 'DB_SET_ABO_CHECKED';
export const INIT_THEMING = 'INIT_THEMING';
export const SET_MODE = 'SET_MODE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const PAYPAL_SDK_LOADED = 'PAYPAL_SDK_LOADED';
export const CHECK_CONNECTION = 'CHECK_CONNECTION';
export const SET_ERROR_STATE = 'SET_ERROR_STATE';
export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE';
export const CHECK_VERBINDUNG_ONGOING = 'CHECK_VERBINDUNG_ONGOING'
export const COMMON_LOAD_COMPLETED = 'COMMON_LOAD_COMPLETED'

export const setDb = (db) => ({
    type: SET_DB
    , payload: {
        db
    }
});

export const dbSetAndAboChecked = (db) => ({
    type: DB_SET_ABO_CHECKED
    , payload: {
        db
    }
});

export const setStudioData = (payload) => ({
    type: SET_STUDIO_DATA
    , payload
});

export const initTheming = theming => ({
    type: INIT_THEMING
    , payload: {theming}
});

export const setMode = mode => ({
    type: SET_MODE
    , payload: {mode}
});

export const setErrorState = createSimpleAction(SET_ERROR_STATE, 'errorState')
export const setLanguage = createSimpleAction(SET_LANGUAGE, 'language')

export const paypalSdkLoaded = () => ({type: PAYPAL_SDK_LOADED})
export const clearErrorState = () => ({type: CLEAR_ERROR_STATE})
export const commonLoadCompleted = () => ({type: COMMON_LOAD_COMPLETED})
export const setCheckVerbindungOngoing = createSimpleAction(CHECK_VERBINDUNG_ONGOING, 'checkVerbindungOngoing')
export const checkConnection = (error, back) => ({type: CHECK_CONNECTION, payload: {error, back}})


import {
    CHECK_VERBINDUNG_ONGOING,
    CLEAR_ERROR_STATE,
    INIT_THEMING,
    SET_DB,
    SET_ERROR_STATE,
    SET_LANGUAGE,
    SET_MODE,
    SET_STUDIO_DATA
} from "./commonActions";
import {replacePayloadFields} from "../utils";
import {mapKeys} from "lodash";

export const ALL_TEHMING_FIELDS = ['mode', 'primary', 'background', 'textcolor']

export const INITIAL_DARK_THEME = {
    mode: 'dark'
    , primary: '#5A8BCCFF'
    , background: '#191923FF'
    , textcolor: '#FDF6F0FF'
}

export const INITIAL_LIGHT_THEME = {
    mode: 'light'
    , primary: '#1976d2'
    , background: '#fff'
    , textcolor: 'rgba(0,0,0,0.87)'
}

const INITIAL = {
    db: null
    , studio: null
    , language: 'de'
    , theming: INITIAL_LIGHT_THEME
};

export default function commonReducer(state = INITIAL, action) {
    switch (action.type) {
        case SET_STUDIO_DATA:
            return {
                ...state
                , studio: action.payload.studio
                , UserID: action.payload.studioBase.UserID
                , produkte: mapKeys(action.payload.produkte, 'ProduktID')
                , allArtists: mapKeys(action.payload.allArtists, 'ArtistID')
                , rubriken: mapKeys(action.payload.rubriken, 'RubrikID')
                , terminarten: mapKeys(action.payload.terminarten, 'TerminArtID')
                , voucherStatus: action.payload.voucherStatus
                , persistedState: action.payload.persistedState
            }

        case SET_MODE:
            return {
                ...state
                , theming: {...state.theming, mode: action.payload.mode}
            }

        case INIT_THEMING:
            return {
                ...state
                , theming: {...action.payload.theming}
            }

        case SET_DB:
        case SET_LANGUAGE:
        case SET_ERROR_STATE:
        case CHECK_VERBINDUNG_ONGOING:
            return replacePayloadFields(state, action);

        case CLEAR_ERROR_STATE:
            return {
                ...state
                , errorState: undefined
            }

        default:
            return state;
    }
}

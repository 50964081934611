import React, {useEffect} from "react";
import TerminartWahl from "./TerminartWahl";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    selectSelectedTerminArtID,
    selectTerminArtListForSelectedRubrikID
} from "../../../store/appointment/appointmentSelectors";
import {changeActiveStep, changeTerminart} from "../../../store/appointment/appointmentActions";
import {useTheme} from "@mui/styles";
import {useMediaQuery} from "@mui/material";
import {PRODUKT} from "../BookingFlowStepperModel";

const TerminartWahlContainer = () => {
    const theme = useTheme()
    const {t} = useTranslation();
    const terminarten = useSelector(state => selectTerminArtListForSelectedRubrikID(state));
    const selectedTerminArtID = useSelector(state => selectSelectedTerminArtID(state));
    const dispatch = useDispatch();
    const isTabletOrGreater = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (!selectedTerminArtID && terminarten?.length === 1) {
            dispatch(changeTerminart(terminarten[0].TerminArtID))
            dispatch(changeActiveStep(PRODUKT))
        }
    }, [dispatch, terminarten, selectedTerminArtID])

    const handleChangeTerminArt = (terminartId) => {
        dispatch(changeTerminart(terminartId));
        dispatch(changeActiveStep(PRODUKT))
    }

    return (
        <TerminartWahl
            t={t}
            terminarten={terminarten}
            selectedTerminArtID={selectedTerminArtID}
            handleChangeTerminArt={handleChangeTerminArt}
            theme={theme}
            isTabletOrGreater={isTabletOrGreater}
        />
    )
}

export default TerminartWahlContainer
import {combineReducers} from "redux";
import StudioReducers from './common/commonReducer';
import AppointmentReducers from "./appointment/appointmentReducers";
import KundeReducers from "./kunde/kundeReducer";
import CheckoutReducer from "./checkout/checkoutReducer";
import VoucherReducers from "./voucher/voucherReducer";

const reducers = combineReducers({
    common: StudioReducers
    , appointment: AppointmentReducers
    , kunde: KundeReducers
    , checkout: CheckoutReducer
    , voucher: VoucherReducers
});

export default reducers;

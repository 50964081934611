import React, {useCallback, useEffect, useState} from "react";
import Checkout from "./Checkout";
import {useDispatch, useSelector} from "react-redux";
import {
    selectForwardUrl,
    selectSelectedPeriodAsText,
    selectSelectedTerminArtArtist,
    selectSelectedTerminArtKaution,
    selectStateLoaded
} from "../../store/appointment/appointmentSelectors";
import {
    selectCurrency,
    selectDB,
    selectGutscheinVorlagen,
    selectHeaderUrl,
    selectIsPaypalAllowed,
    selectIsSofortAllowed,
    selectLanguage
} from "../../store/common/commonSelectors";
import {
    selectCheckoutId,
    selectIsPaymentClosed,
    selectIsPaymentFailed,
    selectIsPaymentProcessing,
    selectIsPaymentSuccess,
    selectPaymentType,
    selectProcessedCheckoutId,
    selectStripeClientSecret,
    selectStripePaymentStatusOpenOrRetry,
    selectWillPayLater
} from "../../store/checkout/checkoutSelector";
import {createPaypalPayment} from "../../api/paypalApi";
import {useHistory} from "react-router-dom";
import {
    abortSofortPayment,
    checkCheckoutStatusAction,
    executePaypalOrderAction,
    initiateSofortPaymentAction,
    payLater,
    TERMINKAUTION_PAYMENT_TYPE
} from "../../store/checkout/checkoutActions";
import {selectEmail} from "../../store/kunde/kundeSelectors";
import {newBooking} from "../../store/appointment/appointmentActions";
import {setErrorState, setLanguage} from "../../store/common/commonActions";
import {PAYPAL_CREATE_ORDER_ERROR} from "../../store/common/commonSaga";
import {selectVoucherState} from "../../store/voucher/voucherSelectors";
import {downloadVoucher} from "../../utils/download";
import {useTranslation} from "react-i18next";

const CheckoutContainer = () => {
    const [selectedVoucherVorlage, setSelectedVoucherVorlage] = useState('')

    const stateLoaded = useSelector(state => selectStateLoaded(state))
    const headerUrl = useSelector(state => selectHeaderUrl(state));
    const waehrung = useSelector(state => selectCurrency(state))
    const lang = useSelector(state => selectLanguage(state))
    const terminkaution = useSelector(state => selectSelectedTerminArtKaution(state))
    const voucher = useSelector(state => selectVoucherState(state))
    const paymentType = useSelector(state => selectPaymentType(state))
    const db = useSelector(state => selectDB(state))
    const loaded = useSelector(state => selectStateLoaded(state))
    const checkoutId = useSelector(state => selectCheckoutId(state))
    const processedCheckoutId = useSelector(state => selectProcessedCheckoutId(state))
    const artist = useSelector(state => selectSelectedTerminArtArtist(state))
    const periodAsText = useSelector(state => selectSelectedPeriodAsText(state))
    const isPaymentSuccess = useSelector(state => selectIsPaymentSuccess(state))
    const isPaymentClosed = useSelector(state => selectIsPaymentClosed(state))
    const willPayLater = useSelector(state => selectWillPayLater(state))
    const isPaymentProcessing = useSelector(state => selectIsPaymentProcessing(state))
    const isStripePaymentStatusOpen = useSelector(state => selectStripePaymentStatusOpenOrRetry(state))
    const isPaymentFailed = useSelector(state => selectIsPaymentFailed(state))
    const stripeClientSecret = useSelector(state => selectStripeClientSecret(state))
    const email = useSelector(state => selectEmail(state))
    const voucherVorlagen = useSelector(state => selectGutscheinVorlagen(state))
    const isPaypalAllowed = useSelector(state => selectIsPaypalAllowed(state))
    const isSofortAllowed = useSelector(state => selectIsSofortAllowed(state))
    const forwardButtonConfig = useSelector(state => selectForwardUrl(state))
    const activeLanguage = useSelector(state => selectLanguage(state))
    const history = useHistory()
    const dispatch = useDispatch()
    const {i18n, t} = useTranslation()

    useEffect(() => {
        if (stateLoaded && !checkoutId && !processedCheckoutId && !willPayLater) {
            history.push('/')
        }

    }, [stateLoaded, checkoutId, processedCheckoutId, willPayLater, history])

    useEffect(() => {
        if (!selectedVoucherVorlage && !!voucherVorlagen?.length) {
            setSelectedVoucherVorlage(voucherVorlagen[0])
        }
    }, [selectedVoucherVorlage, voucherVorlagen])

    const createOrder = async () => {
        return await createPaypalPayment(db, checkoutId)
    }

    const onApprove = async (data) => {
        dispatch(executePaypalOrderAction(data))
    }

    const onPaypalError = () => {
        dispatch(setErrorState(PAYPAL_CREATE_ORDER_ERROR))
        history.push('error')
    }

    const initiateSofort = () => {
        dispatch(initiateSofortPaymentAction(isPaymentFailed))
    }

    const abortSofort = () => {
        dispatch(abortSofortPayment())
    }

    const onClickPayLater = () => {
        dispatch(payLater())
    }

    const checkStripePayment = () => {
        dispatch(checkCheckoutStatusAction())
    }

    const onClickNextBooking = () => {
        dispatch(newBooking())
        if (paymentType === TERMINKAUTION_PAYMENT_TYPE) {
            history.push('/')
        } else {
            history.push('/gutschein')
        }
    }

    const onChangeVoucherVorlage = (id) => {
        const vorlage = voucherVorlagen.find(t => t.PDFVorlagenID === id)
        if (vorlage) {
            setSelectedVoucherVorlage(vorlage)
        }
    }

    const onDownloadVoucher = async (vorlagenId) => {
        await downloadVoucher(db, processedCheckoutId, vorlagenId)
    }

    const handleChangeLanguage = useCallback((isoLang) => {
        dispatch(setLanguage(isoLang))
        i18n.changeLanguage(isoLang)
    }, [dispatch, i18n])

    return (
        <React.Fragment>
            {loaded && (!!checkoutId || !!processedCheckoutId || !!willPayLater) ?
                <Checkout stateLoaded={stateLoaded}
                          headerUrl={headerUrl}
                          waehrung={waehrung}
                          lang={lang}
                          paymentType={paymentType}
                          voucher={voucher}
                          terminkaution={terminkaution}
                          periodAsText={periodAsText}
                          artist={artist}
                          initiateSofort={initiateSofort}
                          abortSofort={abortSofort}
                          createOrder={() => createOrder()}
                          onApprove={data => onApprove(data)}
                          onPaypalError={onPaypalError}
                          onClickPayLater={onClickPayLater}
                          isPaymentSuccess={isPaymentSuccess}
                          isPaymentProcessing={isPaymentProcessing}
                          willPayLater={willPayLater}
                          showPaymentOptions={!isPaymentProcessing && !isPaymentClosed}
                          isStripePaymentStatusOpen={isStripePaymentStatusOpen}
                          isStripePayment={!!stripeClientSecret}
                          isPaymentFailed={isPaymentFailed}
                          email={email}
                          onClickNextBooking={onClickNextBooking}
                          checkStripePayment={checkStripePayment}
                          voucherVorlagen={voucherVorlagen}
                          onChangeVoucherVorlage={onChangeVoucherVorlage}
                          selectedVoucherVorlage={selectedVoucherVorlage}
                          onDownloadVoucher={onDownloadVoucher}
                          isPaypalAllowed={isPaypalAllowed}
                          isSofortAllowed={isSofortAllowed}
                          forwardButtonConfig={forwardButtonConfig}
                          onChangeLanguage={handleChangeLanguage}
                          t={t}
                          activeLanguage={activeLanguage}
                /> : null}
        </React.Fragment>
    )
}

export default CheckoutContainer
export const english = {
    "Produktkategorie": "Product Category",
    "Produkt": "Product",
    "PRODUKTI": "Product I",
    "PRODUKTII": "Product II",
    "Termin": "Appointment",
    "Egal": "No Matter",
    "TERMIN": "Appointment",
    "BESTAETIGUNG": "CONFIRMATION",
    "Rubrik": "Category",
    "RUBRIK": "Category",
    "Terminart": "Appointment Type",
    "RESERVATIONHINT": "We will reserve the slot for 30 minutes since selection for you. Please finish the booking until then.",
    "PREISNACHVEREINBARUNG": "n.V.: For these products the price will be settled upon agreement.",
    "ANZAHLUNG": "Deposit: {{ anzahlung }}.",
    "KOSTENPFLICHTIG_BUCHEN": "book with costs",
    "VERBINDLICH_BUCHEN": "binding booking",
    "ANFRAGE_SENDEN": "send request",
    "(Preis nach Vereinbarung)": "(price upon agreement)",
    "Bezahlung": "Payment",
    "BEZAHLUNG_ABGESCHLOSSEN": "Payment succeeded.",
    "BEZAHLE_SPAETER": "I will pay later",
    "ZAHLUNGSPROZESS_LAEUFT": "Processing Payment",
    "BUCHUNGSPROZESS_LAEUFT": "Processing Booking",
    "VERBINDUNGSFEHLER": "Connection Error",
    "VERBINDUNGSAUFBAU_NICHT_MOEGLICH": "Sorry, there cannot established a connection to the server. Please try later...",
    "VERBINDUNG_PRUEFEN": "Check Connection",
    "BUCHUNG_ERFOLGREICH": "The booking was successful. An email has been sent to {{email}}.",
    "GUTSCHEIN_VERSANDT": "An email with the voucher has been sent to {{email}}.",
    "WEITERE_BUCHUNG": "New Booking",
    "WEITERER_GUTSCHEIN": "New Voucher",
    "CHECK_ABO_FAILED": "There seems to be a configuration problem. Please contact the studio. (Code={{error}})",
    "NO_STUDIO_GIVEN": "There seems to be a configuration problem. Please contact the studio. (Code={{error}})",
    "CONFIGURATION_ERROR": "Error",
    "ZAHLUNG_FEHLGESCHLAGEN": "The payment has been canceled or failed. No payment has been processed. Try it again.",
    "PAYMENT_SERVICE_MISSING": "There seems to be a configuration problem. Please contact the studio. (Code={{error}})",
    "VOUCHER_ISSUER_ARTIST_MISSING": "There seems to be a configuration problem. Please contact the studio. (Code={{error}})",
    "MAIL_CONFIGURATION": "There seems to be a configuration problem. Please contact the studio. (Code={{error}})",
    "Betrag": "Amount",
    "VOUCHER_MESSAGE_LABEL": "Your Message",
    "KAUFE_GUTSCHEIN": "Buy Voucher",
    "GUTSCHEIN": "Voucher from {{von}} to {{fuer}}",
    "GUTSCHEIN_DOWNLOAD": "Voucher - Download",
    "VOUCHER_TEMPLATE": "Voucher Template",
    "VOUCHER_EMAIL": "Email",
    "VOUCHER_NACHRICHT": "Message",
    "CONFIRMATION.HEADER": "Check & Confirm",
    "KUNDE": "Customer",
    "PRODUKT": "Product",
    "TERMINART": "Category",
    "MESSAGE": "Message",
    "WEITERE_INFOS": "Send us a message or a template picture!",
    "KUNDE_MINDESTALTER_HINT": "You should be at least 14 yrs",
    "EMAIL_CONFIRMATION_INITIATED": "We have send a confirmation code to the given mail address. Please fill it in here to confirm.",
    "EMAIL_CONFIRMATION_PROBLEM": "There seems to be a problem confirming the mail address. Please contact the studio!",
    "EMAIL_CONFIRMATION_SUCCESS": "The email address has been confirmed. You can close the dialog!",
    "EMAIL_CONFIRMATION_FAILED": "The secret is not correct!",
    "EMAIL_CONFIRMATION_TITLE": "Email Confirmation",
    "EMAIL_CONFIRMATION_CODE": "Confirmation Code",
    "CONFIRMATION_USER_MESSAGE": "Your Message to us",
    "KUNDE_VORNAME_LABEL": "Name",
    "KUNDE_NACHNAME_LABEL": "Lastname",
    "KUNDE_EMAIL_LABEL": "Email",
    "KUNDE_BIRTHDAY_LABEL": "Birthday",
    "KUNDE_MOBILE_LABEL": "Mobile",
    "KUNDE_DESEASES_LABEL": "Deseases/Allergies",
    "TERMINWAHL_TERMIN_BLOCKED": "Unfortunately this date has been taken. BPlease choose another!",
    "CHECKOUT_TYPE": "Type",
    "CHECKOUT_TERMINKAUTION": "Deposit",
    "CUSTOMER_MESSAGE_HEADING": "Your Message to us",
    "BOOKING_ERROR": "Booking Error",
    "BOOKING_ERROR_MESSAGE": "The booking has probably been made, but there has been a problem sending the confirmation mail. Please contact the studio.",
    "BOOKING_ERROR_MESSAGE_WITH_CHECKOUTID": "The booking has probably been made, but there has been a problem sending the confirmation mail. Please contact the studio and provide the payment number {{paymentId}}.",
    "(Kostenfrei)": "free of charge",
    "NO_TIMESLOT_AVAILABLE": "Unfortunately there is no timeslot available",
    "KUNDE_HEADING": "Your Data",
    "PRODUKTWAHL_CHOOSE_MAX_ONE_PRODUKT_ALERT": "Please choose one product!",
    "PRODUKTWAHL_CHOOSE_MAX_PRODUKT_ALERT": "Please choose your products - {{maxProdukte}} maximum!",
    "PRODUKTWAHL_CHOOSE_PRODUKT_ALERT": "Please choose your products!",
    "TERMINWAHL_CHOOSE_LOCATION_ALERT": "Please first choose a location!",
    "TERMINWAHL_HEADING": "Date Selection",
    "TERMINANFRAGE_HEADING": "Location / Artist",
    "FILEINPUT.BUTTON_TEXT_ADD_PIC":"Add Image",
    "KUNDE.EMAIL_CHECK_HELPER_TEXT": "The email needs to be verified!",
    "KUNDE.EMAIL_CHECK_BUTTON_TEXT": "Verify",
    "KUNDE.CONTACT_LABEL_TEXT": "Preferred contact type",
    "KUNDE.CONTACT.SELECTION_TEXT_MOBIL": "Mobile",
    "KUNDE.CONTACT.SELECTION_TEXT_EMAIL": "Email",
    "BUTTON_TEXT_CLOSE": "Close",
    "BUTTON_TEXT_CHECK": "Check",
    "BUTTON_TEXT_RESEND": "Resend",
    "CONFIRMATION.PRIVACY_AGREEMENT_READ": "I have read the privacy agreement.",
    "VOUCHER_FUER": "for",
    "VOUCHER_VON": "from",
    "VOUCHER.HEADER": "Voucher",
    "LEGAL_FOOTER.LINK_TEXT_DATENSCHUTZ": "Privacy",
    "LEGAL_FOOTER.LINK_TEXT_IMPRESSUM": "Legal Notice"
}

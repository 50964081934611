import {createFieldSelector, identityFunction, isOlderThan14} from "../utils";
import {createSelector} from "reselect";

export const selectKundeState = createSelector(
    (state) => state.kunde
    , identityFunction
);

export const selectVorname = createFieldSelector(selectKundeState, 'vorname');
export const selectNachname = createFieldSelector(selectKundeState, 'nachname');
export const selectEmail = createFieldSelector(selectKundeState, 'email');
export const selectMobile = createFieldSelector(selectKundeState, 'mobile');
export const selectKontaktart = createFieldSelector(selectKundeState, 'kontaktart');
export const selectDeseases = createFieldSelector(selectKundeState, 'deseases');
export const selectEmailHash = createFieldSelector(selectKundeState, 'emailHash');
export const selectBirthday = createFieldSelector(selectKundeState, 'birthday');
export const selectEmailVerificationSuccess = createFieldSelector(selectKundeState, 'emailVerificationSuccess');
export const selectEmailVerificationOngoing = createFieldSelector(selectKundeState, 'verifyEmailOngoing');
export const selectEmailCheckBadRequest = createFieldSelector(selectKundeState, 'emailCheckBadRequest');
export const selectIsKundeValid = createSelector(
    selectVorname
    , selectNachname
    , selectEmail
    , selectMobile
    , selectBirthday
    , selectEmailVerificationSuccess
    , (vorname, nachname, email, mobile, birthday, emailVerification) => !!vorname
        && !!nachname && !!email && !!mobile && !!birthday && isOlderThan14(birthday) && emailVerification
)

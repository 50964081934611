import React from "react";
import {Button, Grid, TextField, Typography} from "@mui/material";
import StandardContainer from "../../common/style/StandardContainer";
import {numberFormat} from "../../../utils/Localization";
import {useTranslation} from "react-i18next";

const Voucher = ({
                     vonPar,
                     fuerPar,
                     emailPar,
                     changeAmount,
                     canChangeAmount,
                     betrag,
                     message,
                     changeMessage,
                     buyVoucher
                 }) => {
    const {t} = useTranslation()

    const canBook = !!vonPar?.value?.length && !!fuerPar?.value?.length && !!emailPar?.value?.length && !!betrag


    const renderChangeAmountButton = (amount) => {
        return (
            <Button variant="contained" style={{margin: '5px'}} color={amount > 0 ? 'primary' : 'secondary'}
                    key={amount} disabled={!canChangeAmount(amount)}
                    onClick={() => changeAmount(amount)}>{amount > 0 ? '+' : null}{amount}</Button>
        )
    }

    const validateEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return email && regex.test(email)
    }

    return (
        <StandardContainer>
            <Typography variant="h4" style={{marginBottom: '20px', textAlign: 'center'}}>{t('VOUCHER.HEADER')}</Typography>
            <TextField style={{paddingBottom: '20px'}}
                       variant="standard"
                       required
                       autoFocus={true}
                       error={vonPar.touched && !vonPar.value}
                       fullWidth
                       id="Von"
                       value={vonPar.value}
                       label={vonPar.label}
                       onChange={e => vonPar.setValue(e.target.value)}
                       inputProps={{onBlur: vonPar.onBlur}}
            />

            <TextField style={{paddingBottom: '20px'}}
                       variant="standard"
                       required
                       error={fuerPar.touched && !fuerPar.value}
                       fullWidth
                       id="Für"
                       value={fuerPar.value}
                       label={fuerPar.label}
                       onChange={e => fuerPar.setValue(e.target.value)}
                       inputProps={{onBlur: fuerPar.onBlur}}
            />

            <TextField style={{paddingBottom: '20px'}}
                       variant="standard"
                       required
                       error={emailPar.touched && !validateEmail(emailPar.value)}
                       fullWidth
                       id="Email"
                       value={emailPar.value}
                       label={emailPar.label}
                       onChange={e => emailPar.setValue(e.target.value)}
                       inputProps={{onBlur: emailPar.onBlur}}
            />
            <Grid container alignItems="center" spacing={3}>
                <Grid item>
                    <Typography variant="h5">{t('Betrag')}:</Typography>

                </Grid>
                <Grid item>
                    <Typography variant="h5">{numberFormat(betrag, 'de', 'EUR')}</Typography>
                </Grid>

            </Grid>
            <Grid container spacing={2} style={{marginBottom: '20px'}}>
                <Grid item>
                    {[100, 50, 20, 5].map(amt => renderChangeAmountButton(amt, "primary"))}
                </Grid>
                <Grid item>
                    {[-100, -50, -20, -5].map(amt => renderChangeAmountButton(amt, "default"))}
                </Grid>
            </Grid>
            <TextField style={{paddingBottom: '10px', marginBottom: '20px'}}
                       variant="standard"
                       fullWidth
                       multiline
                       maxrows={3}
                       id="msg"
                       value={message}
                       label={t('VOUCHER_MESSAGE_LABEL')}
                       onChange={e => changeMessage(e.target.value)}
            />

            <Grid container justifyContent="flex-end" style={{marginBottom: '40px'}}>
                <Button variant="contained" color="primary" disabled={!canBook}
                        onClick={buyVoucher}>{t('KAUFE_GUTSCHEIN')}</Button>
            </Grid>

        </StandardContainer>
    );

}

export default Voucher
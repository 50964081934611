import React from "react";
import Heading from "../common/style/Heading";
import OopsImage from "../../public/images/tattoo-yogi 5-min.jpg";
import {Alert} from "@mui/material";
import StandardContainer from "../common/style/StandardContainer";
import Button from "@mui/material/Button";

const BookingError = ({t, paymentId, onOk}) => {
    const error = paymentId ? t('BOOKING_ERROR_MESSAGE_WITH_CHECKOUTID', {paymentId}) : t('BOOKING_ERROR_MESSAGE')
    return (
        <StandardContainer>
            <Heading>{t('BOOKING_ERROR')}</Heading>
            <img src={OopsImage} width="100%"/>
            <Alert severity="error">{error}</Alert>
            <Button onClick={onOk} color="primary">
                Ok
            </Button>

        </StandardContainer>
    )
}

export default BookingError
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectBlockerBookingFailed,
    selectFreeTerminArtists,
    selectFreeTerminLocations,
    selectFreeTimeslots,
    selectIsSelectedTerminArtOhneTermin,
    selectLoadingTermineFinished,
    selectSelectedArtistID,
    selectSelectedPeriodAsText,
    selectSelectedPeriodId,
    selectSelectedProduktList,
    selectSelectedTatOrtID,
    selectTerminartSetupDauer,
    selectZeitTakt
} from "../../../store/appointment/appointmentSelectors";
import Terminwahl from "./Terminwahl";
import {
    changeActiveStep,
    changeArtist,
    changePeriod,
    changeTatort,
    clearBlockerBookingFailed
} from "../../../store/appointment/appointmentActions";
import {useTranslation} from "react-i18next";
import {convertTimeSlot2StartTimeSelectionWithLocale} from "../../../store/appointment/terminslotConverter";
import {selectLanguage, selectStudioTimezone} from "../../../store/common/commonSelectors";
import {getDauer} from "../../../models/Produkt";
import {BOOKING_KUNDE} from "../BookingFlowStepperModel";


const TerminwahlContainer = () => {
    const [periods, setPeriods] = useState([])
    const [timeslotId, setTimeslotId] = useState()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const selectableLocations = useSelector(state => selectFreeTerminLocations(state))
    const zeitTakt = useSelector(state => selectZeitTakt(state))
    const selectedTatOrtID = useSelector(state => selectSelectedTatOrtID(state))
    const selectedArtistID = useSelector(state => selectSelectedArtistID(state))
    const setupDauer = useSelector(state => selectTerminartSetupDauer(state))
    const freeArtists = useSelector(state => selectFreeTerminArtists(state))
    const timeslotSelection = useSelector(state => selectFreeTimeslots(state))
    const selectedPeriodId = useSelector(state => selectSelectedPeriodId(state))
    const selectedPeriodAsText = useSelector(state => selectSelectedPeriodAsText(state))
    const blockerBookingConflict = useSelector(state => selectBlockerBookingFailed(state))
    const produktList = useSelector(state => selectSelectedProduktList(state))
    const language = useSelector(state => selectLanguage(state))
    const timezone = useSelector(state => selectStudioTimezone(state))
    const loadingTermineFinished = useSelector(state => selectLoadingTermineFinished(state))
    const isTerminArtOhneTermin = useSelector(state => selectIsSelectedTerminArtOhneTermin(state))

    const getTimeslot = (timeslotId) => timeslotId ? timeslotSelection.find(t => t.id === timeslotId) : undefined

    const getSelectablePeriods = (timeslotId) => {
        const timeslot = getTimeslot(timeslotId)
        if (!timeslot) {
            return []
        }
        const duration = getDauer(produktList, setupDauer)
        return convertTimeSlot2StartTimeSelectionWithLocale(timeslot.value, duration, language, timezone, zeitTakt);
    }

    useEffect(() => {
        if (freeArtists?.length && !selectedArtistID && selectedTatOrtID) {
            if (freeArtists.length === 1) {
                dispatch(changeArtist(freeArtists[0].id))
                if (isTerminArtOhneTermin) {
                    dispatch(changeActiveStep(BOOKING_KUNDE))
                }
            } else if (!isTerminArtOhneTermin) {
                dispatch(changeArtist(-1))
            }

        }
    }, [freeArtists, selectedArtistID, selectedTatOrtID, dispatch, isTerminArtOhneTermin])

    const setArtistFromChosenTimeslot = (timeslotId) => {
        const timeslot = getTimeslot(timeslotId)
        if (timeslot && selectedArtistID < 0) {
            dispatch(changeArtist(timeslot.value.ArtistID))
        }
    }

    const handleChangeTatOrt = (event) => {
        dispatch(changeTatort(event.target.value))
        clearTimeslots()
    };
    const handleChangeArtist = (event) => {
        dispatch(changeArtist(event.target.value))
        clearTimeslots()
    };

    const clearTimeslots = () => {
        setPeriods([])
        setTimeslotId(undefined)
    }

    const handleChangeTimeslot = (event) => {
        setTimeslotId(event.target.value)
        setArtistFromChosenTimeslot(event.target.value)
        const periods = getSelectablePeriods(event.target.value)
        setPeriods(periods)
    }
    const handleChangePeriod = (event) => {
        if (!event) {
            dispatch(changePeriod(undefined))
            setTimeslotId(undefined)
            return
        }
        const selectedPeriod = periods.find(t => t.id === event.target.value)
        dispatch(changePeriod(selectedPeriod && selectedPeriod.value))
    }
    const handleBlockBookingConflictAlertClose = () => {
        dispatch(clearBlockerBookingFailed())
    }

    const enrichArtistList = (artistList) => {
        if (artistList && (artistList.length === 1 || isTerminArtOhneTermin)) {
            return artistList
        }
        return artistList?.length ? [{id: -1, text: 'Egal'}].concat(artistList) : []
    }


    const tatort = {selectionList: selectableLocations, selectedTatOrtID, onChange: handleChangeTatOrt}
    const artist = {selectionList: enrichArtistList(freeArtists), selectedArtistID, onChange: handleChangeArtist}
    const timeslot = {
        selectionList: timeslotSelection,
        selectedTimeslot: timeslotId,
        onChange: handleChangeTimeslot
    };
    const period = {
        selectionList: periods,
        selectedPeriodId,
        onChange: handleChangePeriod,
        hint: t('RESERVATIONHINT')
    };
    const blockerAlert = {blockerBookingConflict, onClose: handleBlockBookingConflictAlertClose}

    return (
        <Terminwahl tatort={tatort} artist={artist} timeslot={timeslot} period={period} blockerAlert={blockerAlert}
                    showBackdrop={!loadingTermineFinished}
                    isTerminArtOhneTermin={isTerminArtOhneTermin}
                    t={t}
                    selectedPeriodAsText={selectedPeriodAsText?.text}/>
    )

}

export default TerminwahlContainer

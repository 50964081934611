import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {loadPersistedUIState, saveUiStateAfterSelection} from "../../api/localPersistence";
import {
    ADD_IMAGE,
    addS3KeyToImage,
    BLOCKER_BOOKING_FAILED,
    blockerBookingFailed,
    BLOCKERID_CLEARED,
    CHANGE_ACTIVE_STEP,
    CHANGE_ARTIST,
    CHANGE_BLOCKERID,
    CHANGE_CUSTOMER_MESSAGE,
    CHANGE_DAATENSCHUTZ_READ,
    CHANGE_PERIOD,
    CHANGE_RUBRIK,
    CHANGE_TATORT,
    CHANGE_TERMINART,
    changeBlockerId,
    changeTatort,
    clearBlockerId,
    DESELECT_PRODUKT,
    IMAGE_UPLOADED,
    imageUploaded,
    LOAD_ARTIST_TERMINE_SUCCESS,
    LOAD_UI_STATE_ACTION,
    loadArtistTermineOngoing,
    loadArtistTermineSuccess,
    NEW_BOOKING,
    removeTempImage,
    SELECT_PRODUKT,
    SET_UI_STATE,
    setPersistedUIState,
    uploadingImage
} from "./appointmentActions";
import {getTermine} from "./terminLoader";
import {
    selectAppointmentState,
    selectBlockerId,
    selectFreeTerminLocations,
    selectSelectedArtistID,
    selectSelectedPeriod,
    selectSelectedProduktList,
    selectSelectedRubrik,
    selectSelectedTatOrtID,
    selectSelectedTerminArt,
    selectTerminartSetupDauer
} from "./appointmentSelectors";
import {selectArtistMap, selectDB, selectStudio} from "../common/commonSelectors";
import {bookBlocker, deleteBlocker, saveTempImage} from "../../api/studioApi";
import history from "../../history";
import {BLOCKER_UPDATE_ERROR, LOAD_TERMINE_ERROR} from "../common/commonSaga";
import {createErrorPathWithCurrentLocationAsBack} from "../utils";
import {getDauer} from "../../models/Produkt";

function* loadAndSetPersistedUIStage() {
    const persistedState = yield call(loadPersistedUIState);
    yield put(setPersistedUIState(persistedState))
}

function* watchLoadUIStateAction() {
    yield takeLatest(LOAD_UI_STATE_ACTION, loadAndSetPersistedUIStage)
}

function* persistUIStateForSelection() {
    const appointmentState = yield select(state => selectAppointmentState(state));
    yield call(saveUiStateAfterSelection, appointmentState)
}

function* watchPersistedUiState() {
    yield takeLatest([CHANGE_RUBRIK, CHANGE_TERMINART
        , SELECT_PRODUKT, DESELECT_PRODUKT, CHANGE_TATORT, CHANGE_ARTIST, CHANGE_CUSTOMER_MESSAGE, IMAGE_UPLOADED
        , CHANGE_PERIOD, CHANGE_BLOCKERID, BLOCKERID_CLEARED, CHANGE_ACTIVE_STEP, CHANGE_DAATENSCHUTZ_READ,
        NEW_BOOKING], persistUIStateForSelection)
}

export function* calculateTerminslots() {
    const selectedTerminart = yield select(state => selectSelectedTerminArt(state));
    const setupDauer = yield select(state => selectTerminartSetupDauer(state));
    const selectedRubrik = yield select(state => selectSelectedRubrik(state))
    const selectedProdukte = yield select(state => selectSelectedProduktList(state));
    const db = yield select(state => selectDB(state));
    const allArtists = yield select(state => selectArtistMap(state));
    const studio = yield select(state => selectStudio(state));
    if (selectedTerminart && selectedRubrik && selectedProdukte?.length) {
        yield put(loadArtistTermineOngoing());
        const duration = getDauer(selectedProdukte, setupDauer)
        try {
            const termine = yield call(getTermine, db, allArtists, studio, selectedTerminart, selectedRubrik, duration);
            yield put(loadArtistTermineSuccess(termine));
        } catch (e) {
            console.log(e)
            history.push(createErrorPathWithCurrentLocationAsBack('/error', LOAD_TERMINE_ERROR))
        }

    }
}

function* watchProduktSelection() {
    yield takeLatest([SET_UI_STATE, SELECT_PRODUKT, DESELECT_PRODUKT, BLOCKER_BOOKING_FAILED], calculateTerminslots)
}

function* initializeLocationAndArtist() {
    const terminLocations = yield select(state => selectFreeTerminLocations(state));
    const selectedTatOrtID = yield select(state => selectSelectedTatOrtID(state));
    if (!selectedTatOrtID
        && terminLocations && terminLocations.length) {
        console.log('initialLoadCompleted initialize tatort and artist');
        if (!selectedTatOrtID && terminLocations.length === 1) {
            yield put(changeTatort(terminLocations[0].id));
        }
    }
}

function* watchloadArtistTermineSuccess() {
    yield takeLatest(LOAD_ARTIST_TERMINE_SUCCESS, initializeLocationAndArtist)
}

function* watchSetPeriod() {
    yield takeLatest(CHANGE_PERIOD, bookBlockerAndUpdateBlockerID)
}

export function* bookBlockerAndUpdateBlockerID() {
    const selectedArtistID = yield select(state => selectSelectedArtistID(state));
    const selectedPeriod = yield select(state => selectSelectedPeriod(state));
    const db = yield select(state => selectDB(state));
    if (!!selectedArtistID && !!selectedPeriod) {
        try {
            const blockerId = yield call(bookBlocker, db, selectedArtistID, selectedPeriod);
            if (blockerId) {
                yield put(changeBlockerId(blockerId));
            } else {
                yield put(blockerBookingFailed());
            }
        } catch (e) {
            console.log(e);
            history.push(createErrorPathWithCurrentLocationAsBack('/error', BLOCKER_UPDATE_ERROR))
        }

    }
}

function* watchBlockIdNeedCleared() {
    yield takeLatest([DESELECT_PRODUKT, CHANGE_TATORT, CHANGE_ARTIST, CHANGE_PERIOD], clearBlocker)
}

function* clearBlocker() {
    const db = yield select(state => selectDB(state));
    const blockerId = yield select(state => selectBlockerId(state));
    if (blockerId) {
        try {
            yield deleteBlocker(db, blockerId);
            yield put(clearBlockerId());
        } catch (e) {
            console.log('blocker delete failed ******************+++');
        }

    }
}

function* putTempImageToStore({payload}) {
    const db = yield select(state => selectDB(state));
    if (payload.image) {
        try {
            yield put(uploadingImage())
            const key = yield saveTempImage(db, payload.image)
            yield put(addS3KeyToImage({filename: payload.image.name, id: payload.image.id, key}))
            yield put(imageUploaded())
        } catch (e) {
            console.error('saving image failed')
            yield put(removeTempImage(payload.image.id))
        }

    }
}

function* watchAddImage() {
    yield takeLatest(ADD_IMAGE, putTempImageToStore)
}

const sagas = [fork(watchLoadUIStateAction)
    , fork(watchPersistedUiState)
    , fork(watchProduktSelection)
    , fork(watchloadArtistTermineSuccess)
    , fork(watchSetPeriod)
    , fork(watchBlockIdNeedCleared)
    , fork(watchAddImage)
];

export default sagas;

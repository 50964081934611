import React from "react";
import {Card, CardContent, CardMedia, IconButton, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

const ImageCard = ({id, titel, imageLocation, deleteHandler}) => {

    return (
        <Card>
            <CardMedia style={{height: 150}}
                       image={imageLocation}
                       component='img'
            />
            <CardContent>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div>
                        <Typography variant="subtitle1">{titel}</Typography>
                    </div>
                    <div>
                        <IconButton aria-label="delete" onClick={() => deleteHandler(id)} size="large">
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

export default ImageCard;

import {
    ABORT_SOFORT_PAYMENT,
    CHECKOUTID_REGISTERED,
    EXECUTE_PAYPAL_ORDER,
    INITIATE_PAY_LATER,
    INITIATE_SOFORT_PAYMENT,
    PAY_LATER_FAILURE,
    PAY_LATER_SUCCESS,
    PAYPAL_PAYMENT_FAILURE,
    PAYPAL_PAYMENT_SUCCESS,
    PENDING_PAYMENT_CLEARED,
    PENDING_TERMINKAUTION_PAYMENT,
    PENDING_VOUCHER_PAYMENT,
    PERSISTED_CHECKOUT_STATE_LOADED,
    PROCESS_TERMIN_BOOKING_PAY_LATER,
    PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE,
    PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS,
    SOFORT_CHECK_CHECKOUT_STATUS_SUCCESS,
    SOFORT_PAYMENT_INITIATED_SUCCESS,
    TERMINKAUTION_PAYMENT_TYPE
} from "./checkoutActions";
import {replacePayloadFields} from "../utils";
import {
    ADD_IMAGE,
    CHANGE_ARTIST,
    CHANGE_CUSTOMER_MESSAGE,
    CHANGE_PERIOD,
    CHANGE_RUBRIK,
    CHANGE_TATORT,
    CHANGE_TERMINART,
    DESELECT_PRODUKT,
    NEW_BOOKING,
    REMOVE_IMAGE,
    SELECT_PRODUKT
} from "../appointment/appointmentActions";
import {SET_ERROR_STATE} from "../common/commonActions";

const PROCESSING_SOFORT_STATI = ['open', 'pending', 'retry']

const processCheckoutId = checkoutId => checkoutId ? ({
    checkoutId: undefined,
    processedCheckoutId: checkoutId
}) : {}

export default function CheckoutReducer(state = {}, action) {
    switch (action.type) {
        case PENDING_TERMINKAUTION_PAYMENT:
        case PENDING_VOUCHER_PAYMENT:
        case PERSISTED_CHECKOUT_STATE_LOADED:
            return replacePayloadFields(state, action);
        case CHECKOUTID_REGISTERED:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , checkoutId: action.payload.checkoutId
                    , processedCheckoutId: undefined
                }
            }
        case EXECUTE_PAYPAL_ORDER:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , paypalOrder: action.payload.paypalOrder
                    , stripePaymentStatus: undefined
                    , stripeClientSecret: undefined
                }
            }
        case PAYPAL_PAYMENT_SUCCESS:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , ...processCheckoutId(state.payment.checkoutId)
                    , paypalOrderId: action.payload.paypalOrderId
                    , paypalOrder: undefined
                    , processing: false
                }
            }
        case SOFORT_PAYMENT_INITIATED_SUCCESS:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , stripeClientSecret: action.payload.stripeClientSecret
                }
            }
        case INITIATE_PAY_LATER:
        case PROCESS_TERMIN_BOOKING_PAY_LATER:
            return {
                ...state
                , payment: {
                    pendingPaymentType: TERMINKAUTION_PAYMENT_TYPE
                    , payLater: true
                    , processing: true
                }
            }
        case INITIATE_SOFORT_PAYMENT:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , processing: true
                    , paypalOrderId: undefined
                    , stripePaymentStatus: undefined
                    , stripeClientSecret: undefined
                }
            }
        case ABORT_SOFORT_PAYMENT:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , stripePaymentStatus: 'failed'
                    , processing: false
                    , stripeClientSecret: undefined
                }
            }
        case SOFORT_CHECK_CHECKOUT_STATUS_SUCCESS: {
            const processing = state.payment.processing
                && (!action.payload.checkoutStatus || PROCESSING_SOFORT_STATI.includes(action.payload.checkoutStatus))
            let payment = {
                ...state.payment
                , stripePaymentStatus: action.payload.checkoutStatus
                , processing
            }
            if (!processing) {
                payment = {...payment, ...processCheckoutId(payment.checkoutId)}
            }
            return {
                ...state
                , payment
            }

        }
        case PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS:
        case PAY_LATER_SUCCESS:
            return {
                ...state
                , payment: {
                    ...state.payment
                    , payLater: true
                    , processing: false
                }
            }
        case SET_ERROR_STATE:
            return {
                ...state
                , payment: state.payment ? {
                    ...state.payment
                    , processing: false
                } : undefined
            }
        case NEW_BOOKING:
        case CHANGE_RUBRIK:
        case CHANGE_TERMINART:
        case CHANGE_TATORT:
        case CHANGE_ARTIST:
        case CHANGE_PERIOD:
        case SELECT_PRODUKT:
        case DESELECT_PRODUKT:
        case ADD_IMAGE:
        case REMOVE_IMAGE:
        case CHANGE_CUSTOMER_MESSAGE:
        case PENDING_PAYMENT_CLEARED:
        case PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE:
        case PAY_LATER_FAILURE:
        case PAYPAL_PAYMENT_FAILURE:
            return {
                ...state
                , payment: undefined
            }
        default:
            return state
    }
}
import React, {useCallback} from "react";
import {Box, CssBaseline, Grid, Paper} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {selectStateLoaded} from "../../../store/appointment/appointmentSelectors";
import {selectHeaderUrl, selectLanguage} from "../../../store/common/commonSelectors";
import LangSelector from "../../language/langSelector";
import {setLanguage} from "../../../store/common/commonActions";
import {useTranslation} from "react-i18next";

const StandardContainer = ({children}) => {
    const stateLoaded = useSelector(state => selectStateLoaded(state))
    const headerUrl = useSelector(state => selectHeaderUrl(state));
    const activeLanguage = useSelector(state => selectLanguage(state))
    const dispatch = useDispatch()
    const {i18n} = useTranslation()

    const handleChangeLanguage = useCallback((isoLang) => {
        dispatch(setLanguage(isoLang))
        i18n.changeLanguage(isoLang)
    }, [dispatch, i18n])

    return (
        <React.Fragment>
            <CssBaseline>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                        <Box position="relative">
                            {stateLoaded ? <img src={headerUrl} width="100%" alt="studio-logo"/> : null}
                            <Box position='absolute' top='0' right='7px'>
                                <LangSelector isoLang={activeLanguage || 'de'} onChange={handleChangeLanguage}/>
                            </Box>
                        </Box>

                        <Paper style={{width: '100%', padding: '0 7px', minHeight: '100vh'}}>
                            {children}
                        </Paper>
                    </Grid>
                </Grid>
            </CssBaseline>
        </React.Fragment>
    );
}

export default StandardContainer
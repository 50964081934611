import React, {useMemo, useRef} from "react";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField,} from "@mui/material";
import {formatLocalDate, isOlderThan14} from "../../../store/utils";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import {plus} from "@kiss-solutions/dateutils";
import Heading from "../../common/style/Heading";
import {makeStyles, useTheme} from "@mui/styles";

const KONTAKTARTEN = [
    {id: 1, text: 'KUNDE.CONTACT.SELECTION_TEXT_MOBIL'}
    , {id: 2, text: 'KUNDE.CONTACT.SELECTION_TEXT_EMAIL'}
];

const localeMap = {
    en: enLocale,
    de: deLocale,
};

const maskMap = {
    en: '__/__/____',
    de: '__.__.____',
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiButtonBase-root': {
            color: theme.palette.primary.main
        }
    }
}));

const Kunde = ({
                   vorname,
                   nachname,
                   email,
                   emailCheck,
                   birthday,
                   mobile,
                   kontaktart,
                   deseases,
                   infotextDeseases,
                   lang,
                   t
               }) => {
    const isTooYoung = birthday.value && !isOlderThan14(birthday.value)
    const ageHelperText = isTooYoung ? t('KUNDE_MINDESTALTER_HINT') : null
    const isNotValid = isNaN(birthday.value.getTime());
    const theme = useTheme()
    const lastNameRef = useRef()
    const birthdayRef = useRef()
    const emailRef = useRef()
    const mobileRef = useRef()
    const deseasesRef = useRef()
    const classes = useStyles(theme)

    const emailHelperText = useMemo(() =>  emailCheck.visible && email.value?.length ? t('KUNDE.EMAIL_CHECK_HELPER_TEXT') : undefined, [email.value?.length, emailCheck.visible, t])
    const maxDate = plus(new Date(), -14, "year")

    const renderButton = () => {
        if (!emailCheck.visible) {
            return null;
        }
        return (
            <Button color="primary"
                    disabled={emailCheck.disabled}
                    onClick={emailCheck.onClick}
            >{t('KUNDE.EMAIL_CHECK_BUTTON_TEXT')}</Button>
        );
    }

    const SelectionItem = (id, bezeichnung) => {
        return (
            <MenuItem key={id} value={id}>{t(bezeichnung)}</MenuItem>
        )
    }

    const renderKontaktart = () => {
        if (!email.value || !mobile.value) {
            return null;
        }
        return (
            <FormControl variant="standard" fullWidth style={{marginTop: '10px', paddingBottom: '20px'}}>
                <InputLabel id="kontaktart-label">{t('KUNDE.CONTACT_LABEL_TEXT')}</InputLabel>
                <Select
                    labelId="kontaktart-label"
                    id="kontaktart-Selection"
                    value={kontaktart.value}
                    onChange={e => kontaktart.setValue(e.target.value)}
                    onBlur={kontaktart.onBlur}
                >
                    {KONTAKTARTEN.map(t => SelectionItem(t.id, t.text))}
                </Select>
            </FormControl>
        )
    }

    const focusFieldOnKeyEvent = (event, ref) => {
        const {key} = event
        if (key === "Enter") {
            ref.current.focus();
        }
    }

    return (
        <React.Fragment>
            <Heading>{t('KUNDE_HEADING')}</Heading>
            <TextField style={{paddingBottom: '20px'}}
                       required
                       error={vorname.touched && !vorname.value}
                       fullWidth
                       id="vorname"
                       variant="standard"
                       value={vorname.value}
                       label={vorname.label}
                       onChange={e => vorname.setValue(e.target.value)}
                       inputProps={{
                           onBlur: vorname.onBlur, onKeyPress: event => focusFieldOnKeyEvent(event, lastNameRef)
                       }}
                       autoFocus={true}
            />
            <TextField style={{marginTop: '10px', paddingBottom: '20px'}}
                       inputRef={lastNameRef}
                       required
                       variant="standard"
                       error={nachname.touched && !nachname.value}
                       fullWidth
                       id="nachname"
                       value={nachname.value}
                       label={nachname.label}
                       onChange={e => nachname.setValue(e.target.value)}
                       inputProps={{
                           onBlur: nachname.onBlur,
                           onKeyPress: event => focusFieldOnKeyEvent(event, birthdayRef)
                       }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}
                                  locale={localeMap[lang]}
                                  style={{marginTop: '10px', paddingBottom: '20px'}}>
                <DatePicker
                    renderInput={(props) => <TextField required fullWidth
                                                       helperText={ageHelperText}
                                                       onBlur={birthday.onBlur}
                                                       variant="standard" {...props}
                                                       error={(birthday.touched || !!email.value || !!mobile.value) && (isNotValid || isTooYoung)}/>}
                    id="date-picker-dialog"
                    label={t('KUNDE_BIRTHDAY_LABEL')}
                    mask={maskMap[lang]}
                    className={classes.svg}
                    InputProps={{"data-testid": "birthday"}}
                    inputRef={birthdayRef}
                    maxDate={maxDate}
                    value={new Date(birthday.value)}
                    onChange={e => {
                        if (e && e.getTime()) {
                            birthday.setValue(formatLocalDate(e))
                        } else {
                            birthday.setValue(formatLocalDate(new Date()))
                        }
                    }}
                    invalidDateMessage="Ungültiges Datum"
                    // renderDay={renderWeekPickerDay}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </LocalizationProvider>
            <Grid container={true} style={{marginTop: '10px', paddingBottom: '20px'}}>
                <TextField
                    error={email.errorState}
                    style={{flex: 1}}
                    variant="standard"
                    inputRef={emailRef}
                    required
                    id="email"
                    value={email.value}
                    label={email.label}
                    onChange={e => email.setValue(e.target.value)}
                    helperText={emailHelperText}
                    inputProps={{onBlur: email.onBlur, onKeyPress: event => focusFieldOnKeyEvent(event, mobileRef)}}
                />
                {renderButton()}
            </Grid>
            <TextField style={{marginTop: '10px', paddingBottom: '20px'}}
                       fullWidth
                       inputRef={mobileRef}
                       error={mobile.touched && !mobile.value}
                       required
                       id="mobile"
                       variant="standard"
                       value={mobile.value}
                       label={mobile.label}
                       onChange={e => mobile.setValue(e.target.value)}
                       inputProps={{
                           onBlur: mobile.onBlur,
                           onKeyPress: event => focusFieldOnKeyEvent(event, deseasesRef)
                       }}
            />
            {renderKontaktart()}
            <TextField style={{marginTop: '10px', paddingBottom: '20px'}}
                       fullWidth
                       inputRef={deseasesRef}
                       multiline
                       helperText={infotextDeseases}
                       variant="standard"
                       maxrows={4}
                       id="deseases"
                       value={deseases.value}
                       label={deseases.label}
                       onChange={e => deseases.setValue(e.target.value)}
                       inputProps={{onBlur: deseases.onBlur}}
            />
        </React.Fragment>
    )
}

export default Kunde;

import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { resizedataURL, resizeFile } from "../../../utils/image-resizer";

import AddIcon from '@mui/icons-material/Add';
import uuid from "react-uuid";
import {useTranslation} from "react-i18next";

const IMAGE_FORMATS = ['image/gif', 'image/jpeg', 'image/png', 'image/heif'];

const FileInput = ({ onAddImage, onError, disabled }) => {
    const {t} = useTranslation()
    const [isConverting, setIsConverting] = useState(false);
    const handleChange = async (event) => {
        if (!event.target.files.length) {
            onError();
            return;
        }
        const file = event.target.files[0];
        if (!IMAGE_FORMATS.includes(file.type)) {
            onError();
            return;
        }
        setIsConverting(true);
        if (file.type === 'image/heif') {
            const heic2any = await import('heic2any')
            const blobConverted = await heic2any.default({
                blob: file,
                toType: 'image/jpeg',
                quality: 0.94,
            });
            setIsConverting(false);
            const reader = new FileReader();
            reader.onload = async e => {
                const dataURL = await resizedataURL(e.target.result, 800, 800);
                onAddImage({ name: file.name, dataURL, id: uuid() });
            };
            reader.onerror = onError;
            reader.readAsDataURL(blobConverted);
        } else {
            const dataURL = await resizeFile(file, 800, 800);
            setIsConverting(false);
            onAddImage({ name: file.name, dataURL, id: uuid() });
        }
        // enable select same file twice
        event.target.value = ''
    }

    return (
        <React.Fragment>
            <Button variant="contained"
                disabled={isConverting || disabled}
                startIcon={<AddIcon />}
                component="label">
                {t('FILEINPUT.BUTTON_TEXT_ADD_PIC')}
                {isConverting && <CircularProgress style={{ marginLeft: '10px' }} size={20} color='primary' />}
                <input type="file"
                    onChange={handleChange}
                    hidden
                    id="icon-button-file" />
            </Button>
        </React.Fragment>
    );
}

export default FileInput;
import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {loadPersistedVoucherState, saveVoucherState} from "../../api/localPersistence";
import {DB_SET_ABO_CHECKED} from "../common/commonActions";
import {LOAD_VOUCHER_FROM_CHECKOUT, loadPersistedVoucherToState, voucherFromCheckoutLoaded} from "./voucherActions";
import {selectVoucherState} from "./voucherSelectors";
import {PENDING_VOUCHER_PAYMENT} from "../checkout/checkoutActions";
import {NEW_BOOKING} from "../appointment/appointmentActions";
import {getCheckoutById} from "../../api/studioApi";
import {selectDB} from "../common/commonSelectors";
import {pick} from "lodash/object";
import {LOAD_VOUCHER_FROM_CHECKOUT_ERROR} from "../common/commonSaga";
import history from "../../history";
import {createErrorPathWithCurrentLocationAsBack} from "../utils";

function* loadAndSetPersistedVoucherStage() {
    try {
        const persistedState = yield call(loadPersistedVoucherState);
        yield put(loadPersistedVoucherToState(persistedState))
    } catch (e) {
        console.log(e);
    }
}

function* watchSetDB() {
    yield takeLatest(DB_SET_ABO_CHECKED, loadAndSetPersistedVoucherStage)
}

function* executePersistVoucher() {
    const voucherState = yield select(state => selectVoucherState(state));
    yield call(saveVoucherState, voucherState);
}

function* watchPersistVoucher() {
    yield takeLatest([PENDING_VOUCHER_PAYMENT, NEW_BOOKING], executePersistVoucher);
}

function* loadVoucherFromCheckout({payload}) {
    const db = yield select(state => selectDB(state));
    if (!!db && !!payload?.checkoutId) {
        try {
            const checkout = yield call(getCheckoutById, db, payload.checkoutId, true)
            const content = JSON.parse(checkout.content)
            const voucher = pick(content, ['von', 'fuer', 'email', 'betrag', 'message'])
            yield put(voucherFromCheckoutLoaded(voucher))
        } catch (e) {
            console.log(e)
            history.push(createErrorPathWithCurrentLocationAsBack('/error', LOAD_VOUCHER_FROM_CHECKOUT_ERROR))
        }
    }
}

function* watchLoadVoucherFromCheckout() {
    yield takeLatest([LOAD_VOUCHER_FROM_CHECKOUT], loadVoucherFromCheckout)
}


const voucherSaga = [
    fork(watchSetDB)
    , fork(watchPersistVoucher)
    , fork(watchLoadVoucherFromCheckout)
]

export default voucherSaga
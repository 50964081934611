import React from "react";
import {Fade} from "@mui/material";
import RubrikElement from "./RubrikElement";

const RubrikWahl = ({
                        t,
                        rubriken,
                        selectedRubrikID,
                        handleRubrikSelection,
                        imageBasePath,
                        isTabletOrGreater,
                        theme
                    }) => {
    return (
        <React.Fragment>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                {
                    rubriken && rubriken.map((p, idx) =>
                        <Fade key={idx} in={true} timeout={(idx + 1) * 1000}>
                            <div key={idx}>
                                <RubrikElement rubrik={p}
                                               checked={selectedRubrikID === p.RubrikID}
                                               handleRubrikSelection={handleRubrikSelection}
                                               imageBasePath={imageBasePath}
                                               isTabletOrGreater={isTabletOrGreater}
                                               theme={theme}
                                               key={p.RubrikID}/></div>
                        </Fade>)
                }
            </div>
        </React.Fragment>
    )
}

export default RubrikWahl
import {getVoucherBlob} from "../api/studioApi";

export const convertPdfBlob2FileDownload = (blob) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.setAttribute(
        'download',
        `Voucher.pdf`,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}

export const downloadVoucher = async (db, checkoutId, vorlagenId) => {
    const blob = await getVoucherBlob(db, checkoutId, vorlagenId)
    convertPdfBlob2FileDownload(blob)
}
import uuid from "react-uuid";

export const createOpenVoucherCheckoutRecord = (lang, waehrung, {von, fuer, betrag, message, email}) => ({
    id: uuid()
    , type: 'voucher'
    , paymentid: null
    , status: 'open'
    , content: {
        von
        , fuer
        , betrag
        , waehrung
        , message
        , email
        , datout: new Date().toLocaleDateString()
    }
    , lang
})
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {CircularProgress, Grid} from "@mui/material";

const EmailConfirmation = ({
                               t,
                               open,
                               setOpen,
                               email,
                               verifyEmailSecret,
                               emailVerificationSuccess,
                               emailVerificationOngoing,
                               resendEmail,
                               emailCheckBadRequest
                           }) => {
    const [secret, setSecret] = useState('');
    const [checkTried, setCheckTried] = useState(false);

    const onClickVerify = () => {
        setCheckTried(true);
        verifyEmailSecret(secret);
    }

    const infoText = emailCheckBadRequest ? t('EMAIL_CONFIRMATION_PROBLEM')
        : emailVerificationSuccess ? t('EMAIL_CONFIRMATION_SUCCESS')
            : t('EMAIL_CONFIRMATION_INITIATED');

    const renderSuccessIcon = () => {
        if (emailVerificationSuccess) {
            return (
                <React.Fragment>
                    <CheckCircleOutlineIcon color="primary" fontSize="large"/>
                </React.Fragment>
            );
        }
        return null;
    }

    const closeDialog = () => {
        setSecret('');
        setCheckTried(false);
        setOpen(false);
    }

    const renderSpinner = () => {
        return (
            <React.Fragment>
                <CircularProgress/>&nbsp;
            </React.Fragment>
        )
    }

    const renderCheckButton = () => {
        if (emailVerificationSuccess) {
            return null;
        }
        return (
            <Button onClick={onClickVerify} color="primary" disabled={!secret || emailVerificationOngoing}>
                {emailVerificationOngoing ? renderSpinner() : null}{t('BUTTON_TEXT_CHECK')}
            </Button>
        );
    }

    const renderResendButton = () => {
        if (emailVerificationSuccess) {
            return (<div></div>);
        }
        return (
            <Button onClick={resendEmail} disabled={emailVerificationOngoing} color="primary">
                {t('BUTTON_TEXT_RESEND')}
            </Button>
        );
    }

    const helperText = emailVerificationSuccess === false ? t('EMAIL_CONFIRMATION_FAILED') : undefined;

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('EMAIL_CONFIRMATION_TITLE')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span style={{fontWeight: 'bold'}}>{email}</span><br/>
                    {infoText}
                </DialogContentText>
                {!emailCheckBadRequest ? <Grid container={true} alignItems="flex-end">
                    <TextField
                        style={{flex: 1}}
                        error={checkTried && emailVerificationSuccess === false}
                        autoFocus
                        margin="dense"
                        variant="standard"
                        id="confirmation"
                        label={t('EMAIL_CONFIRMATION_CODE')}
                        type="text"
                        value={secret}
                        onChange={e => setSecret(e.target.value)}
                        helperText={helperText}
                    />
                    {renderSuccessIcon()}
                </Grid> : null}

            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between">
                    {!emailCheckBadRequest ? renderResendButton() : <div></div>}
                    <div>
                        <Button onClick={closeDialog} disabled={emailVerificationOngoing} color="secondary">
                            {t('BUTTON_TEXT_CLOSE')}
                        </Button>
                        {!emailCheckBadRequest ? renderCheckButton() : null}
                    </div>
                </Grid>

            </DialogActions>
        </Dialog>
    );
}

export default EmailConfirmation;

import React, {useEffect} from "react";
import Produktwahl from "./Produktwahl";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrency, selectStudioimageBasePath} from "../../../store/common/commonSelectors";
import {useTranslation} from "react-i18next";
import {
    isMaximumProduktListReached,
    selectFreeTerminArtists,
    selectLoadingTermineFinished,
    selectProduktListOfSelectedTerminArtID,
    selectSelectedProduktIDList,
    selectSelectedTerminArt,
    selectSelectedTerminArtID
} from "../../../store/appointment/appointmentSelectors";
import {changeActiveStep, deselectProdukt, selectProdukt} from "../../../store/appointment/appointmentActions";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@emotion/react";
import {CUSTOMER_MESSAGE} from "../BookingFlowStepperModel";

const ProduktwahlContainer = () => {
    const [openMaxProduktAlert, setOpenMaxProduktAlert] = React.useState(false)
    const [productSelectionChanged, setProductSelectionChanged] = React.useState(false)
    const {t} = useTranslation();
    const selectedTerminArtID = useSelector(state => selectSelectedTerminArtID(state))
    const selectedTerminArt = useSelector(state => selectSelectedTerminArt(state))
    const produkte = useSelector(state => selectProduktListOfSelectedTerminArtID(state))
    const produktList = useSelector(state => selectSelectedProduktIDList(state))
    const isMaximumReached = useSelector(state => isMaximumProduktListReached(state))
    const studioimageBasePath = useSelector(state => selectStudioimageBasePath(state))
    const currency = useSelector(state => selectCurrency(state))
    const loadingTermineFinished = useSelector(state => selectLoadingTermineFinished(state))
    const freeArtists = useSelector(state => selectFreeTerminArtists(state))
    const dispatch = useDispatch()
    const theme = useTheme()
    const isTabletOrGreater = useMediaQuery(theme.breakpoints.up('sm'))

    const showNoPruductAvailable = loadingTermineFinished && !freeArtists?.length && produktList?.length

    useEffect(() => {
        if (produktList?.length && loadingTermineFinished && productSelectionChanged) {
            const maxProdukte = selectedTerminArt?.MaxProduktAnzahl || 100
            const shouldSwitchNext = produktList.length >= maxProdukte
            if (shouldSwitchNext && freeArtists?.length) {
                dispatch(changeActiveStep(CUSTOMER_MESSAGE))
            }
        }

    }, [freeArtists, produktList, loadingTermineFinished, productSelectionChanged, dispatch, selectedTerminArt])

    const handleProduktSelection = (produktId) => {
        if (!produktList.includes(produktId)) {
            if (isMaximumReached) {
                setOpenMaxProduktAlert(true);
            } else {
                setProductSelectionChanged(true)
                dispatch(selectProdukt(produktId));
            }
        } else {
            dispatch(deselectProdukt(produktId));
        }
    }

    const handleCloseMaxProduktAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenMaxProduktAlert(false);
    };

    return (
        <Produktwahl openMaxProduktAlert={openMaxProduktAlert}
                     t={t}
                     selectedTerminArtID={selectedTerminArtID}
                     maxProdukte={selectedTerminArt?.MaxProduktAnzahl}
                     produkte={produkte}
                     produktList={produktList}
                     produktImageBasePath={studioimageBasePath}
                     currency={currency}
                     loadingTermineFinished={loadingTermineFinished}
                     isTabletOrGreater={isTabletOrGreater}
                     handleProduktSelection={handleProduktSelection}
                     handleCloseMaxProduktAlert={handleCloseMaxProduktAlert}
                     showNoPruductAvailable={showNoPruductAvailable}
        />
    )
}

export default ProduktwahlContainer
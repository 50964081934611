export const francais = {
    "Produktkategorie": "catégorie de produit",
    "Produkt": "Produit",
    "PRODUKTI": "Produit I",
    "PRODUKTII": "Produit II",
    "Termin": "Rendez-vous",
    "Egal": "Peu importe",
    "TERMIN": "Rendez-vous",
    "BESTAETIGUNG": "Vérifier",
    "Rubrik": "Catégorie",
    "RUBRIK": "Catégorie",
    "Terminart": "Type de Rendez-vous",
    "RESERVATIONHINT": "Nous vous réserverons le créneau pendant 30 minutes depuis la sélection. Veuillez terminer la réservation d'ici là.",
    "PREISNACHVEREINBARUNG": "n.V.: Pour ces produits, le prix sera fixé après accord.",
    "ANZAHLUNG": "Verser: {{ anzahlung }}.",
    "KOSTENPFLICHTIG_BUCHEN": "réserver avec frais",
    "VERBINDLICH_BUCHEN": "réservation contraignante",
    "ANFRAGE_SENDEN": "envoyer demande",
    "(Preis nach Vereinbarung)": "(prix à convenir)",
    "Bezahlung": "Paiement",
    "BEZAHLUNG_ABGESCHLOSSEN": "Paiement réussi.",
    "BEZAHLE_SPAETER": "je paierai plus tard",
    "ZAHLUNGSPROZESS_LAEUFT": "Traitement du paiement",
    "BUCHUNGSPROZESS_LAEUFT": "Traitement de la réservation",
    "VERBINDUNGSFEHLER": "Erreur de connexion",
    "VERBINDUNGSAUFBAU_NICHT_MOEGLICH": "Désolé, il ne peut pas établir de connexion au serveur. Merci d'essayer plus tard...",
    "VERBINDUNG_PRUEFEN": "Vérifier la connexion",
    "BUCHUNG_ERFOLGREICH": "La réservation a réussi. Un courriel a été envoyé à {{email}}.",
    "GUTSCHEIN_VERSANDT": "Un e-mail avec le bon a été envoyé à {{email}}.",
    "WEITERE_BUCHUNG": "Nouvelle réservation",
    "WEITERER_GUTSCHEIN": "Nouveau bon",
    "CHECK_ABO_FAILED": "Il semble y avoir un problème de configuration. Veuillez contacter le studio. (Code={{error}})",
    "NO_STUDIO_GIVEN": "Il semble y avoir un problème de configuration. Veuillez contacter le studio. (Code={{error}})",
    "CONFIGURATION_ERROR": "Erreur",
    "ZAHLUNG_FEHLGESCHLAGEN": "Le paiement a été annulé ou a échoué. Aucun paiement n'a été traité. Essayez à nouveau.",
    "PAYMENT_SERVICE_MISSING": "Il semble y avoir un problème de configuration. Veuillez contacter le studio. (Code={{error}})",
    "VOUCHER_ISSUER_ARTIST_MISSING": "Il semble y avoir un problème de configuration. Veuillez contacter le studio. (Code={{error}})",
    "MAIL_CONFIGURATION": "Il semble y avoir un problème de configuration. Please contact the studio. (Code={{error}})",
    "Betrag": "Montant",
    "VOUCHER_MESSAGE_LABEL": "Votre message",
    "KAUFE_GUTSCHEIN": "Acheter un bon",
    "GUTSCHEIN": "Bon de  {{von}} à {{fuer}}",
    "GUTSCHEIN_DOWNLOAD": "Bon - Télécharger",
    "VOUCHER_TEMPLATE": "Modèle de voucher",
    "VOUCHER_EMAIL": "Email",
    "VOUCHER_NACHRICHT": "Message",
    "CONFIRMATION.HEADER": "Vérifier et confirmer",
    "KUNDE": "Cliente",
    "PRODUKT": "Produit",
    "TERMINART": "Catégorie",
    "MESSAGE": "Message",
    "WEITERE_INFOS": "Envoyez-nous un message ou une image modèle !",
    "KUNDE_MINDESTALTER_HINT": "Vous devez avoir au moins 14 ans",
    "EMAIL_CONFIRMATION_INITIATED": "Nous avons envoyé un code de confirmation à l'adresse e-mail indiquée. Veuillez le remplir ici pour confirmer.",
    "EMAIL_CONFIRMATION_PROBLEM": "Il semble y avoir un problème lors de la confirmation de l'adresse e-mail. Merci de contacter le studio!",
    "EMAIL_CONFIRMATION_SUCCESS": "L'adresse e-mail a été confirmée. Vous pouvez fermer la boîte de dialogue !",
    "EMAIL_CONFIRMATION_FAILED": "Le secret n'est pas correct !",
    "EMAIL_CONFIRMATION_TITLE": "Confirmation de l'émail",
    "EMAIL_CONFIRMATION_CODE": "Code de confirmation",
    "CONFIRMATION_USER_MESSAGE": "Votre message pour nous",
    "KUNDE_VORNAME_LABEL": "Nom",
    "KUNDE_NACHNAME_LABEL": "Nom de famille",
    "KUNDE_EMAIL_LABEL": "Email",
    "KUNDE_BIRTHDAY_LABEL": "Date d'anniversaire",
    "KUNDE_MOBILE_LABEL": "Mobile",
    "KUNDE_DESEASES_LABEL": "Maladies/Allergies",
    "TERMINWAHL_TERMIN_BLOCKED": "Malheureusement cette date a été prise. BVeuillez en choisir un autre !",
    "CHECKOUT_TYPE": "Type",
    "CHECKOUT_TERMINKAUTION": "Verser",
    "CUSTOMER_MESSAGE_HEADING": "Votre message pour nous",
    "BOOKING_ERROR": "Erreur de réservation",
    "BOOKING_ERROR_MESSAGE": "La réservation a probablement été effectuée, mais il y a eu un problème lors de l'envoi du mail de confirmation. Veuillez contacter l'atelier.",
    "BOOKING_ERROR_MESSAGE_WITH_CHECKOUTID": "La réservation a probablement été effectuée, mais il y a eu un problème lors de l'envoi du mail de confirmation. Veuillez contacter le studio et fournir le numéro de paiement {{paymentId}}.",
    "(Kostenfrei)": "gratuit",
    "NO_TIMESLOT_AVAILABLE": "Malheureusement, il n'y a pas de plage horaire disponible",
    "KUNDE_HEADING": "Vos données",
    "PRODUKTWAHL_CHOOSE_MAX_ONE_PRODUKT_ALERT": "Veuillez choisir un produit !",
    "PRODUKTWAHL_CHOOSE_MAX_PRODUKT_ALERT": "Veuillez choisir vos produits - {{maxProdukte}} maximum!",
    "PRODUKTWAHL_CHOOSE_PRODUKT_ALERT": "Veuillez choisir vos produits!",
    "TERMINWAHL_CHOOSE_LOCATION_ALERT": "Veuillez d'abord choisir un emplacement!",
    "TERMINWAHL_HEADING": "Sélection des dates",
    "FILEINPUT.BUTTON_TEXT_ADD_PIC":"Joindre une Photo",
    "KUNDE.EMAIL_CHECK_HELPER_TEXT": "L'email doit être vérifié!",
    "KUNDE.EMAIL_CHECK_BUTTON_TEXT": "Vérifier",
    "KUNDE.CONTACT_LABEL_TEXT": "Type de contact préféré",
    "BUTTON_TEXT_CLOSE": "fermer",
    "BUTTON_TEXT_CHECK": "vérifié",
    "BUTTON_TEXT_RESEND": "renvoyer",
    "CONFIRMATION.PRIVACY_AGREEMENT_READ": "J'ai lu l'accord de protection des données.",
    "VOUCHER_FUER": "pour",
    "VOUCHER_VON": "de",
    "LEGAL_FOOTER.LINK_TEXT_DATENSCHUTZ": "Protection des données",
    "LEGAL_FOOTER.LINK_TEXT_IMPRESSUM": " mentions légales"
}

import React, {useCallback, useEffect} from "react";
import {Box, Button, CircularProgress, CssBaseline, Grid, MobileStepper, Paper, useTheme} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight, NavigateBefore, NavigateNext} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import TerminwahlContainer from "./terminwahl/TerminwahlContainer";
import {useTranslation} from "react-i18next";
import KundeContainer from "./kunde/KundeContainer";
import {
    selectActiveStep,
    selectIsSelectedTerminArtOhneTermin,
    selectSelectedRubrikID,
    selectSelectedTerminArtID,
    selectSelectedTerminArtKaution,
    selectStateLoaded
} from "../../store/appointment/appointmentSelectors";
import {changeActiveStep, changeRubrik, changeTerminart} from "../../store/appointment/appointmentActions";
import ConfirmationContainer from "./confirmation/ConfirmationContainer";
import {
    selectHeaderUrl,
    selectIsStudioLoaded,
    selectLanguage,
    selectRubrikenList,
    selectStudioHasPaymentProvider,
    selectTerminArtMap
} from "../../store/common/commonSelectors";
import {selectCheckoutId, selectIsPaymentProcessing, selectWillPayLater} from "../../store/checkout/checkoutSelector";
import ProduktwahlContainer from "./produktwahl/ProduktwahlContainer";
import TerminartWahlContainer from "./terminartwahl/TerminartWahlContainer";
import {useHistory, useLocation} from "react-router-dom";
import {createTerminkautionspayment, processTerminbookingAndPayLaterAction} from "../../store/checkout/checkoutActions";
import CustomerCommentContainer from "./customer-comment/CustomerCommentContainer";
import qs from "qs";
import RubrikWahlContainer from "./rubrikwahl/RubrikWahlContainer";
import {BOOKING_BESTAETIGUNG, PRODUKT, STEP_LIST} from "./BookingFlowStepperModel";
import LegalFooter from "../common/navigation/LegalFooter";
import {selectCanSelectNext} from "../../store/appointment/nextEnabledSelectors";
import LangSelector from "../language/langSelector";
import {setLanguage} from "../../store/common/commonActions";

const STEPPER_SELECTOR = ['', 'RUBRIK', 'TERMINART', 'PRODUKT', 'MESSAGE', 'TERMIN', 'KUNDE', 'BESTAETIGUNG', '']

const BookingFlow = () => {
    const activeStep = useSelector(state => selectActiveStep(state))
    const isStudioLoaded = useSelector(state => selectIsStudioLoaded(state))
    const activeLanguage = useSelector(state => selectLanguage(state))
    const isStateLoaded = useSelector(state => selectStateLoaded(state))
    const canSelectNext = useSelector(state => selectCanSelectNext(state))
    const headerUrl = useSelector(state => selectHeaderUrl(state))
    const bookingWithPayLaterCompleted = useSelector(state => selectWillPayLater(state))
    const isPaymentProcessing = useSelector(state => selectIsPaymentProcessing(state))
    const terminkaution = useSelector(state => selectSelectedTerminArtKaution(state))
    const hasPaymentProvider = useSelector(state => selectStudioHasPaymentProvider(state))
    const checkoutId = useSelector(state => selectCheckoutId(state))
    const terminartMap = useSelector(state => selectTerminArtMap(state))
    const selectedTerminArtID = useSelector(state => selectSelectedTerminArtID(state))
    const isOhneTermin = useSelector(state => selectIsSelectedTerminArtOhneTermin(state))
    const selectedRubrikID = useSelector(state => selectSelectedRubrikID(state))
    const rubriken = useSelector(state => selectRubrikenList(state))
    const location = useLocation()
    const query = qs.parse(location.search, {ignoreQueryPrefix: true})
    const history = useHistory()

    const dispatch = useDispatch()
    const {t, i18n} = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (!isStateLoaded) {
            return true
        }
        if (rubriken?.length && !!query?.terminart
            && !!terminartMap && selectedTerminArtID !== query?.terminart) {
            const ta = terminartMap[query.terminart]
            if (ta) {
                dispatch(changeRubrik(ta.RubrikID))
                dispatch(changeTerminart(ta.TerminArtID));
                dispatch(changeActiveStep(PRODUKT))
                history.push('/')
            }
        }
    }, [selectedRubrikID, rubriken, selectedTerminArtID, terminartMap, query, isStateLoaded, dispatch, history])

    const confirmBooking = () => {
        if (!hasPaymentProvider || !terminkaution) {
            dispatch(processTerminbookingAndPayLaterAction())
            return
        }
        if (!checkoutId) {
            dispatch(createTerminkautionspayment())
        } else {
            setTimeout(() => history.push('/checkout'), 500)
        }
    }

    const handleNext = () => {
        if (activeStep === BOOKING_BESTAETIGUNG) {
            confirmBooking()
        } else {
            dispatch(changeActiveStep(STEP_LIST[getStepIndex() + 1]))
        }

    };

    const handleBack = () => {
        dispatch(changeActiveStep(STEP_LIST[getStepIndex() - 1]))
    };

    const getStepIndex = () => STEP_LIST.indexOf(activeStep)

    const isLast = (getStepIndex() === STEP_LIST.length - 1)

    const getNext = (step) => {
        const nextStep = STEPPER_SELECTOR[step + 2]
        return nextStep.length ? t(nextStep) : nextStep
    }

    const getBack = (step) => {
        const backStep = STEPPER_SELECTOR[step]
        return backStep.length ? t(backStep) : backStep
    }

    const renderStep = () => {
        if (!isStateLoaded) {
            return null;
        }
        switch (getStepIndex()) {
            case 0:
                return <RubrikWahlContainer/>
            case 1:
                return <TerminartWahlContainer/>
            case 2:
                return <ProduktwahlContainer/>
            case 3:
                return <CustomerCommentContainer/>
            case 4:
                return <TerminwahlContainer/>
            case 5:
                return <KundeContainer/>
            case 6:
                return <ConfirmationContainer/>
            default:
                return <TerminartWahlContainer/>
        }
    }

    const getNextButtonString4Confirmation = () => isOhneTermin ? t('ANFRAGE_SENDEN') : (terminkaution > 0 ? t('KOSTENPFLICHTIG_BUCHEN') : t('VERBINDLICH_BUCHEN'))


    const renderBackNextButton = () => {
        return (
            <Box position="fixed" bottom="10px" width="100%">
                <Grid container style={{marginTop: '40px'}}>
                    <Grid item xs={12} sm={8} md={6} container justifyContent="space-between">
                        {getStepIndex() === 0 ? null :
                            <Grid item> <Button variant="contained" color="primary" disabled={isPaymentProcessing}
                                                onClick={handleBack}
                                                style={{marginBottom: '20px', marginTop: '10px'}}>
                                <NavigateBefore/></Button></Grid>}
                        {<Grid item><Button variant="contained" color="primary"
                                            disabled={!canSelectNext || isPaymentProcessing}
                                            onClick={handleNext}
                                            style={{marginBottom: '20px', marginTop: '10px'}}>
                            {isLast ? getNextButtonString4Confirmation() :
                                <NavigateNext/>}</Button></Grid>}
                    </Grid>
                </Grid>
            </Box>

        );
    }

    const renderSpinner = () => {
        return (
            <Grid container justifyContent="center">
                <Grid item><CircularProgress size={200}/></Grid>
            </Grid>
        );
    }

    const handleChangeLanguage = useCallback((isoLang) => {
        dispatch(setLanguage(isoLang))
        i18n.changeLanguage(isoLang)
    }, [dispatch, i18n])

    return (
        <React.Fragment>
            <CssBaseline>
                <Grid container justifyContent="center" style={{minHeight: '100vh'}}>
                    <Grid item xs={12} sm={8} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                        <Box position="relative">
                            {isStudioLoaded ? <img src={headerUrl} width="100%" alt="StudioHeader"/> : null}
                            <Box position='absolute' top='0' right='7px'>
                                <LangSelector isoLang={activeLanguage || 'de'} onChange={handleChangeLanguage}/>
                            </Box>
                            {isStateLoaded && !bookingWithPayLaterCompleted ? <MobileStepper
                                steps={STEP_LIST.length}
                                position="static"
                                variant="text"
                                activeStep={getStepIndex()}
                                nextButton={
                                    <Button size="small" onClick={handleNext}
                                            disabled={!canSelectNext || isPaymentProcessing} color="primary">
                                        {t(getNext(getStepIndex()))}
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack}
                                            disabled={getStepIndex() === 0 || isPaymentProcessing}
                                            color="primary">
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                                        {getBack(getStepIndex())}
                                    </Button>
                                }
                            /> : !isStateLoaded ? renderSpinner() : null}

                        </Box>
                        <Paper style={{padding: '0 7px', height: '100%'}}>
                            {renderStep()}
                        </Paper>
                        {!isStateLoaded || bookingWithPayLaterCompleted ? null : renderBackNextButton()}
                        <LegalFooter t={t}/>
                    </Grid>
                </Grid>
            </CssBaseline>
        </React.Fragment>
    );
}

export default BookingFlow;

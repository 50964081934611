import React from "react";
import {Alert, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import {Edit, Info} from "@mui/icons-material";
import Heading from "../../common/style/Heading";
import BackdropSpinner from "../../common/backdrop/BackdropSpinner";
import NoTimeslotAvailableAlert from "../../error/NoTimeslotAvailableAlert";


const Terminwahl = ({
                        tatort,
                        artist,
                        timeslot,
                        period,
                        blockerAlert,
                        selectedPeriodAsText,
                        showBackdrop,
                        t,
                        isTerminArtOhneTermin
                    }) => {

    const noTimeslotAvailable = tatort?.selectedTatOrtID && !artist.selectionList?.length
    const selectTatortNeeded = !tatort?.selectedTatOrtID && tatort.selectionList?.length > 1
    const SelectionItem = (id, bezeichnung) => {
        return (
            <MenuItem key={id} value={id}>{bezeichnung}</MenuItem>
        )
    }

    const renderReservationHint = (shouldRender) => {
        if (!shouldRender) {
            return null;
        }
        return (
            <Alert icon={<Info fontSize="inherit"/>} severity="info">
                {period.hint}
            </Alert>
        )
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        blockerAlert.onClose();
    };


    const renderChooseLocationAlert = () => {
        if (!selectTatortNeeded) {
            return null
        }
        return (
            <Alert severity="warning" style={{marginBottom: '20px'}}>
                {t('TERMINWAHL_CHOOSE_LOCATION_ALERT')}
            </Alert>
        )
    }

    const renderPeriodAsText = () => {
        return (
            <React.Fragment>
                <Grid container alignItems="center" style={{marginTop: '10px'}}>
                    <Grid item>
                        <Typography variant="body">{selectedPeriodAsText}</Typography>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => period.onChange()}><Edit/></Button>
                    </Grid>
                </Grid>
                {renderReservationHint(!!period.selectedPeriodId)}
            </React.Fragment>

        )
    }

    const renderPeriodSelection = () => {
        if (isTerminArtOhneTermin) {
            return null
        }
        return (
            <React.Fragment>
                <FormControl variant="standard" fullWidth style={{marginTop: '10px', paddingBottom: '20px'}}>
                    <InputLabel id="timeslot-label">Timeslot</InputLabel>
                    <Select
                        labelId="timeslot-label"
                        id="timeslot-Selection"
                        value={timeslot.selectedTimeslot || ''}
                        disabled={!artist.selectedArtistID || artist.selectedArtistID === ''}
                        onChange={timeslot.onChange}
                    >
                        {timeslot.selectionList.map(t => SelectionItem(t.id, t.text))}
                    </Select>
                </FormControl>
                <FormControl variant="standard" fullWidth style={{marginTop: '10px', paddingBottom: '20px'}}>
                    <InputLabel id="period-label">{t('Termin')}</InputLabel>
                    <Select
                        labelId="period-label"
                        id="period-Selection"
                        disabled={!timeslot.selectedTimeslot || timeslot.selectedTimeslot === ''}
                        value={period.selectedPeriodId || ''}
                        onChange={period.onChange}
                    >
                        {period.selectionList.map(t => SelectionItem(t.id, t.text))}
                    </Select>
                </FormControl>
            </React.Fragment>
        )
    }

    const renderLocation = () => {
        return (
            <React.Fragment>
                {renderChooseLocationAlert()}
                <FormControl variant="standard" fullWidth style={{paddingBottom: '20px'}}>
                    <InputLabel id="tatort-label">Location</InputLabel>
                    <Select
                        labelId="tatort-label"
                        id="tatort-Selection"
                        value={tatort.selectedTatOrtID || ''}
                        onChange={tatort.onChange}
                    >
                        {tatort.selectionList.map(t => SelectionItem(t.id, t.text))}
                    </Select>
                </FormControl>
            </React.Fragment>
        )
    }

    const renderArtistSelection = () => {
        return (
            <FormControl variant="standard" fullWidth style={{marginTop: '10px', paddingBottom: '20px'}}>
                <InputLabel id="artist-label">Artist</InputLabel>
                <Select
                    labelId="artist-label"
                    id="artist-Selection"
                    value={artist.selectedArtistID || ''}
                    onChange={artist.onChange}
                >
                    {artist.selectionList.map(t => SelectionItem(t.id, t.text))}
                </Select>
            </FormControl>
        )
    }

    const heading = isTerminArtOhneTermin ? t('TERMINANFRAGE_HEADING') : t('TERMINWAHL_HEADING')
    return (
        <React.Fragment>
            <Heading>{heading}</Heading>
            {tatort.selectionList.length ? renderLocation() : null}
            {artist.selectionList.length ? renderArtistSelection() : null}
            {noTimeslotAvailable ? <NoTimeslotAvailableAlert t={t}/> : null}

            {!selectedPeriodAsText ? renderPeriodSelection() : renderPeriodAsText()}
            <BackdropSpinner open={showBackdrop}/>

            <Snackbar open={blockerAlert.blockerBookingConflict} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {t('TERMINWAHL_TERMIN_BLOCKED')}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};

export default Terminwahl;

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {deutsch} from "./public/locales/deutsch";
import {english} from "./public/locales/english";
import {italiano} from "./public/locales/italiano";
import {francais} from "./public/locales/francais";

const resources = {
    en: {
        translation: english
    },
    de: {
        translation: deutsch
    },
    it: {
        translation: italiano
    },
    fr: {
        translation: francais
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'de',
        debug: (process.env.REACT_APP_ENV === 'development'),
        fallbackLng: 'en',
        interpolation: {escapeValue: false},
        keySeparator: false
    });

export default i18n;

import axios from "axios";
import uuid from "react-uuid";
import {formatDate} from "@kiss-solutions/dateutils/lib/formatting";
import {plus} from "@kiss-solutions/dateutils";

const BACKEND = process.env.REACT_APP_BASEURL;

export const getStudio = (db) => {
    return axios.get(`${BACKEND}/studio/ext/${db}`)
}

export const checkAbo = async (db) => {
    const response = await axios.get(`${BACKEND}/studio/ext/${db}/checkAbo`)
    return response.status
}

export const checkConnectionBackend = () => {
    return axios.get(`${BACKEND}/studio/check`)
}

export const getProdukte = (db) => {
    return axios.get(`${BACKEND}/studio/${db}/produkte`)
}

export const getArtists = (db) => {
    return axios.get(`${BACKEND}/artists/ext/${db}`)
}

export const getRubriken = (db) => {
    return axios.get(`${BACKEND}/studio/${db}/rubriken`)
}

export const getRTerminArten = (db) => {
    return axios.get(`${BACKEND}/studio/${db}/terminarten`)
}

export const getVoucherStatus = (db) => {
    return axios.get(`${BACKEND}/studio/ext/${db}/voucher/status`)
}

export const getStudioBase = (db) => {
    return axios.get(`${BACKEND}/studio/${db}/host`)
}

export const bookBlocker = async (db, ArtistID, period) => {
    const BlockerID = uuid();
    const blocker = {
        BlockerID
        , Betreff: 'Blocker expires at ' + formatDate(plus(new Date(), 30, 'minute'), 'de', 'Time')
        , ArtistID
        , Start: period.Start
        , DatBis: period.DatBis
        , _createdTS: new Date().toISOString()
    }
    const result = await axios.post(`${BACKEND}/studio/ext/${db}/onlinetermin/blocker`, blocker);
    if (result.data.booked) {
        return BlockerID;
    }
    return undefined;
}

export const deleteBlocker = async (db, blockerId) => {
    if (blockerId) {
        await axios.delete(`${BACKEND}/studio/ext/${db}/onlinetermin/blocker/${blockerId}`)
    }
}

export const saveTempImage = async (db, image) => {
    const data = {filename: image.name, dataUrl: image.dataURL}
    const response = await axios.post(`${BACKEND}/studio/ext/${db}/image/temp`, data)
    return response.data.imageKey
}

export const checkEmail = async (secret, hash) => {
    const query = `${BACKEND}/studio/checkhashforvalue/${encodeURI(secret)}/${encodeURIComponent(hash)}`;
    const response = await axios.get(query);
    return response.data.status;
}

export const saveCheckout = async (db, data) => {
    try {
        const response = await axios.post(`${BACKEND}/studio/ext/${db}/onlinetermin/checkout`, data)
        return response.data.insertedId
    } catch (e) {
        if (e.response.status === 409) {
            return data.id
        } else {
            throw e
        }
    }
}

export const deleteCheckout = async (db, checkoutId) => {
    await axios.delete(`${BACKEND}/studio/ext/${db}/onlinetermin/checkout/${checkoutId}`)
}

export const processPayLater = async (db, checkout) => {
    console.log('posting processPayLater')
    await axios.post(`${BACKEND}/studio/ext/${db}/onlinetermin`, checkout)
}

export const createSofortSource = async (db, checkoutId) => {
    console.log('get createSofortSource')
    const response = await axios.get(`${BACKEND}/studio/ext/${db}/stripe/source/${checkoutId}`)
    return response.data
}

export const retryStripePayment = async (db, checkoutId) => {
    console.log('post retryStripePayment')
    await axios.post(`${BACKEND}/studio/ext/${db}/stripe/retry/${checkoutId}`, {})
}

export const getCheckoutById = (db, checkoutId, withContent) => {
    return axios.get(`${BACKEND}/studio/ext/${db}/checkout/${checkoutId}`, {params: {withContent}})
        .then(response => response.data)
}

export const getCheckoutStatus = async (db, checkoutId) => {
    console.log('get getCheckoutStatus')
    const data = await getCheckoutById(db, checkoutId)
    return data.status
}

export const getVoucherBlob = async (db, checkoutId, pdfvorlagenid) => {
    console.log('get getVoucherBlob')
    const response = await axios.get(`${BACKEND}/studio/ext/${db}/checkout/${checkoutId}/voucher/${pdfvorlagenid}`,
        {responseType: 'blob'})
    return response.data
}

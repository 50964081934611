import React from "react";
import {makeStyles} from "@mui/styles";
import {Checkbox, Fade, Grid, Typography} from "@mui/material";
import ExpandableText from "../../common/style/ExpandableText";


const useStyles = makeStyles({
    hover: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

const RubrikElement = ({rubrik, checked, handleRubrikSelection, imageBasePath, isTabletOrGreater, theme}) => {
    const classes = useStyles()

    const renderImage = () => {
        if (!rubrik.Bild) {
            return null;
        }
        const key = encodeURIComponent(rubrik.Bild)
        const minHeight = '250px'
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: minHeight}}>
                <Fade in={true} timeout={2000}>
                    <img style={{maxHeight: minHeight, width: '100%', objectFit: 'cover'}}
                         alt="RubrikBild"
                         className={classes.hover}
                         onClick={() => handleRubrikSelection(rubrik.RubrikID)}
                         src={`${imageBasePath}?key=${key}`}/>
                </Fade>
            </div>

        );
    }

    const renderBeschreibung = () => {
        return (
            <React.Fragment>
                <ExpandableText theme={theme} beschreibung={rubrik.Beschreibung}/>
            </React.Fragment>
        )
    }


    return (
        <Grid item xs={12} style={{marginTop: '20px'}}>
            <Grid container direction="row" justifyContent="flext-start" alignItems="center">
                <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={() => handleRubrikSelection(rubrik.RubrikID)}
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
                <Typography onClick={() => handleRubrikSelection(rubrik.RubrikID)}
                            className={classes.hover}
                            variant={isTabletOrGreater ? 'h5' : 'h6'}>{rubrik.Bezeichnung}</Typography>
            </Grid>
            {renderImage()}
            {rubrik.Beschreibung?.length ? renderBeschreibung() : null}
        </Grid>
    );
}

export default RubrikElement
import uuid from "react-uuid";
import {formatDateLocalIso} from "@kiss-solutions/dateutils/lib/formatting";

export const convertTerminart2OTSummary = ta => ta && ({
    TerminArtID: ta.TerminArtID
    , Bezeichnung: ta.Bezeichnung
    , Beschreibung: ta.Beschreibung
    , RubrikID: ta.RubrikID
    , MailCC: ta.MailCC
    , MatchExistingProjekt: ta.MatchExistingProjekt
    , TerminTyp: ta.TerminTyp
    , OhneTermin: ta.OhneTermin
    , TerminVormerken: ta.TerminVormerken
})

export const convertKunde2OTSummary = kunde => ({
    Vorname: kunde.vorname
    , Nachname: kunde.nachname
    , Email: kunde.email
    , Mobil: kunde.mobile
    , Birthday: kunde.birthday && formatDateLocalIso(new Date(kunde.birthday)) || undefined
    , Deseases: kunde.deseases
    , MsgPref: kunde.kontaktart
})

export const convertPeriodString2IsoDate = (period) => ({
    Start: period.Start.slice(0, 19).replace('T', ' ')
    , DatBis: period.DatBis.slice(0, 19).replace('T', ' ')
})

export const createOpenTerminCheckoutRecord = (checkoutId, checkout, lang) => ({
    id: uuid()
    , type: 'termin'
    , paymentid: null
    , status: 'open'
    , content: checkout
    , lang
})
import {createSimpleAction} from "../utils";

export const SET_UI_STATE = 'SET_UI_STATE';
export const LOAD_UI_STATE_ACTION = 'LOAD_UI_STATE_ACTION';
export const CHANGE_RUBRIK = 'CHANGE_RUBRIK';
export const CHANGE_TERMINART = 'CHANGE_TERMINART';
export const CHANGE_TATORT = 'CHANGE_TATORT';
export const CHANGE_ARTIST = 'CHANGE_ARTIST';
export const CHANGE_PERIOD = 'CHANGE_PERIOD';
export const SELECT_PRODUKT = 'SELECT_PRODUKT';
export const DESELECT_PRODUKT = 'DESELECT_PRODUKT';
export const LOAD_ARTIST_TERMINE_SUCCESS = 'LOAD_ARTIST_TERMINE_SUCCESS';
export const LOAD_ARTIST_TERMINE_ONGOING = 'LOAD_ARTIST_TERMINE_ONGOING';
export const CHANGE_BLOCKERID = 'CHANGE_BLOCKERID';
export const BLOCKERID_CLEARED = 'BLOCKERID_CLEARED';
export const BLOCKER_BOOKING_FAILED = 'BLOCKER_BOOKING_FAILED';
export const CLEAR_BLOCKER_BOOKING_FAILED = 'CLEAR_BLOCKER_BOOKING_FAILED';
export const CHANGE_ACTIVE_STEP = 'CHANGE_ACTIVE_STEP';
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_S3KEY_TO_IMAGE = 'ADD_S3KEY_TO_IMAGE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const UPLOADING_IMAGE = 'UPLOADING_IMAGE';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const CHANGE_DAATENSCHUTZ_READ = 'CHANGE_DAATENSCHUTZ_READ'
export const CHANGE_CUSTOMER_MESSAGE = 'CHANGE_CUSTOMER_MESSAGE'
export const NEW_BOOKING = 'NEW_BOOKING'

export const setPersistedUIState = createSimpleAction(SET_UI_STATE, 'uiState');
export const changeRubrik = createSimpleAction(CHANGE_RUBRIK, 'selectedRubrikID');
export const changeTerminart = createSimpleAction(CHANGE_TERMINART, 'selectedTerminArtID');
export const changeTatort = createSimpleAction(CHANGE_TATORT, 'selectedTatOrtID');
export const changeArtist = createSimpleAction(CHANGE_ARTIST, 'selectedArtistID');
export const changePeriod = createSimpleAction(CHANGE_PERIOD, 'selectedPeriod');
export const changeBlockerId = createSimpleAction(CHANGE_BLOCKERID, 'blockerId');
export const blockerBookingFailed = () => ({type: BLOCKER_BOOKING_FAILED});
export const clearBlockerBookingFailed = () => ({type: CLEAR_BLOCKER_BOOKING_FAILED})
export const clearBlockerId = () => ({type: BLOCKERID_CLEARED});

export const loadUiStateAction = () => ({type: LOAD_UI_STATE_ACTION});

export const selectProdukt = createSimpleAction(SELECT_PRODUKT, 'produktId');
export const deselectProdukt = createSimpleAction(DESELECT_PRODUKT, 'produktId');

export const loadArtistTermineOngoing = () => ({type: LOAD_ARTIST_TERMINE_ONGOING});
export const loadArtistTermineSuccess = createSimpleAction(LOAD_ARTIST_TERMINE_SUCCESS, 'artisttermine');
export const changeActiveStep = createSimpleAction(CHANGE_ACTIVE_STEP, 'activeStep');
export const addTempImage = createSimpleAction(ADD_IMAGE, 'image');
export const removeTempImage = createSimpleAction(REMOVE_IMAGE, 'imageId');
export const addS3KeyToImage = createSimpleAction(ADD_S3KEY_TO_IMAGE, 'id2s3key');
export const uploadingImage = () => ({type: UPLOADING_IMAGE});
export const imageUploaded = () => ({type: IMAGE_UPLOADED});
export const newBooking = () => ({type: NEW_BOOKING});
export const changeDatenschutzRead = createSimpleAction(CHANGE_DAATENSCHUTZ_READ, 'datenschutzRead');
export const changeCustomerMessage = createSimpleAction(CHANGE_CUSTOMER_MESSAGE, 'customerMessage');




import {
    createBooleanFieldSelector,
    createFieldSelector,
    createIsArrayNotEmpty,
    createIsFieldDefinedSelector,
    identityFunction,
    mapAndIdToObject
} from "../utils";
import {
    selectLanguage,
    selectProduktList,
    selectProduktMap,
    selectRubrikenMap,
    selectStudio,
    selectStudioTatorte,
    selectStudioTimezone,
    selectTerminArtMap
} from "../common/commonSelectors";
import {endOfDay, startOfDay} from "@kiss-solutions/dateutils/lib/common";
import {plus} from "@kiss-solutions/dateutils";
import {convertArtistTermin2StringSelectionWithLocale} from "./terminslotConverter";
import {selectKundeState} from "../kunde/kundeSelectors";
import {createSelector} from "reselect";

export const selectAppointmentState = createSelector(
    (state) => state?.appointment
    , identityFunction
);

export const selectSelectedRubrikID = createFieldSelector(selectAppointmentState, 'selectedRubrikID');
export const selectSelectedTerminArtID = createFieldSelector(selectAppointmentState, 'selectedTerminArtID');
export const selectStateLoaded = createFieldSelector(selectAppointmentState, 'stateLoaded');
export const selectBlockerId = createFieldSelector(selectAppointmentState, 'blockerId');
export const selectLoadingTermineFinished = createFieldSelector(selectAppointmentState, 'loadingTermineFinished');

export const selectSelectedTerminArt = createSelector(
    selectTerminArtMap
    , selectSelectedTerminArtID
    , (map, id) => {
        const ta = mapAndIdToObject(map, id);
        return ta ? ({
            ...ta
            , MinStart: getMinStart(ta)
            , MaxStart: getMaxStart(ta)
        }) : undefined
    }
);

export const selectZeitTakt = createSelector(
    selectSelectedTerminArt
    , terminArt => terminArt?.ZeitTakt || 15
)

export const selectTerminartKundenHinweisText = createSelector(
    selectSelectedTerminArt
    , ta => ta?.HinweisKundenNachricht
);
export const selectTerminartSetupDauer = createSelector(
    selectSelectedTerminArt
    , ta => ta?.SetupDauer || 0
);

export const selectSelectedRubrik = createSelector(
    selectRubrikenMap
    , selectSelectedRubrikID
    , mapAndIdToObject
);

export const selectTerminArtListForSelectedRubrikID = createSelector(
    selectTerminArtMap
    , selectSelectedRubrikID
    , (ta, rubrikId) => !!ta && !!rubrikId ? Object.values(ta).filter(t => t.RubrikID === rubrikId) : []
);

export const selectSelectedProduktIDList = createSelector(
    selectAppointmentState
    , state => state.produktList || []
);

export const selectIsProduktSelected = createIsArrayNotEmpty(selectAppointmentState, 'produktList');

export const isMaximumProduktListReached = createSelector(
    selectSelectedProduktIDList
    , selectSelectedTerminArt
    , (produkte, ta) => ta !== undefined && produkte.length >= (ta.MaxProduktAnzahl || 100)
);

export const selectSelectedProduktList = createSelector(
    selectProduktMap
    , selectSelectedProduktIDList
    , (produktMap, produktIds) => produktMap && produktIds.map(id => produktMap[id]) || []
)

export const selectProduktListOfSelectedTerminArtID = createSelector(
    selectProduktList
    , selectSelectedTerminArt
    , (pl, ta) => ta ? pl.filter(p => p.ProduktgruppeID === ta.ProduktgruppeID) : []
);

export const selectFreeArtistTermine = createSelector(
    selectAppointmentState
    , state => state.freeArtistTermine || []
)

export const selectFreeTerminLocations = createSelector(
    selectFreeArtistTermine
    , selectStudioTatorte
    , (freeArtistTermine, tatorte) => {
        const locSet = freeArtistTermine
            .map(t => t.location.TatOrtID)
            .reduce((pv, cv) => pv.add(cv), new Set());

        return !!tatorte && tatorte
            .filter(t => locSet.has(t.TatOrtID))
            .map(t => ({id: t.TatOrtID, text: t.Ort}));
    }
)

export const selectSelectedTatOrtID = createFieldSelector(selectAppointmentState, 'selectedTatOrtID');
export const selectSelectedTatOrt = createSelector(
    selectSelectedTatOrtID
    , selectStudio
    , (tatortid, studio) => studio && studio.tatorte.find(t => t.TatOrtID === tatortid)
)
export const selectSelectedArtistID = createFieldSelector(selectAppointmentState, 'selectedArtistID');

export const selectFreeTerminArtists = createSelector(
    selectFreeArtistTermine
    , selectSelectedTatOrtID
    , (freeArtistTermine, tatortid) => {
        if (!freeArtistTermine.length) {
            return [];
        }
        const artistSelection = [];
        freeArtistTermine
            .filter(t => !tatortid || t.location.TatOrtID === tatortid)
            .forEach(t => {
                if (!artistSelection.find(u => u.id === t.artist.ArtistID)) {
                    artistSelection.push({id: t.artist.ArtistID, text: t.artist.Kuerzel});
                }
            });
        return artistSelection;
    }
);

export const selectFreeTermineForSelectedArtistAtLocation = createSelector(
    selectFreeArtistTermine
    , selectSelectedTatOrtID
    , selectSelectedArtistID
    , (freeTermine, tatortId, artistId) => {
        const terminFilter = !!artistId && artistId > 0 ? artist => artist.artist.ArtistID === artistId
                && artist.location.TatOrtID === tatortId
            : artist => artist.location.TatOrtID === tatortId
        return freeTermine.filter(terminFilter).map(t => ({...t.termin, id: t.id, ...t.artist}))
            .sort((a, b) => new Date(a.Start).getTime() - new Date(b.Start).getTime());
    }
);

export const selectFreeTimeslots = createSelector(
    selectFreeTermineForSelectedArtistAtLocation
    , selectLanguage
    , selectStudioTimezone
    , (freeTermine, language, timezone) =>
        freeTermine.map(t => convertArtistTermin2StringSelectionWithLocale(t, language, timezone))
)

export const selectSelectedPeriod = createFieldSelector(selectAppointmentState, 'selectedPeriod');
export const selectSelectedPeriodAsText = createSelector(
    selectSelectedPeriod
    , selectLanguage
    , selectStudioTimezone
    , (period, language, timezone) => convertArtistTermin2StringSelectionWithLocale(period, language, timezone)
)
export const selectIsPeriodSelected = createIsFieldDefinedSelector(selectAppointmentState, 'selectedPeriod');
export const selectBlockerBookingFailed = createFieldSelector(selectAppointmentState, 'blockerBookingFailed');
export const selectSelectedPeriodId = createFieldSelector(selectSelectedPeriod, 'id');
export const selectActiveStep = createFieldSelector(selectAppointmentState, 'activeStep');
export const selectImages = createFieldSelector(selectAppointmentState, 'images');
export const selectIsImageUploading = createFieldSelector(selectAppointmentState, 'uploadingImage');
export const selectIsDatenschutzRead = createBooleanFieldSelector(selectAppointmentState, 'datenschutzRead');
export const selectCustomerMessage = createFieldSelector(selectAppointmentState, 'customerMessage');


export const selectSelectedTerminArtArtist = createSelector(
    selectSelectedArtistID
    , selectSelectedTerminArt
    , (artistId, ta) => ta && ta.TerminArtArtists.find(t => t.ArtistID === artistId)
)

export const selectSelectedTerminArtKaution = createSelector(
    selectSelectedTerminArtArtist
    , artist => artist && artist.AnzAgreed
)

export const selectSelecteTerminartHinweisAnzahlung = createFieldSelector(selectSelectedTerminArt, 'HinweisAnzahlung')
export const selectIsSelectedTerminArtOhneTermin = createBooleanFieldSelector(selectSelectedTerminArt, 'OhneTermin')

const FORWARD_URL_REPLACEMENTS = [
    {toBeReplaced: '<Vorname>', fieldName: 'vorname'},
    {toBeReplaced: '<Nachname>', fieldName: 'nachname'},
    {toBeReplaced: '<Email>', fieldName: 'email'},
    {toBeReplaced: '<Mobile>', fieldName: 'mobile'},
    {toBeReplaced: '<Birthday>', fieldName: 'birthday'},
]

function createForwardUrlButtonConfig(kunde, terminArt) {
    if (!terminArt?.ForwardUrl || !terminArt?.ForwardButtonText) {
        return undefined
    }
    const url = FORWARD_URL_REPLACEMENTS.reduce((pv, cv) => {
        if (!kunde[cv.fieldName]) {
            return pv
        }
        return pv.replace(cv.toBeReplaced, window.encodeURIComponent(kunde[cv.fieldName]))
    }, terminArt.ForwardUrl)
    return {url, buttonText: terminArt.ForwardButtonText}
}


export const selectForwardUrl = createSelector(
    selectKundeState
    , selectSelectedTerminArt
    , (kunde, terminArt) => kunde && terminArt && createForwardUrlButtonConfig(kunde, terminArt)
)


function getMinStart(terminart) {
    const allMoments = [new Date()];
    if (terminart.MinTageVorlauf) {
        allMoments.push(startOfDay(plus(new Date(), terminart.MinTageVorlauf, 'date')));
    }
    if (terminart.AktionVon) {
        allMoments.push(new Date(terminart.AktionVon));
    }
    return allMoments.reduce((a, b) => a > b ? a : b);
}

function getMaxStart(terminart) {
    const allMoments = [];
    if (!!terminart.MaxTageVorlauf && terminart.MaxTageVorlauf > 0) {
        allMoments.push(endOfDay(plus(new Date(), terminart.MaxTageVorlauf, 'date')));
    }
    if (terminart.AktionBis) {
        allMoments.push(new Date(terminart.AktionBis));
    }
    if (terminart.OhneTermin) {
        allMoments.push(endOfDay(plus(new Date(), 30, 'date')));
    }
    // Maximal sollen 200 Tage / Anzahl Terminartists kalkuliert werden
    const maxDays = Math.floor(1000 / (terminart.TerminArtArtists?.length || 1));
    allMoments.push(plus(new Date(getMinStart(terminart).getTime()), maxDays, 'date'));
    return allMoments.reduce((a, b) => a < b ? a : b);
}


import React from "react";
import {Alert, Button, CircularProgress} from "@mui/material";
import Cable from '../../public/images/cable.jpg'
import {useTranslation} from "react-i18next";
import StandardContainer from "../common/style/StandardContainer";
import Heading from "../common/style/Heading";

const BackendError = ({tryConnection, checkVerbindungOngoing, handleReset}) => {
    const {t} = useTranslation()

    return (
        <StandardContainer>
            <Heading>{t('VERBINDUNGSFEHLER')}</Heading>
            <img src={Cable} width="100%"/>
            <Alert severity="error">{t('VERBINDUNGSAUFBAU_NICHT_MOEGLICH')}</Alert>
            <Button variant="contained" color="primary" onClick={handleReset} disabled={checkVerbindungOngoing}
                    style={{margin: "20px 0"}}>Reset</Button>
            <Button variant="contained" color="primary" onClick={() => tryConnection()}
                    disabled={checkVerbindungOngoing}
                    style={{margin: "20px 10px"}}>{checkVerbindungOngoing ?
                <CircularProgress color="inherit"
                                  size={14}/> : null}&nbsp;{t('VERBINDUNG_PRUEFEN')}
            </Button>
        </StandardContainer>

    );
}

export default BackendError
import React from "react";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";

const ProduktCard = ({titel, beschreibung, imageLocation, isTabletOrGreater}) => {
    const renderImage = () => {
        return (
            <CardMedia style={{maxHeight: 250}}
                       image={imageLocation}
                       title={titel}
                       component='img'
                       onError={e => e.target.className = 'missing'}
            />
        );
    }

    return (
        <Card>
            <CardContent>
                <Typography variant={isTabletOrGreater ? "h5" : 'h6'} component="h2">
                    {titel}
                </Typography>
                {imageLocation ? renderImage() : null}
                <Typography color="textSecondary">
                    {beschreibung}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ProduktCard;

import axios from "axios";

const BACKEND = process.env.REACT_APP_BASEURL;

export const createPaypalPayment = async (db, checkoutId) => {
    console.log('get createPaypalPayment')
    const response = await axios.get(`${BACKEND}/studio/ext/${db}/paypal/order/${checkoutId}/create`)
    return response.data.orderID
}

export const captureExecutedPaypalOrder = async (db, checkoutId, data) => {
    console.log('posting captureExecutedPaypalOrder')
    await axios.post(`${BACKEND}/studio/ext/${db}/paypal/order/${checkoutId}/execute`, data)
    return data.orderID
}
import React from "react";
import StandardContainer from "../common/style/StandardContainer";
import Heading from "../common/style/Heading";
import {Button, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";


const Impressum = () => {
    const history = useHistory()
    return (
        <StandardContainer>
            <Heading>Impressum</Heading>
            <Typography variant="h5">Angaben gemäß § 5 TMG:</Typography>
            <Typography variant="body1">Kiss Solutions GbR Inh. T. Schroll, M. Künemann</Typography>
            <Typography variant="body1">Im Steinenberg 9/1</Typography>
            <Typography variant="body1">79585 Steinen</Typography>
            <Typography variant="body1">Vertreten durch: Max Künemann</Typography>
            <Typography variant="h5">Kontakt:</Typography>
            <Typography variant="body1">E-Mail: info[at]kisscal.de</Typography>
            <Typography variant="h5">Umsatzsteuer-ID:</Typography>
            <Typography variant="body1">DE308212219</Typography>
            <Button style={{marginTop: "20px"}} variant="contained" color="primary"
                    onClick={() => history.goBack()}>Back</Button>
        </StandardContainer>
    )
}

export default Impressum
import React from "react";
import BackendError from "./BackendError";
import {useDispatch, useSelector} from "react-redux";
import {selectStateLoaded} from "../../store/appointment/appointmentSelectors";
import {selectCheckVerbindungOngoing, selectHeaderUrl} from "../../store/common/commonSelectors";
import {checkConnection} from "../../store/common/commonActions";
import {useHistory, useLocation} from "react-router-dom";
import {newBooking} from "../../store/appointment/appointmentActions";

const BackendErrorContainer = () => {
    const search = useLocation().search;
    const back = new URLSearchParams(search).get('back');
    const error = new URLSearchParams(search).get('error');
    const stateLoaded = useSelector(state => selectStateLoaded(state))
    const headerUrl = useSelector(state => selectHeaderUrl(state));
    const checkVerbindungOngoing = useSelector(state => selectCheckVerbindungOngoing(state))
    const history = useHistory()
    const dispatch = useDispatch()
    const tryConnection = () => {
        dispatch(checkConnection(error, back))
    }


    const reset = () => {
        if (back.indexOf('gutschein') >= 0) {
            history.push('/gutschein')
            return
        }

        dispatch(newBooking())
        history.push('/')
        window.location.reload();
    }

    return (
        <BackendError stateLoaded={stateLoaded}
                      headerUrl={headerUrl}
                      handleReset={reset}
                      checkVerbindungOngoing={checkVerbindungOngoing}
                      tryConnection={() => tryConnection()}/>
    )
}

export default BackendErrorContainer
const loadPaypalScript = (clientid, waehrung) => {
    const existingScript = document.getElementById('paypal')
    return new Promise((resolve, reject) => {
        if (!existingScript) {
            const script = document.createElement('script')
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientid}&disable-funding=credit,card,sofort,giropay,sepa&currency=${waehrung}&intent=capture`
            script.id = 'googleMaps'
            document.body.appendChild(script)

            script.onload = () => {
                resolve()
            };
            script.onerror = () => {
                reject()
            }
        }
        if (existingScript) {
            resolve()
        }
    })
};
export default loadPaypalScript
import React from "react";
import StandardContainer from "../../common/style/StandardContainer";
import Heading from "../../common/style/Heading";
import {CircularProgress, Grid} from "@mui/material";
import VoucherInfoCard from "../info/VoucherInfoCard";
import VoucherDownload from "../VoucherDownload";

const VoucherCompleted = ({
                              t,
                              voucherData,
                              lang,
                              waehrung,
                              isLoaded,
                              selectedVoucherVorlage,
                              setSelectedVoucherVorlage,
                              voucherVorlagen,
                              onDownloadVoucher
                          }) => {

    const renderLoading = () => {
        return (
            <Grid container justifyContent="center">
                <CircularProgress size={200}/>
            </Grid>

        )
    }


    const renderVoucherInfo = () => {
        return (
            <React.Fragment>
                <VoucherInfoCard voucher={voucherData} lang={lang} waehrung={waehrung} t={t}/>
                <VoucherDownload voucherVorlagen={voucherVorlagen} onChangeVoucherVorlage={setSelectedVoucherVorlage}
                                 t={t}
                                 selectedVoucherVorlage={selectedVoucherVorlage} onDownloadVoucher={onDownloadVoucher}/>
            </React.Fragment>

        )
    }

    return (
        <React.Fragment>
            <StandardContainer>
                <Heading>{t('GUTSCHEIN_DOWNLOAD')}</Heading>

                {!isLoaded ? renderLoading() : renderVoucherInfo()}
            </StandardContainer>
        </React.Fragment>
    );
}

export default VoucherCompleted
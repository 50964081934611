import {createSimpleAction} from "../utils";

export const CHANGE_VORNAME = 'CHANGE_VORNAME';
export const CHANGE_NACHNAME = 'CHANGE_NACHNAME';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_MOBILE = 'CHANGE_MOBILE';
export const EMAIL_HASH_RECEIVED = 'EMAIL_HASH_RECEIVED';
export const EMAIL_CHECK_STARTED = 'EMAIL_CHECK_STARTED';
export const EMAIL_CHECK_RESEND = 'EMAIL_CHECK_RESEND';
export const EMAIL_CHECK_BAD_REQUEST = 'EMAIL_CHECK_BAD_REQUEST';

export const CHANGE_BIRTHDAY = 'CHANGE_BIRTHDAY';
export const PERSIST_KUNDE = 'PERSIST_KUNDE';
export const LOAD_PERSISTED_KUNDE = 'LOAD_PERSISTED_KUNDE';
export const VERIFY_EMAIL_STARTED = 'VERIFY_EMAIL_STARTED';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';
export const CHANGE_KONTAKTART = 'CHANGE_KONTAKTART';
export const CHANGE_DESEASES = 'CHANGE_DESEASES';

export const changeVorname = createSimpleAction(CHANGE_VORNAME, 'vorname');
export const changeNachname = createSimpleAction(CHANGE_NACHNAME, 'nachname');
export const changeEmail = createSimpleAction(CHANGE_EMAIL, 'email');
export const changeMobile = createSimpleAction(CHANGE_MOBILE, 'mobile');
export const changeKontaktart = createSimpleAction(CHANGE_KONTAKTART, 'kontaktart');
export const emailHashReceived = createSimpleAction(EMAIL_HASH_RECEIVED, 'emailHash');
export const emailCheckBadRequest = createSimpleAction(EMAIL_CHECK_BAD_REQUEST, 'emailCheckBadRequest')
export const changeDeseases = createSimpleAction(CHANGE_DESEASES, 'deseases');
export const startEmailCheck = () => ({type: EMAIL_CHECK_STARTED});
export const resendEmailCheck = () => ({type: EMAIL_CHECK_RESEND});
export const changeBirthday = createSimpleAction(CHANGE_BIRTHDAY, 'birthday');
export const startingEmailVerification = createSimpleAction(VERIFY_EMAIL_STARTED, 'secret');
export const emailVerificationSuccess = () => ({type: VERIFY_EMAIL_SUCCESS});
export const emailVerificationFailed = () => ({type: VERIFY_EMAIL_FAILED});
export const persistKunde = () => ({type: PERSIST_KUNDE});
export const loadPersistedKundeToState = (kundeState) => ({type: LOAD_PERSISTED_KUNDE, payload: kundeState});

import axios from "axios";
import {isBefore, isSameOrBefore, plus, switchTimezone} from "@kiss-solutions/dateutils";
import {TERMIN_AUSGEFALLEN} from "../models/termin";

const BACKEND = process.env.REACT_APP_BASEURL;
const BASEURL = BACKEND + '/artists/ext';

export const getAllArtistFeiertage = async (db, start, end, timezone, artistIdList) => {
    if (!artistIdList?.length) {
        return []
    }
    const {artistids, startString, endString} = convertParameters(start, end, artistIdList);
    const query = `${BASEURL}/${db}/feiertagsmap/${startString}/${endString}`;

    const feiertagsMap = await axios.get(query, {params: {artists: artistids}});
    return convertFeiertagsMap2BookedPeriod(feiertagsMap.data, timezone);
}

function convertParameters(start, end, artistIdList) {
    return {
        artistids: artistIdList.map(t => t.toString()).join(',')
        , startString: start.toISOString().substr(0, 10)
        , endString: end.toISOString().substr(0, 10)
    }
}


export const getAllArtistEvents = async (db, start, end, timezone, artistIdList) => {
    if (!artistIdList?.length) {
        return []
    }
    const {artistids, startString, endString} = convertParameters(start, end, artistIdList);
    const query = `${BASEURL}/${db}/calender/${encodeURI(artistids)}/${startString}/${endString}`;
    const {data} = await axios.get(query);
    const result = data.filter(t => t.TrmStat !== TERMIN_AUSGEFALLEN)
        .map(t => convertTermin2BookPeriod(t, timezone, start, end))
        .reduce((pv, cv) => pv.concat(cv), []);
    return result

}

export const getEMailHash = async (db, email, artistId) => {
    const query = `${BASEURL}/${db}/${artistId}/checkmailadr/${encodeURI(email)}`;
    const {data} = await axios.get(query);
    return data.hash;
}

export function convertFeiertagsMap2BookedPeriod(map, timezone) {
    const result = [];
    Object.keys(map).forEach(key => {
        map[key].forEach(feiertag => {
            let start = new Date(feiertag.Datum);
            start.setHours(0, 0, 0, 0);
            start = switchTimezone(start, timezone);
            result.push({
                Start: start.toISOString()
                , DatBis: plus(start, 1, 'date').toISOString()
                , ArtistID: +key
            });
        });
    });
    return result;
}

function getDuration(start, datBis, timezone) {
    const timefmt = new Intl.DateTimeFormat('de', {hour: 'numeric', minute: 'numeric', timeZone: timezone})
    const [vonHr, vonMin] = timefmt.format(new Date(start)).split(":")
    const [bisHr, bisMin] = timefmt.format(new Date(datBis)).split(":")
    return (+bisHr - +vonHr) * 3_600_000 + (+bisMin - +vonMin) * 60_000
}

function convertTermin2BookPeriod(termin, timezone, start, end) {
    const resultDatBis = new Date(termin.DatBis);
    const resultStart = new Date(termin.Start);
    const singleResult = {
        Start: termin.Start
        , DatBis: termin.DatBis
        , ArtistID: termin.ArtistID
    };
    const result = [];

    if (!termin.Ganztags && isBefore(resultStart, resultDatBis, 'date')) {
        let dat = new Date(singleResult.Start);
        const duration = getDuration(termin.Start, termin.DatBis, timezone)
        while (isSameOrBefore(dat, resultDatBis, 'date')) {
            const datBis = new Date(dat.getTime() + duration);
            if (isSameOrBefore(start, dat, 'date') && end.getTime() >= datBis.getTime()) {
                const newDat = {
                    Start: dat.toISOString()
                    , DatBis: datBis.toISOString()
                    , ArtistID: singleResult.ArtistID
                };
                result.push(newDat);
            }
            dat = plus(dat, 1, 'date');
        }
    } else {
        result.push(singleResult);
    }
    return result;
}


import React from "react";
import {Checkbox, Fade, Grid, Typography} from "@mui/material";
import {getBezeichnungWithPreis} from "../../../models/Produkt";
import {makeStyles, useTheme} from "@mui/styles";
import ExpandableText from "../../common/style/ExpandableText";

const useStyles = makeStyles({
    hover: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

const Produkt = ({produkt, checked, handleProduktSelection, imageBasePath, currency, isTabletOrGreater}) => {
    const theme = useTheme()
    const classes = useStyles(theme)

    const renderImage = () => {
        if (!produkt.Bild) {
            return null;
        }
        const minHeight = '250px'
        const key = encodeURIComponent(produkt.Bild)
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight}}>
                <Fade in={true} timeout={2000}>
                    <img style={{maxHeight: minHeight, maxWidth: '100%'}}
                         alt="Produktbild"
                         className={classes.hover}
                         onClick={() => handleProduktSelection(produkt.ProduktID)}
                         src={`${imageBasePath}?key=${key}`}/>
                </Fade>
            </div>

        );
    }

    return (
        <Grid item xs={12} style={{marginTop: '20px'}}>
            <Grid container direction="row" justifyContent="flext-start" alignItems="center">
                <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={() => handleProduktSelection(produkt.ProduktID)}
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
                <Typography onClick={() => handleProduktSelection(produkt.ProduktID)}
                            className={classes.hover}
                            variant={isTabletOrGreater ? 'h5' : 'h6'}>{getBezeichnungWithPreis(produkt, currency)}</Typography>
            </Grid>
            {renderImage()}
            {produkt.Beschreibung?.length ? <ExpandableText theme={theme} beschreibung={produkt.Beschreibung}/> : null}
        </Grid>
    );
}


export default Produkt;

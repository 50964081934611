import React from "react";
import {Grid, Link as MuiLink} from "@mui/material";
import {Link} from "react-router-dom";
import {makeStyles, useTheme} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    lowKey: {
        opacity: 0.4,
        color: theme.text.primary
    }
}));

const LegalFooter = ({t}) => {
    const theme = useTheme()
    const classes = useStyles(theme)
    return (
        <Grid container style={{marginTop: '50px'}}>
            <Link className={classes.lowKey} to="/impressum">{t('LEGAL_FOOTER.LINK_TEXT_IMPRESSUM')}</Link>
            <MuiLink className={classes.lowKey}
                     href="https://kisscal.tattoo/doc/Html/DS-Vereinbarung-Termin-Portal.html"
                     target="_blank"
                     style={{marginLeft: '20px'}}>{t('LEGAL_FOOTER.LINK_TEXT_DATENSCHUTZ')}</MuiLink>
        </Grid>
    )
}

export default LegalFooter
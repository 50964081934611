import React from 'react';
import {Router} from 'react-router-dom';
import history from "../history";
import OnlineContainer from "./OnlineContainer";
import './App.css';
import {connect} from "react-redux";

const App = (props) => {
    // BrowserRouter kann nicht verwendet werden, da history prop nicht verfügbar ist
    return (
        <React.Suspense fallback="loading">
            <Router history={history}>
                <div className={props.mode === 'dark' ? 'background-dark' : 'background-light'}
                     style={{minHeight: '100vh'}}>
                    <OnlineContainer location={history.location}/>
                </div>
            </Router>
        </React.Suspense>
    );

};
const mapStateToProps = state => ({
    mode: state.common.theming.mode
})

export default connect(mapStateToProps)(App);

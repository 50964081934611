import {endOfDay} from "@kiss-solutions/dateutils/lib/common";
import {getAllArtistEvents, getAllArtistFeiertage} from "../../api/artistApi";
import {getFreeTimeslots} from "@kiss-solutions/artistlib";
import {narrowTimeslot} from "../utils";

export const getTermine = async (db, allArtists, studio, terminart, rubrik, duration) => {
    const artistIdList = terminart.TerminArtArtists.map(t => t.ArtistID);
    // Exited Artists are not loaded an could lead to NPE
    const artists = artistIdList.map(id => allArtists[id]).filter(t => !!t);
    if (!artistIdList?.length) {
        return []
    }
    const start = terminart.MinStart;
    const end = endOfDay(terminart.MaxStart);
    const feiertage = terminart.OhneTermin ? [] : await getAllArtistFeiertage(db, start, end, studio.Timezone, artistIdList);
    const events = terminart.OhneTermin ? [] : await getAllArtistEvents(db, start, end, studio.Timezone, artistIdList);
    const termine = feiertage.concat(events);
    const params = {start, end, termine, studio, duration, terminart, rubrik, artists};
    return calculatTermine4TerminArtArtists(params);
}

export function calculatTermine4TerminArtArtists(parms) {
    let freieArtistTermine = [];
    for (const taArtist of parms.terminart.TerminArtArtists) {
        const artist = parms.artists.find(t => t.ArtistID === taArtist.ArtistID);
        if (!artist) {
            continue;
        }
        const filteredTermine = parms.termine.filter(t => t.ArtistID === taArtist.ArtistID);
        let artistSlots = getFreeTimeslots(parms.start, parms.end, artist, filteredTermine, parms.studio, parms.duration)
            .map((ts, idx) => ({...ts, id: idx + 1}));
        if (parms.rubrik.TatOrtID) {
            artistSlots = artistSlots.filter(t => t.location.TatOrtID === parms.rubrik.TatOrtID);
        }
        if (parms.terminart.ZeitVon) {
            artistSlots = artistSlots.map(t => narrowArtistTimeslot(t, parms.terminart, parms.duration, parms.studio.Timezone))
                .filter(t => t)
        }
        freieArtistTermine = freieArtistTermine.concat(artistSlots);
    }
    return freieArtistTermine;
}

function narrowArtistTimeslot(artistSlot, terminart, duration, timezone) {
    const newTimeslot = narrowTimeslot(new Date(artistSlot.termin.Start), new Date(artistSlot.termin.DatBis), terminart.ZeitVon, terminart.ZeitBis, timezone)
    if (!newTimeslot) {
        return undefined
    }
    if ((newTimeslot.bis.getTime() - newTimeslot.von.getTime()) / 60_000 < duration) {
        return undefined
    }
    return {
        ...artistSlot
        , termin: {
            Start: newTimeslot.von.toISOString(),
            DatBis: newTimeslot.bis.toISOString()
        }
    }
}
import {call, fork, put, select, takeLatest} from "redux-saga/effects";
import {
    ABORT_SOFORT_PAYMENT,
    EXECUTE_PAYPAL_ORDER,
    INITIATE_PAY_LATER,
    INITIATE_SOFORT_PAYMENT,
    LOAD_PERSISTED_CHECKOUT_STATE,
    PAY_LATER_FAILURE,
    PAY_LATER_SUCCESS,
    payLaterFailure,
    payLaterSuccess,
    PAYPAL_PAYMENT_FAILURE,
    PAYPAL_PAYMENT_SUCCESS,
    paypalFailure,
    paypalSuccess,
    PENDING_PAYMENT_CLEARED,
    PENDING_TERMINKAUTION_PAYMENT,
    PENDING_VOUCHER_PAYMENT,
    pendingPaymentCleared,
    persistedCheckoutLoadedIntoStore,
    PROCESS_TERMIN_BOOKING_PAY_LATER,
    PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE,
    PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS,
    processTerminbookingAndPayLaterFailure,
    processTerminbookingAndPayLaterSuccess,
    registerCheckoutid,
    setCheckoutStatus,
    SOFORT_CHECK_CHECKOUT_STATUS,
    sofortInitiated
} from "./checkoutActions";
import {
    selectCheckoutFromTerminData,
    selectCheckoutFromVoucherData,
    selectCheckoutId,
    selectCheckoutState,
    selectPaymentType,
    selectPaypalOrder,
    selectStripePaymentStatus
} from "./checkoutSelector";
import * as api from "../../api/studioApi";
import {
    createSofortSource,
    deleteCheckout,
    getCheckoutStatus,
    processPayLater,
    retryStripePayment,
    saveCheckout
} from "../../api/studioApi";
import {createOpenTerminCheckoutRecord} from "../../models/onlineTermin";
import {selectDB, selectLanguage} from "../common/commonSelectors";
import {loadPersistedCheckoutState, saveCheckoutState} from "../../api/localPersistence";
import {
    INITIATE_SOFORT_PAYMENT_ERROR,
    PAY_LATER_PROCESSING_ERROR,
    PAYPAL_EXECUTE_ORDER_ERROR,
    PROCESS_TERMIN_BOOKING_PAYLATER_ERROR,
    REGISTER_TERMINKAUTION_CHECKOUT_ERROR,
    REGISTER_VOUCHER_CHECKOUT_ERROR,
    SOFORT_CHECK_CHECKOUT_STATUS_ERROR
} from "../common/commonSaga";
import history from "../../history";
import {captureExecutedPaypalOrder} from "../../api/paypalApi";
import {NEW_BOOKING} from "../appointment/appointmentActions";
import {emailCheckBadRequest} from "../kunde/kundeActions";
import {createErrorPathWithCurrentLocationAsBack} from "../utils";
import {constructCheckoutData} from "./constructCheckoutData";


export function* clearCheckout() {
    const checkoutId = yield select(state => selectCheckoutId(state))
    const paymentType = yield select(state => selectPaymentType(state))
    if (paymentType) {
        if (checkoutId) {
            const db = yield select(state => selectDB(state))
            try {
                yield call(deleteCheckout, db, checkoutId)
            } catch (e) {
                console.log(e)
            }
        }
        yield put(pendingPaymentCleared())
    }
}

function* loadAndSetPersistedCheckoutStage() {
    try {
        const persistedState = yield call(loadPersistedCheckoutState);
        yield put(persistedCheckoutLoadedIntoStore(persistedState))
    } catch (e) {
        console.log(e);
    }
}

export function* watchLoadPersistedCheckoutStateAction() {
    yield takeLatest(LOAD_PERSISTED_CHECKOUT_STATE, loadAndSetPersistedCheckoutStage)
}

export function* registerTerminkautionCheckout({payload}) {
    const checkoutDataBase = yield select(state => selectCheckoutFromTerminData(state))
    const checkoutData = constructCheckoutData({...checkoutDataBase, isPaid: true})
    const lang = yield select(state => selectLanguage(state))
    const db = yield select(state => selectDB(state));
    try {
        const data = yield call(createOpenTerminCheckoutRecord, payload.payment.checkoutId, checkoutData, lang)
        const checkoutId = yield call(saveCheckout, db, data)
        yield put(registerCheckoutid(checkoutId))
        yield* persistPaymentState()
        history.push('/checkout')
    } catch (err) {
        console.error(err)
        if (err.response?.status === 400) {
            yield put(emailCheckBadRequest(true))
            history.push({
                pathname: 'config-error',
                search: "?" + new URLSearchParams({error: 'MAIL_CONFIGURATION'}).toString()
            })
        } else {
            history.push(createErrorPathWithCurrentLocationAsBack('/error', REGISTER_TERMINKAUTION_CHECKOUT_ERROR))
        }
    }
}

function* watchPendingTerminkautionPayment() {
    yield takeLatest(PENDING_TERMINKAUTION_PAYMENT, registerTerminkautionCheckout)
}

function* registerVoucherCheckout() {
    console.log('pending')
    const data = yield select(state => selectCheckoutFromVoucherData(state))
    const db = yield select(state => selectDB(state));
    try {
        const checkoutId = yield call(saveCheckout, db, data)
        yield put(registerCheckoutid(checkoutId))
        yield* persistPaymentState()
        history.push('/checkout')
    } catch (err) {
        console.error(err)
        history.push(createErrorPathWithCurrentLocationAsBack('/error', REGISTER_VOUCHER_CHECKOUT_ERROR))
    }
}

function* watchPendingVoucherPayment() {
    yield takeLatest(PENDING_VOUCHER_PAYMENT, registerVoucherCheckout)
}

function* persistPaymentState() {
    const checkoutState = yield select(state => selectCheckoutState(state));
    yield call(saveCheckoutState, checkoutState)
}

function* watchPersitingNeeded() {
    yield takeLatest([EXECUTE_PAYPAL_ORDER, PAYPAL_PAYMENT_SUCCESS, PAYPAL_PAYMENT_FAILURE, INITIATE_PAY_LATER,
        PAY_LATER_SUCCESS, PAY_LATER_FAILURE, PROCESS_TERMIN_BOOKING_PAY_LATER, ABORT_SOFORT_PAYMENT,
        PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS, PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE,
        NEW_BOOKING, PENDING_PAYMENT_CLEARED], persistPaymentState)
}

function* processPayLaterAction() {
    const db = yield select(state => selectDB(state))
    const statusOk = yield call(checkConnection, db, PAY_LATER_PROCESSING_ERROR)
    if (statusOk) {
        try {
            const checkoutData = yield select(state => selectCheckoutFromTerminData(state))
            yield call(processPayLater, db, constructCheckoutData(checkoutData))
            yield put(payLaterSuccess())
        } catch (e) {
            console.log(e)
            history.push('/bookingerror')
            yield put(payLaterFailure())
        }
    }

}

function* watchPayLater() {
    yield takeLatest(INITIATE_PAY_LATER, processPayLaterAction)
}

function* initiateSofortPayment({payload}) {
    try {
        const checkoutId = yield select(state => selectCheckoutId(state))
        const db = yield select(state => selectDB(state));
        if (payload.isRetry) {
            yield call(retryStripePayment, db, checkoutId)
        }
        const stripeData = yield call(createSofortSource, db, checkoutId)
        yield put(sofortInitiated(stripeData.client_secret))
        yield call(persistPaymentState)
        window.location.href = stripeData.redirect.url
    } catch (e) {
        console.log(e)
        history.push(createErrorPathWithCurrentLocationAsBack('/error', INITIATE_SOFORT_PAYMENT_ERROR))
    }
}

function* watchInitiateSofortPayment() {
    yield takeLatest(INITIATE_SOFORT_PAYMENT, initiateSofortPayment)
}

export function* checkSofortCheckoutStatus() {
    const checkoutId = yield select(state => selectCheckoutId(state))
    const db = yield select(state => selectDB(state));
    const stripePaymentStatus = yield select(state => selectStripePaymentStatus(state))
    try {
        const status = yield call(getCheckoutStatus, db, checkoutId)
        if (stripePaymentStatus !== status) {
            yield put(setCheckoutStatus(status))
            yield call(persistPaymentState)
        }
    } catch (e) {
        console.log(e)
        history.push(createErrorPathWithCurrentLocationAsBack('/error', SOFORT_CHECK_CHECKOUT_STATUS_ERROR))
    }
}

function* watchCheckCheckoutStatus() {
    yield takeLatest(SOFORT_CHECK_CHECKOUT_STATUS, checkSofortCheckoutStatus)
}

export function* executePaypalPayment() {
    const db = yield select(state => selectDB(state))
    const checkoutId = yield select(state => selectCheckoutId(state))
    const statusOk = yield call(checkConnection, db, PAYPAL_EXECUTE_ORDER_ERROR)
    const paypalOrder = yield select(state => selectPaypalOrder(state))
    if (statusOk) {
        try {
            const orderID = yield call(captureExecutedPaypalOrder, db, checkoutId, paypalOrder)
            yield put(paypalSuccess(orderID))
        } catch (e) {
            console.log(e)
            const paymentId = paypalOrder?.orderID
            if (paymentId) {
                history.push({
                    pathname: '/bookingerror',
                    search: "?" + new URLSearchParams({paymentId}).toString()
                })
            } else {
                history.push('/bookingerror')
            }

            yield put(paypalFailure())
        }
    }
}

function* watchExecutePaypalOrderAction() {
    yield takeLatest(EXECUTE_PAYPAL_ORDER, executePaypalPayment)
}

function* checkConnection(db, errorInfo) {
    try {
        yield call(api.checkAbo, db);
        return true
    } catch (e) {
        console.log(e)
        history.push(createErrorPathWithCurrentLocationAsBack('/error', errorInfo))
    }
}


export function* processTerminBookingAndPayLater() {
    const db = yield select(state => selectDB(state))
    const statusOk = yield call(checkConnection, db, PROCESS_TERMIN_BOOKING_PAYLATER_ERROR)
    if (statusOk) {
        const checkoutDataBase = yield select(state => selectCheckoutFromTerminData(state))
        const checkoutData = constructCheckoutData(checkoutDataBase)
        try {
            yield call(processPayLater, db, checkoutData)
            yield put(processTerminbookingAndPayLaterSuccess())
        } catch (e) {
            console.log(e)
            const checkoutId = yield select(state => selectCheckoutId(state))
            history.push({
                pathname: '/bookingerror',
                search: "?" + new URLSearchParams({checkoutId}).toString()
            })
            yield put(processTerminbookingAndPayLaterFailure())
        }
    }

}

function* watchProcessTerminBookingPayLater() {
    yield takeLatest(PROCESS_TERMIN_BOOKING_PAY_LATER, processTerminBookingAndPayLater)
}

const checkoutSaga = [
    fork(watchPendingTerminkautionPayment)
    , fork(watchPendingVoucherPayment)
    , fork(watchLoadPersistedCheckoutStateAction)
    , fork(watchPersitingNeeded)
    , fork(watchPayLater)
    , fork(watchInitiateSofortPayment)
    , fork(watchCheckCheckoutStatus)
    , fork(watchExecutePaypalOrderAction)
    , fork(watchProcessTerminBookingPayLater)
];

export default checkoutSaga;
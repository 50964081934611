import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom"
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    CssBaseline,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import {numberFormat} from "../../utils/Localization";
import SofortLogo from '../../public/images/sofort-logo.svg'
import Success from "../success/Success";
import {TERMINKAUTION_PAYMENT_TYPE} from "../../store/checkout/checkoutActions";
import CheckoutVoucherCompleted from "./CheckoutVoucherCompleted";
import Heading from "../common/style/Heading";
import VoucherInfoCard from "../voucher/info/VoucherInfoCard";
import LegalFooter from "../common/navigation/LegalFooter";
import LangSelector from "../language/langSelector";


const Checkout = ({
                      stateLoaded,
                      headerUrl,
                      createOrder,
                      onApprove,
                      onPaypalError,
                      paymentType,
                      voucher,
                      terminkaution,
                      waehrung,
                      lang,
                      periodAsText,
                      artist,
                      initiateSofort,
                      abortSofort,
                      isPaymentSuccess,
                      willPayLater,
                      onClickPayLater,
                      showPaymentOptions,
                      isStripePaymentStatusOpen,
                      isStripePayment,
                      checkStripePayment,
                      isPaymentProcessing,
                      isPaymentFailed,
                      email,
                      onClickNextBooking,
                      voucherVorlagen,
                      onChangeVoucherVorlage,
                      selectedVoucherVorlage,
                      onDownloadVoucher,
                      isPaypalAllowed,
                      isSofortAllowed,
                      forwardButtonConfig,
                      t,
                      activeLanguage,
                      onChangeLanguage
                  }) => {

    const [stripeReadCount, setStripeReadCount] = useState(1)

    useEffect(() => {
        if (stripeReadCount >= 20) {
            abortSofort()
        }
        if (isStripePayment && isStripePaymentStatusOpen && stripeReadCount < 20) {
            checkStripePayment()
            const timer = setTimeout(() => setStripeReadCount(stripeReadCount + 1), 2000);
            return () => clearTimeout(timer)
        }
    }, [stripeReadCount, isStripePayment, isStripePaymentStatusOpen, abortSofort, checkStripePayment])

    const renderPaypalButtion = () => {
        if (stateLoaded) {
            const PayPalButton = window.paypal.Buttons.driver("react", {React, ReactDOM});
            return (
                <PayPalButton
                    createOrder={() => createOrder()}
                    onApprove={(data) => onApprove(data)}
                    onError={err => onPaypalError(err)}
                />
            );
        }
    }

    const getEmail = () => paymentType === TERMINKAUTION_PAYMENT_TYPE ? email : voucher.email

    const renderTerminkautionCard = () => {
        return (
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Typography style={{fontWeight: 'bold'}}
                                        variant="subtitle1">{t('CHECKOUT_TYPE')}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">{t('CHECKOUT_TERMINKAUTION')}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontWeight: 'bold'}} variant="subtitle1">{t('Betrag')}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">{numberFormat(terminkaution, lang, waehrung)}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontWeight: 'bold'}} variant="subtitle1">Details</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="body1">{artist.Kuerzel}</Typography>
                            <Typography variant="body1">{periodAsText.text}</Typography>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        )
    }

    const renderCheckoutCard = () => {
        if (paymentType === TERMINKAUTION_PAYMENT_TYPE) {
            return renderTerminkautionCard()
        }
        return <VoucherInfoCard t={t} voucher={voucher} waehrung={waehrung} lang={lang}/>
    }

    const renderSofortButton = () => {
        return (
            <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '20px'}}>
                <img height="50px" src={SofortLogo} alt="Sofort Logo" onClick={e => initiateSofort(e)}/>
            </div>

        )
    }

    const renderSpinner = () => {
        return (
            <Grid container alignItems="center" style={{marginTop: "20px"}}>
                <Grid item xs={2}>
                    <CircularProgress/>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1">{t('ZAHLUNGSPROZESS_LAEUFT')}</Typography>
                </Grid>
            </Grid>
        )
    }

    const renderSuccessBooking = () => {
        console.log(forwardButtonConfig)
        if (!isPaymentSuccess && !willPayLater) {
            return null
        }
        if (paymentType === TERMINKAUTION_PAYMENT_TYPE) {
            return (
                <Success paymentType={paymentType} email={getEmail()} onClickNextBooking={onClickNextBooking}
                         forwardButtonConfig={forwardButtonConfig}/>
            )
        }
        return (
            <CheckoutVoucherCompleted voucherVorlagen={voucherVorlagen} onChangeVoucherVorlage={onChangeVoucherVorlage}
                                      onDownloadVoucher={onDownloadVoucher}
                                      t={t}
                                      selectedVoucherVorlage={selectedVoucherVorlage}
                                      email={getEmail()} onClickNextBooking={onClickNextBooking}/>

        )

    }

    const renderPaymentFailed = () => {
        if (!isPaymentFailed) {
            return null
        }
        return (
            <React.Fragment>
                <Alert style={{marginTop: '20px'}}
                       severity="error">{t('ZAHLUNG_FEHLGESCHLAGEN', {email})}</Alert>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <CssBaseline>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={8} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                        <Box position="relative">
                            {stateLoaded ? <img src={headerUrl} alt='header-image' width="100%"/> : null}
                            <Box position='absolute' top='0' right='7px'>
                                <LangSelector isoLang={activeLanguage || 'de'} onChange={onChangeLanguage}/>
                            </Box>
                        </Box>

                        <Paper style={{flexGrow: '1', overflowY: 'auto', padding: '0 7px'}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{flexGrow: '0', paddingBottom: "30px"}}>
                                    <Heading>{t('Bezahlung')}</Heading>
                                    {renderCheckoutCard()}
                                    {showPaymentOptions && isPaypalAllowed ? renderPaypalButtion() : null}
                                    {showPaymentOptions && isSofortAllowed ? renderSofortButton() : null}
                                    {showPaymentOptions && paymentType === TERMINKAUTION_PAYMENT_TYPE ?
                                        <Button style={{marginTop: '50px'}}
                                                onClick={() => onClickPayLater()}>{t('BEZAHLE_SPAETER')}</Button> : null}

                                    {isPaymentProcessing ? renderSpinner() : null}

                                    {isPaymentSuccess ?
                                        <Typography variant="body1">{t('BEZAHLUNG_ABGESCHLOSSEN')}</Typography> : null}
                                    {willPayLater ?
                                        <Typography variant="body1"> {t('BEZAHLE_SPAETER')}</Typography> : null}
                                    {renderSuccessBooking()}
                                    {renderPaymentFailed()}

                                </div>
                            </div>
                        </Paper>
                        <LegalFooter t={t}/>
                    </Grid>
                </Grid>
            </CssBaseline>
        </React.Fragment>
    );
}

export default Checkout
import {isBefore, plus} from "@kiss-solutions/dateutils";
import {pick} from "lodash";

const PROLONGATION_PERIOD_MINUTES = 60
const UI_STATE_VERSION = 1

const INITIAL_UI_STATE = {
    version: UI_STATE_VERSION
    , selectedRubrikID: undefined
    , selectedTerminArtID: undefined
    , produktList: []
    , expire: plus(new Date(), PROLONGATION_PERIOD_MINUTES, 'minute').toISOString()
};


export const getDbPrefix = () => {
    return localStorage.getItem('dbprefix');
}

export const setDbPrefix = (dbprefix) => {
    const prefix = getDbPrefix();
    if (dbprefix !== prefix) {
        localStorage.setItem('dbprefix', dbprefix);
        savePersistedUIState(INITIAL_UI_STATE);
    }
}

export const checkAppointmentStateForExpired = () => {
    const state = loadPersistedUIState();
    if (UI_STATE_VERSION !== state.version || isBefore(new Date(state.expire), new Date())) {
        clearState()
        return true
    } else {
        prolong(state);
        savePersistedUIState(state);
        return false
    }
}

export const clearState = () => {
    savePersistedUIState(removeAllAppointmentSelections());
    removePersistedCheckoutState()
    removePersistedVoucherState()
}

export const removeDbPrefix = () => {
    localStorage.removeItem('dbprefix');
}

export const removeTheming = () => {
    localStorage.removeItem('theming');
}


export const loadPersistedUIState = () => {
    return loadLocalStorage('state', INITIAL_UI_STATE);
}

export const loadPersistedKundeState = () => {
    const s = localStorage.getItem('kunde');
    return s ? JSON.parse(s) : undefined
}

export const loadPersistedVoucherState = () => {
    const s = localStorage.getItem('voucher');
    return s ? JSON.parse(s) : undefined
}

export const savePersistedUIState = (state) => {
    const stateWithVersion = {...state, version: UI_STATE_VERSION}
    localStorage.setItem('state', JSON.stringify(stateWithVersion))
}

export const loadPersistedThemingState = () => {
    return loadLocalStorage('theming');
}

export const savePersistedThemingState = (state) => {
    localStorage.setItem('theming', JSON.stringify(state));
}

function loadLocalStorage(storage, initialState) {
    const s = localStorage.getItem(storage);
    return s ? JSON.parse(s) : initialState;
}

export const removeAllAppointmentSelections = () => {
    const state = loadPersistedUIState();
    prolong(state);
    state.selectedRubrikID = undefined;
    state.selectedTerminArtID = undefined;
    state.selectedTatOrtID = undefined;
    state.selectedArtistID = undefined;
    state.selectedPeriod = undefined;
    state.produktList = [];
    return state;
}

function prolong(state) {
    state.expire = plus(new Date(), PROLONGATION_PERIOD_MINUTES, 'minute').toISOString();
}

export const saveUiStateAfterSelection = (uiState) => {
    const persistedState = removeAllAppointmentSelections();
    const fields = ['selectedRubrikID', 'selectedTerminArtID', 'selectedTatOrtID', 'blockerId'
        , 'selectedArtistID', 'selectedPeriod', 'produktList', 'selectedTimeslotIndex', 'selectedPeriod'
        , 'activeStep', 'datenschutzRead', 'customerMessage', 'images'];
    const cleanedUiState = pick(uiState, fields);
    const newState = {...persistedState, ...cleanedUiState};
    savePersistedUIState(newState);
}

export const saveKundeState = (kundeState) => {
    localStorage.setItem('kunde', JSON.stringify(kundeState));
}

export const saveVoucherState = (voucherState) => {
    localStorage.setItem('voucher', JSON.stringify(voucherState));
}

export const loadPersistedCheckoutState = () => {
    const s = localStorage.getItem('checkout');
    return s ? JSON.parse(s) : undefined;
}

export const removePersistedCheckoutState = () => {
    localStorage.removeItem('checkout');
}

export const removePersistedVoucherState = () => {
    localStorage.removeItem('voucher');
}

export const saveCheckoutState = (paymentState) => {
    localStorage.setItem('checkout', JSON.stringify(paymentState));
}



import * as api from '../../api/studioApi';
import {checkConnectionBackend} from '../../api/studioApi';
import {all, call, fork, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from "./commonActions";
import {
    CHECK_CONNECTION,
    commonLoadCompleted,
    DB_SET_ABO_CHECKED,
    paypalSdkLoaded,
    SET_DB,
    SET_LANGUAGE,
    setCheckVerbindungOngoing
} from "./commonActions";
import {checkAppointmentStateForExpired, setDbPrefix} from "../../api/localPersistence";
import i18next from "i18next";
import {changeActiveStep} from "../appointment/appointmentActions";
import {selectDB, selectIsPaypalAllowed, selectStudio} from "./commonSelectors";
import loadPaypalScript from "../../components/common/paypal/ScriptLoader";
import history from "../../history";
import {bookBlockerAndUpdateBlockerID, calculateTerminslots} from "../appointment/appointmentSaga";
import {getEmailConfirmationSecret} from "../kunde/kundeSaga";
import {checkSofortCheckoutStatus} from "../checkout/checkoutSaga";
import {createErrorPathWithCurrentLocationAsBack} from "../utils";
import {BOOKING_START} from "../../components/bookingflow/BookingFlowStepperModel";

const CHECK_ABO_ERROR = 'CHECK_ABO_ERROR'
const LOAD_STUDIO_ERROR = 'LOAD_STUDIO_ERROR'
export const LOAD_TERMINE_ERROR = 'LOAD_TERMINE_ERROR'
export const CHECK_ABO_FAILED = 'CHECK_ABO_FAILED'
export const NO_STUDIO_GIVEN = 'NO_STUDIO_GIVEN'
export const BLOCKER_UPDATE_ERROR = 'BLOCKER_UPDATE_ERROR'
export const GET_EMAIL_HASH_ERROR = 'GET_EMAIL_HASH_ERROR'
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR'
export const REGISTER_TERMINKAUTION_CHECKOUT_ERROR = 'REGISTER_TERMINKAUTION_CHECKOUT_ERROR'
export const REGISTER_VOUCHER_CHECKOUT_ERROR = 'REGISTER_VOUCHER_CHECKOUT_ERROR'
export const PAY_LATER_PROCESSING_ERROR = 'PAY_LATER_PROCESSING_ERROR'
export const INITIATE_SOFORT_PAYMENT_ERROR = 'INITIATE_SOFORT_PAYMENT_ERROR'
export const PAYPAL_CREATE_ORDER_ERROR = 'PAYPAL_CREATE_ORDER_ERROR'
export const PAYPAL_EXECUTE_ORDER_ERROR = 'PAYPAL_EXECUTE_ORDER_ERROR'
export const SOFORT_CHECK_CHECKOUT_STATUS_ERROR = 'SOFORT_CHECK_CHECKOUT_STATUS_ERROR'
export const PROCESS_TERMIN_BOOKING_PAYLATER_ERROR = 'PROCESS_TERMIN_BOOKING_PAYLATER_ERROR'
export const LOAD_VOUCHER_FROM_CHECKOUT_ERROR = 'LOAD_VOUCHER_FROM_CHECKOUT_ERROR'

function* checkAboAndSetDb({payload}) {
    try {
        yield call(api.checkAbo, payload.db);
    } catch (e) {
        if (e.response?.status === 404) {
            history.push({
                pathname: '/config-error',
                search: "?" + new URLSearchParams({error: CHECK_ABO_FAILED}).toString()
            })
        } else {
            history.push(createErrorPathWithCurrentLocationAsBack('/error', CHECK_ABO_ERROR))
        }
        return false
    }
    try {
        yield call(setDbPrefix, payload.db);
        const isExpired = yield call(checkAppointmentStateForExpired);
        if (isExpired) {
            yield put(changeActiveStep(BOOKING_START))
        }
        yield put(actions.dbSetAndAboChecked(payload.db));
    } catch (e) {
        console.log(e);
        history.push(createErrorPathWithCurrentLocationAsBack('/error', CHECK_ABO_ERROR))
    }
    return true
}

function* getStudio({payload}) {
    try {
        const [studio, studioBase, produkte, artists, rubriken, terminarten, voucherStatus] = yield all([
            call(api.getStudio, payload.db)
            , call(api.getStudioBase, payload.db)
            , call(api.getProdukte, payload.db)
            , call(api.getArtists, payload.db)
            , call(api.getRubriken, payload.db)
            , call(api.getRTerminArten, payload.db)
            , call(api.getVoucherStatus, payload.db)

        ]);
        yield put(actions.setStudioData({
            studio: studio.data,
            studioBase: studioBase.data,
            produkte: produkte.data,
            allArtists: artists.data,
            rubriken: rubriken.data,
            terminarten: terminarten.data.filter(t => t.Aktiv),
            voucherStatus: voucherStatus.data
        }));

        const studioData = yield select(state => selectStudio(state));
        const isPaypalAllowed = yield select(state => selectIsPaypalAllowed(state));
        if (isPaypalAllowed && studioData.PaypalClientId) {
            yield call(loadPaypalScript, studioData.PaypalClientId, studioData.Waehrung)
            yield put(paypalSdkLoaded())
        }
        yield put(commonLoadCompleted())
    } catch (e) {
        console.log(e);
        history.push(createErrorPathWithCurrentLocationAsBack('/error', LOAD_STUDIO_ERROR))
    }
}

function* watchSetDB() {
    yield takeLatest(SET_DB, checkAboAndSetDb)
}

function* watchDbSetAndAboChecked() {
    yield takeLatest(DB_SET_ABO_CHECKED, getStudio)
}

function* updateI18nLanguage() {
    try {
        yield call(i18next.changeLanguage, 'de')
    } catch (e) {
        console.log(e);
    }
}

function* watchSetLanguage() {
    yield takeLatest(SET_LANGUAGE, updateI18nLanguage)
}


function* checkConnectionAndBootstrap({payload}) {
    try {
        yield put(setCheckVerbindungOngoing(true))
        yield call(checkConnectionBackend)
        const db = yield select(state => selectDB(state))
        const errorState = payload.error
        switch (errorState) {
            case CHECK_ABO_ERROR: {
                const success = yield call(checkAboAndSetDb, {payload: {db}})
                if (success) {
                    history.push(payload?.back || '/')
                }
                break
            }
            case LOAD_STUDIO_ERROR:
                yield call(getStudio, {payload: {db}})
                history.push(payload?.back || '/')
                break
            case LOAD_TERMINE_ERROR:
                yield call(calculateTerminslots)
                history.push(payload?.back || '/')
                break
            case BLOCKER_UPDATE_ERROR:
                yield call(bookBlockerAndUpdateBlockerID)
                history.push(payload?.back || '/')
                break
            case GET_EMAIL_HASH_ERROR:
                yield call(getEmailConfirmationSecret)
                history.push(payload?.back || '/')
                break
            case SOFORT_CHECK_CHECKOUT_STATUS_ERROR:
                yield call(checkSofortCheckoutStatus)
                history.push('/checkout')
            case INITIATE_SOFORT_PAYMENT_ERROR:
            case PAY_LATER_PROCESSING_ERROR:
            case PAYPAL_CREATE_ORDER_ERROR:
            case PAYPAL_EXECUTE_ORDER_ERROR:
                history.push('/checkout')
                break

            case REGISTER_VOUCHER_CHECKOUT_ERROR:
                history.push('/gutschein')
                break
            case LOAD_VOUCHER_FROM_CHECKOUT_ERROR:
                history.push(payload?.back || '/gutschein')
                break;

            case REGISTER_TERMINKAUTION_CHECKOUT_ERROR:
            case VERIFY_EMAIL_ERROR:
            case PROCESS_TERMIN_BOOKING_PAYLATER_ERROR:
            default:
                // do nothing process has to be repeated
                history.push(payload?.back || '/')
                break
        }


    } catch (e) {
        console.log(e)
    }
    yield put(setCheckVerbindungOngoing(false))
}

function* watchCheckConnection() {
    yield takeLatest(CHECK_CONNECTION, checkConnectionAndBootstrap)
}

const studioSagas = [fork(watchSetDB),
    fork(watchDbSetAndAboChecked),
    fork(watchSetLanguage),
    fork(watchCheckConnection)];

export default studioSagas;

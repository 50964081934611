import {createSelector} from "reselect";
import {
    selectActiveStep,
    selectIsDatenschutzRead,
    selectIsImageUploading,
    selectIsPeriodSelected,
    selectIsProduktSelected,
    selectSelectedArtistID,
    selectSelectedRubrikID,
    selectSelectedTatOrtID,
    selectSelectedTerminArt
} from "./appointmentSelectors";
import {
    BOOKING_BESTAETIGUNG,
    BOOKING_KUNDE,
    BOOKING_START,
    BOOKING_TERMIN,
    CUSTOMER_MESSAGE,
    PRODUKT,
    TERMINART
} from "../../components/bookingflow/BookingFlowStepperModel";
import {selectIsKundeValid} from "../kunde/kundeSelectors";

export const selectIsNextEnabledBookingStart = createSelector(
    selectActiveStep
    , selectSelectedRubrikID
    , (activeStep, rubrikId) => activeStep === BOOKING_START && !!rubrikId
)

export const selectIsNextEnabledTerminArtSelection = createSelector(
    selectActiveStep
    , selectSelectedTerminArt
    , (activeStep, terminart) => activeStep === TERMINART && !!terminart
)

export const selectIsNextEnabledProduktSelection = createSelector(
    selectActiveStep
    , selectIsProduktSelected
    , (activeStep, isProductSelected) => activeStep === PRODUKT && isProductSelected
)

export const selectOnProductSelectionIsNextEnabled = createSelector(
    selectIsNextEnabledBookingStart
    , selectIsNextEnabledTerminArtSelection
    , selectIsNextEnabledProduktSelection
    , (next1, next2, next3) => next1 || next2 || next3
)

export const selectIsNextEnabledTimeslotSelection = createSelector(
    selectActiveStep
    , selectIsPeriodSelected
    , selectSelectedTerminArt
    , selectSelectedTatOrtID
    , selectSelectedArtistID
    , (activeStep, isPeriodSelected, terminart, selectedTatortId, selectedArtistId) => activeStep === BOOKING_TERMIN
        && (isPeriodSelected || terminart.OhneTermin && !!selectedTatortId && !!selectedArtistId)
)

export const selectIsNextEnabledEditCustomerMessage = createSelector(
    selectActiveStep
    , activeStep => activeStep === CUSTOMER_MESSAGE
)

export const selectIsNextEnabledEditKunde = createSelector(
    selectActiveStep
    , selectIsKundeValid
    , (activeStep, isKundeValid) => activeStep === BOOKING_KUNDE && isKundeValid
)

export const selectIsNextEnabledOnConfirmation = createSelector(
    selectActiveStep
    , selectIsImageUploading
    , selectIsDatenschutzRead
    , (activeStep, isImageUploading, isDsRead) => activeStep === BOOKING_BESTAETIGUNG && !isImageUploading && isDsRead
)

export const selectCanSelectNext = createSelector(
    selectOnProductSelectionIsNextEnabled
    , selectIsNextEnabledTimeslotSelection
    , selectIsNextEnabledEditCustomerMessage
    , selectIsNextEnabledEditKunde
    , selectIsNextEnabledOnConfirmation
    , (next1, next2, next3, next4, next5) => next1 || next2 || next3 || next4 || next5
)
import {call, fork, takeLatest} from "redux-saga/effects";
import {savePersistedThemingState} from "../../api/localPersistence";
import {SET_MODE} from "./commonActions";

function* persistTheming({payload}) {
    yield call(savePersistedThemingState, payload);
}

function* watchUiTheme() {
    yield takeLatest(SET_MODE, persistTheming)
}

const themingSagas = [fork(watchUiTheme)];

export default themingSagas;

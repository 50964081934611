import React from "react";
import ConfigurationError from "./ConfigurationError";
import {useLocation} from "react-router-dom";

const ConfigurationErrorContainer = () => {
    const {search} = useLocation()
    const error = new URLSearchParams(search).get('error');
    return (
        <ConfigurationError error={error}/>
    )
}

export default ConfigurationErrorContainer
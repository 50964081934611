import React from "react";
import {Alert, Button, Grid} from '@mui/material';
import {useTranslation} from "react-i18next";
import {TERMINKAUTION_PAYMENT_TYPE} from "../../store/checkout/checkoutActions";

const Success = ({paymentType, onClickNextBooking, email, forwardButtonConfig}) => {
    const isTerminbuchtung = (paymentType === TERMINKAUTION_PAYMENT_TYPE)
    const {t} = useTranslation()

    const renderForwardButton = () => {
        if (!forwardButtonConfig || !isTerminbuchtung) {
            return null
        }
        return (
            <Button variant="contained" color="primary" onClick={() => location.href = forwardButtonConfig.url}
            >{forwardButtonConfig.buttonText}</Button>
        )
    }

    return (
        <React.Fragment>
            <Alert style={{marginTop: '20px'}}
                   severity="success">{isTerminbuchtung ? t('BUCHUNG_ERFOLGREICH', {email}) : t('GUTSCHEIN_VERSANDT', {email})}</Alert>
            <Grid container justifyContent="flex-end" style={{marginTop: '20px', marginBottom: '40px'}} spacing={2}>
                <Grid item>
                    {renderForwardButton()}
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary"
                            style={{marginLeft: '10px'}}
                            onClick={() => onClickNextBooking()}>{isTerminbuchtung ? t('WEITERE_BUCHUNG') : t('WEITERER_GUTSCHEIN')}</Button>
                </Grid>

            </Grid>
        </React.Fragment>
    );
}

export default Success
import React, {useState} from "react";
import {Alert, CircularProgress, Grid, Snackbar, TextField, Typography} from "@mui/material";
import FileInput from "../common/fileinput/FileInput";
import ImageCard from "../bookingflow/confirmation/ImageCard";

const CommentWithImage = ({
                              customerMessage,
                              setCustomerMessage,
                              t,
                              hideAddImageButton,
                              hintCustomerMessage,
                              showHint = false,
                              isImageUploading,
                              onAddImage,
                              images,
                              removeImage
                          }) => {
    const [openNoImageAlert, setONoImageAlert] = useState(false)

    const hint = hintCustomerMessage || t('WEITERE_INFOS')
    const renderAddImageButton = () => {
        if (hideAddImageButton) {
            return null
        }
        if (isImageUploading) {
            return (<Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
                <Grid item>
                    <Typography variant="body1">Uploading...</Typography>
                </Grid>
            </Grid>)
        } else {
            return <FileInput onAddImage={onAddImage} onError={() => setONoImageAlert(true)}
                              disabled={isImageUploading}/>
        }
    }

    const handleCloseNoImageAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setONoImageAlert(false);
    };

    return (
        <React.Fragment>
            {showHint ? <Alert style={{margin: '20px 0'}} severity="info">{hint}</Alert> : null}
            <TextField style={{paddingBottom: '10px', marginBottom: '20px'}}
                       fullWidth
                       multiline
                       variant="standard"
                       maxrows={3}
                       id="customermsg"
                       value={customerMessage}
                       label={t('CONFIRMATION_USER_MESSAGE')}
                       onChange={e => setCustomerMessage(e.target.value)}
            />
            <div style={{marginBottom: '10px'}}>
                {renderAddImageButton()}
            </div>
            {images.map(i => <ImageCard key={i.id}
                                        id={i.id}
                                        titel={i.name}
                                        imageLocation={i.dataURL}
                                        deleteHandler={removeImage}
                                        beschreibung={i.name}/>)}
            <Snackbar open={openNoImageAlert} autoHideDuration={6000} onClose={handleCloseNoImageAlert}>
                <Alert onClose={handleCloseNoImageAlert} severity="warning">
                    Das Bild konnte nicht gelesen werden!
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default CommentWithImage
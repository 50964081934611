import i18next from "i18next";
import {
    convertKunde2OTSummary,
    convertPeriodString2IsoDate,
    convertTerminart2OTSummary
} from "../../models/onlineTermin";
import {convert2TerminDetails} from "./terminDetailsConverter";


export const constructCheckoutData = ({
                                          terminartArtist, location, produkte, rubrik,
                                          terminArt, lang, periodText, customerMessage,
                                          blockerID, images, period, kunde, waehrung, isPaid
                                      }) => {
    const nachVereinbarung = i18next.t('(Preis nach Vereinbarung)')
    const zeroPriceText = i18next.t('(Kostenfrei)')
    const terminDetails = convert2TerminDetails({
        artist: terminartArtist
        , location
        , produktList: produkte
        , waehrung
        , rubrik
        , terminArt
        , lang
        , periodText
        , comment: customerMessage
        , zeroPriceText
        , nachVereinbarung
        , isPaid
    })
    return {
        produktList: produkte
        , artist: terminartArtist
        , rubrik
        , terminArt: convertTerminart2OTSummary(terminArt)
        , kunde: convertKunde2OTSummary(kunde)
        , waehrung
        , dbPeriodString: period && convertPeriodString2IsoDate(period) || ''
        , blockerID
        , comment: customerMessage
        , images: images.map((img, idx) => ({filename: `attachment-${idx}.jpg`, key: img.key}))
        , lang
        , terminDetails
    }
}
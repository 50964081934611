import {call, fork, put, select, take, takeLatest} from "redux-saga/effects";
import {
    CHANGE_BIRTHDAY,
    EMAIL_CHECK_RESEND,
    EMAIL_CHECK_STARTED,
    emailCheckBadRequest,
    emailHashReceived,
    emailVerificationFailed,
    emailVerificationSuccess,
    loadPersistedKundeToState,
    PERSIST_KUNDE,
    persistKunde,
    VERIFY_EMAIL_FAILED,
    VERIFY_EMAIL_STARTED,
    VERIFY_EMAIL_SUCCESS
} from "./kundeActions";
import {selectSelectedArtistID} from "../appointment/appointmentSelectors";
import {selectEmail, selectEmailHash, selectKundeState} from "./kundeSelectors";
import {selectDB} from "../common/commonSelectors";
import {getEMailHash} from "../../api/artistApi";
import {loadPersistedKundeState, saveKundeState} from "../../api/localPersistence";
import {DB_SET_ABO_CHECKED} from "../common/commonActions";
import {checkEmail} from "../../api/studioApi";
import {GET_EMAIL_HASH_ERROR, VERIFY_EMAIL_ERROR} from "../common/commonSaga";
import history from "../../history";
import {createErrorPathWithCurrentLocationAsBack} from "../utils";

function* loadAndSetPersistedKundeStage() {
    try {
        const persistedState = yield call(loadPersistedKundeState);
        yield put(loadPersistedKundeToState(persistedState))
    } catch (e) {
        console.log(e);
    }
}

function* watchSetDB() {
    yield takeLatest(DB_SET_ABO_CHECKED, loadAndSetPersistedKundeStage)
}

export function* getEmailConfirmationSecret() {
    try {
        const email = yield select(state => selectEmail(state));
        const artistId = yield select(state => selectSelectedArtistID(state));
        const db = yield select(state => selectDB(state));
        const emailHash = yield select(state => selectEmailHash(state));
        if (!!db && !!artistId && !!email && !emailHash) {
            const emailHash = yield call(getEMailHash, db, email, artistId);
            yield put(emailHashReceived(emailHash));
        }
    } catch (e) {
        if (e.response?.status === 400) {
            yield put(emailCheckBadRequest(true))
        } else {
            console.log(e)
            history.push(createErrorPathWithCurrentLocationAsBack('/error', GET_EMAIL_HASH_ERROR))
        }
    }
}

function* watchEmailCheckStarted() {
    yield takeLatest([EMAIL_CHECK_STARTED, EMAIL_CHECK_RESEND], getEmailConfirmationSecret)
}

function* startEmailVerification() {
    while (true) {
        const verifyAction = yield take(VERIFY_EMAIL_STARTED);
        const emailHash = yield select(state => selectEmailHash(state));
        if (verifyAction.payload.secret) {
            try {
                const result = yield call(checkEmail, verifyAction.payload.secret, emailHash);
                if (result) {
                    yield put(emailVerificationSuccess());
                } else {
                    yield put(emailVerificationFailed());
                }
            } catch (e) {
                console.log(e)
                history.push(createErrorPathWithCurrentLocationAsBack('/error', VERIFY_EMAIL_ERROR))
            }

        }
    }

}

function* executePersistKunde() {
    const kundeState = yield select(state => selectKundeState(state));
    yield call(saveKundeState, kundeState);
}

function* watchPersistKunde() {
    yield takeLatest([PERSIST_KUNDE, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILED], executePersistKunde);
}

function* dispatchPersistKunde() {
    yield put(persistKunde());
}

function* watchBirthdayChanged() {
    yield takeLatest(CHANGE_BIRTHDAY, dispatchPersistKunde)
}

const kundeSagas = [fork(watchSetDB), fork(watchEmailCheckStarted), fork(watchPersistKunde), fork(watchBirthdayChanged)
    , fork(startEmailVerification)];

export default kundeSagas;

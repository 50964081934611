import React from "react";
import CommentWithImage from "../../comment/CommentWithImage";
import Heading from "../../common/style/Heading";

const CustomerComment = ({
                             images,
                             customerMessage,
                             setCustomerMessage,
                             hintCustomerMessage,
                             showHint,
                             t,
                             addImage,
                             isImageUploading,
                             removeImage
                         }) => {
    return (
        <React.Fragment>
            <Heading>{t('CUSTOMER_MESSAGE_HEADING')}</Heading>
            <CommentWithImage customerMessage={customerMessage}
                              hintCustomerMessage={hintCustomerMessage}
                              t={t}
                              showHint={showHint}
                              setCustomerMessage={setCustomerMessage}
                              images={images}
                              isImageUploading={isImageUploading}
                              onAddImage={addImage}
                              removeImage={removeImage}
            />
        </React.Fragment>
    )
}

export default CustomerComment
import {all} from 'redux-saga/effects';
import studioSagas from "./common/commonSaga";
import uiSagas from "./appointment/appointmentSaga";
import themingSagas from "./common/themingSaga";
import kundeSagas from "./kunde/kundeSaga";
import checkoutSagas from "./checkout/checkoutSaga";
import voucherSaga from "./voucher/voucherSaga";
import xAppSaga from "./xapp/xAppSaga";

const sagas = function* rootSaga() {
    yield all([
        ...studioSagas,
        ...uiSagas,
        ...themingSagas,
        ...kundeSagas,
        ...checkoutSagas,
        ...voucherSaga,
        ...xAppSaga
    ]);
}

export default sagas;

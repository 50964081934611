import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectCustomerMessage,
    selectImages,
    selectIsImageUploading,
    selectTerminartKundenHinweisText
} from "../../../store/appointment/appointmentSelectors";
import {addTempImage, changeCustomerMessage, removeTempImage} from "../../../store/appointment/appointmentActions";
import CustomerComment from "./CustomerComment";
import {useTranslation} from "react-i18next";

const CustomerCommentContainer = () => {
    const images = useSelector(state => selectImages(state))
    const isImageUploading = useSelector(state => selectIsImageUploading(state))
    const customerMessage = useSelector(state => selectCustomerMessage(state))
    const hintCustomerMessage = useSelector(state => selectTerminartKundenHinweisText(state))
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const setCustomerMessage = msg => dispatch(changeCustomerMessage(msg))
    const addImage = (image) => dispatch(addTempImage(image))
    const removeImage = (image) => dispatch(removeTempImage(image))
    return (
        <React.Fragment>
            <CustomerComment images={images}
                             isImageUploading={isImageUploading}
                             customerMessage={customerMessage}
                             hintCustomerMessage={hintCustomerMessage}
                             showHint={true}
                             t={t}
                             setCustomerMessage={setCustomerMessage}
                             addImage={addImage}
                             removeImage={removeImage}
            />
        </React.Fragment>
    )
}

export default CustomerCommentContainer
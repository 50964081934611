import React, {useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import qs from "qs";
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from "react-redux";
import {initTheming, setDb} from "../store/common/commonActions";
import {
    clearState,
    getDbPrefix,
    loadPersistedThemingState,
    removeDbPrefix,
    removeTheming,
    savePersistedThemingState
} from "../api/localPersistence";
import BookingFlow from "./bookingflow/BookingFlow";
import {selectDB, selectTheming} from "../store/common/commonSelectors";
import CheckoutContainer from "./checkout/CheckoutContainer";
import BackendErrorContainer from "./error/BackendErrorContainer";
import ConfigurationErrorContainer from "./error/ConfigurationErrorContainer";
import VoucherContainer from "./voucher/creation/VoucherContainer";
import VoucherCompletedContainer from "./voucher/completed/VoucherCompletedContainer";
import {ALL_TEHMING_FIELDS, INITIAL_DARK_THEME, INITIAL_LIGHT_THEME} from "../store/common/commonReducer";
import history from "../history";
import {NO_STUDIO_GIVEN} from "../store/common/commonSaga";
import BookingErrorContainer from "./error/BookingErrorContainer";
import Impressum from "./impressum/Impressum";

export const replaceIfGiven = (base, name, value) => {
    if (value) {
        return {...base, [name]: value}
    }
    return base
}

export const replaceAllIfGiven = (base, names, obj) => {
    let newObj = {...base}
    names.forEach(name => {
        newObj = replaceIfGiven(newObj, name, obj[name])
    })
    return newObj
}

const hasFieldSet = (obj, fields) => fields.reduce((pv, cv) => pv || !!obj[cv], false)

const OnlineContainer = (props) => {
    const db = useSelector(state => selectDB(state));
    let theming = useSelector(state => selectTheming(state));
    const dispatch = useDispatch();

    const setThemingByQueryParams = (query) => {
        if (hasFieldSet(query, ALL_TEHMING_FIELDS)) {
            theming = query.mode === 'dark' ? INITIAL_DARK_THEME : INITIAL_LIGHT_THEME
        }
        let newTheming = replaceAllIfGiven(theming, ALL_TEHMING_FIELDS, query)
        dispatch(initTheming(newTheming));
        savePersistedThemingState(newTheming);
    }

    const initializeTheming = (query) => {
        if (!hasFieldSet(query, ALL_TEHMING_FIELDS)) {
            const persistedTheming = loadPersistedThemingState()
            if (persistedTheming) {
                dispatch(initTheming(persistedTheming))
            }
        } else {
            setThemingByQueryParams(query)
        }
    }

    useEffect(() => {
        if (!db) {
            const query = qs.parse(props.location.search, {ignoreQueryPrefix: true});
            initializeTheming(query)
            const dbStudio = getDbPrefix()
            if (!dbStudio && !query.dbstudio) {
                history.push({
                    pathname: '/config-error',
                    search: "?" + new URLSearchParams({error: NO_STUDIO_GIVEN}).toString()
                })
                return
            }
            if (query.dbstudio && dbStudio && query.dbstudio !== dbStudio) {
                clearState()
                removeDbPrefix()
                removeTheming()
                dispatch(setDb(query.dbstudio))
                return
            }
            if (dbStudio) {
                dispatch(setDb(dbStudio))
                return
            }
            if (query.dbstudio) {
                dispatch(setDb(query.dbstudio))
            }
        }

    });

    const getThemeOptions = () => {
        return {
            palette: {
                mode: theming.mode
                , primary: {
                    main: theming.primary
                },
                background: {
                    paper: theming.background,
                    default: theming.background
                }

            },
            text: {
                primary: theming.textcolor
            },
            typography: {
                allVariants: {
                    color: theming.textcolor
                },
            },
            components: {
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            color: theming.textcolor
                        }
                    }
                }
            }
        }
    }

    const theme = createTheme(getThemeOptions())

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/" exact component={BookingFlow}/>
                    <Route path="/gutschein/complete/:checkoutid" exact component={VoucherCompletedContainer}/>
                    <Route path="/gutschein" exact component={VoucherContainer}/>
                    <Route path="/checkout" component={CheckoutContainer}/>
                    <Route path="/stripeconfirm" component={CheckoutContainer}/>
                    <Route path="/config-error" component={ConfigurationErrorContainer}/>
                    <Route path="/bookingerror" component={BookingErrorContainer}/>
                    <Route path="/error" component={BackendErrorContainer}/>
                    <Route path="/impressum" component={Impressum}/>
                </Switch>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default OnlineContainer

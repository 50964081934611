import {createSimpleAction} from "../utils";

export const PENDING_TERMINKAUTION_PAYMENT = 'PENDING_TERMINKAUTION_PAYMENT'
export const PENDING_VOUCHER_PAYMENT = 'PENDING_VOUCHER_PAYMENT'
export const PENDING_PAYMENT_CLEARED = 'PENDING_PAYMENT_CLEARED'
export const LOAD_PERSISTED_CHECKOUT_STATE = 'LOAD_PERSISTED_CHECKOUT_STATE'
export const PERSISTED_CHECKOUT_STATE_LOADED = 'PERSISTED_CHECKOUT_STATE_LOADED'
export const TERMINKAUTION_PAYMENT_TYPE = 'TERMINKAUTION'
export const VOUCHER_PAYMENT_TYPE = 'VOUCHER'
export const CHECKOUTID_REGISTERED = 'CHECKOUTID_REGISTERED'
export const PAYPAL_PAYMENT_SUCCESS = 'PAYPAL_PAYMENT_SUCCESS'
export const PAYPAL_PAYMENT_FAILURE = 'PAYPAL_PAYMENT_FAILURE'
export const EXECUTE_PAYPAL_ORDER = 'EXECUTE_PAYPAL_ORDER'
export const INITIATE_PAY_LATER = 'INITIATE_PAY_LATER'
export const PAY_LATER_SUCCESS = 'PAY_LATER_SUCCESS'
export const PAY_LATER_FAILURE = 'PAY_LATER_FAILURE'
export const INITIATE_SOFORT_PAYMENT = 'INITIATE_SOFORT_PAYMENT'
export const ABORT_SOFORT_PAYMENT = 'ABORT_SOFORT_PAYMENT'
export const SOFORT_PAYMENT_INITIATED_SUCCESS = 'SOFORT_PAYMENT_INITIATED_SUCCESS'
export const SOFORT_CHECK_CHECKOUT_STATUS = 'SOFORT_CHECK_CHECKOUT_STATUS'
export const SOFORT_CHECK_CHECKOUT_STATUS_SUCCESS = 'SOFORT_CHECK_CHECKOUT_STATUS_SUCCESS'
export const PROCESS_TERMIN_BOOKING_PAY_LATER = 'PROCESS_TERMIN_BOOKING_PAY_LATER'
export const PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS = 'PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS'
export const PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE = 'PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE'

export const createTerminkautionspayment = () => ({
    type: PENDING_TERMINKAUTION_PAYMENT
    , payload: {
        payment: {
            pendingPaymentType: TERMINKAUTION_PAYMENT_TYPE
        }
    }
});

export const registerCheckoutid = createSimpleAction(CHECKOUTID_REGISTERED, 'checkoutId')
export const loadPersistedCheckoutStateAction = () => ({type: LOAD_PERSISTED_CHECKOUT_STATE})
export const persistedCheckoutLoadedIntoStore = (checkoutState) => ({
    type: PERSISTED_CHECKOUT_STATE_LOADED,
    payload: checkoutState
});
export const paypalSuccess = createSimpleAction(PAYPAL_PAYMENT_SUCCESS, 'paypalOrderId')
export const paypalFailure = () => ({type: PAYPAL_PAYMENT_FAILURE})
export const initiateSofortPaymentAction = createSimpleAction(INITIATE_SOFORT_PAYMENT, 'isRetry');
export const abortSofortPayment = () => ({type: ABORT_SOFORT_PAYMENT})
export const payLater = () => ({type: INITIATE_PAY_LATER})
export const payLaterSuccess = () => ({type: PAY_LATER_SUCCESS})
export const payLaterFailure = () => ({type: PAY_LATER_FAILURE})

export const pendingPaymentCleared = () => ({type: PENDING_PAYMENT_CLEARED})
export const processTerminbookingAndPayLaterSuccess = () => ({type: PROCESS_TERMIN_BOOKING_PAY_LATER_SUCCESS})
export const processTerminbookingAndPayLaterFailure = () => ({type: PROCESS_TERMIN_BOOKING_PAY_LATER_FAILURE})
export const executePaypalOrderAction = createSimpleAction(EXECUTE_PAYPAL_ORDER, 'paypalOrder');
export const sofortInitiated = createSimpleAction(SOFORT_PAYMENT_INITIATED_SUCCESS, 'stripeClientSecret')
export const checkCheckoutStatusAction = () => ({type: SOFORT_CHECK_CHECKOUT_STATUS});
export const setCheckoutStatus = createSimpleAction(SOFORT_CHECK_CHECKOUT_STATUS_SUCCESS, 'checkoutStatus')
export const processTerminbookingAndPayLaterAction = () => ({type: PROCESS_TERMIN_BOOKING_PAY_LATER})

export const createVoucherspayment = () => ({
    type: PENDING_VOUCHER_PAYMENT
    , payload: {
        payment: {
            pendingPaymentType: VOUCHER_PAYMENT_TYPE
        }
    }
});
import "core-js/proposals/string-replace-all"
import React from "react";
import ReactDOM from 'react-dom';
import App from "./components/App";
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './store/sagas';
import reducers from './store/reducers';
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

import './i18n';
import './axiosConfig';

sagaMiddleware.run(rootSaga)

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.querySelector('#root')
);

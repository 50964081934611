import React from "react";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {numberFormat} from "../../../utils/Localization";

export const VoucherInfoCard = ({t, lang, waehrung, voucher}) => {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{t('GUTSCHEIN', {
                            von: voucher.von,
                            fuer: voucher.fuer
                        })}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" style={{fontStyle: 'italic'}}>{voucher.message}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: 'bold'}} variant="subtitle1">{t('Betrag')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{numberFormat(voucher.betrag, lang, waehrung)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: 'bold'}}
                                    variant="subtitle1">{t('VOUCHER_EMAIL')}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1">{voucher.email}</Typography>
                    </Grid>

                </Grid>

            </CardContent>
        </Card>
    )
}

export default VoucherInfoCard
import React from "react";
import {Alert} from '@mui/material';
import {useTranslation} from "react-i18next";
import Cable from "../../public/images/cable.jpg";
import StandardContainer from "../common/style/StandardContainer";
import Heading from "../common/style/Heading";

const errorCodes = {
    NO_STUDIO_GIVEN: 101,
    CHECK_ABO_FAILED: 102,
    PAYMENT_SERVICE_MISSING: 201,
    VOUCHER_ISSUER_ARTIST_MISSING: 202,
    MAIL_CONFIGURATION: 203
}

const ConfigurationError = ({error}) => {
    const {t} = useTranslation()
    return (
        <StandardContainer>
            <Heading>{t('CONFIGURATION_ERROR')}</Heading>
            <img src={Cable} width="100%"/>
            <Alert severity="error">{t(error, {error: errorCodes[error]})}</Alert>
        </StandardContainer>
    )
}
export default ConfigurationError
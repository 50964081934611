import React, {useEffect, useState} from "react";
import VoucherCompleted from "./VoucherCompleted";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectIsVoucherLoadedFromCheckout, selectVoucherState} from "../../../store/voucher/voucherSelectors";
import {selectCurrency, selectDB, selectGutscheinVorlagen, selectLanguage} from "../../../store/common/commonSelectors";
import {loadVoucherFromCheckout} from "../../../store/voucher/voucherActions";
import {selectStateLoaded} from "../../../store/appointment/appointmentSelectors";
import {downloadVoucher} from "../../../utils/download";

const VoucherCompletedContainer = () => {
    const [selectedVoucherVorlage, setSelectedVoucherVorlage] = useState('')
    const stateLoaded = useSelector(state => selectStateLoaded(state))
    const voucherVorlagen = useSelector(state => selectGutscheinVorlagen(state))
    const db = useSelector(state => selectDB(state))
    const lang = useSelector(state => selectLanguage(state))
    const waehrung = useSelector(state => selectCurrency(state))
    const {checkoutid} = useParams()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const voucherData = useSelector(state => selectVoucherState(state))
    const isLoaded = useSelector(state => selectIsVoucherLoadedFromCheckout(state))

    useEffect(() => {
        if (stateLoaded && !!checkoutid) {
            dispatch(loadVoucherFromCheckout(checkoutid))
        }
    }, [stateLoaded, checkoutid, dispatch])

    useEffect(() => {
        if (!selectedVoucherVorlage && !!voucherVorlagen?.length) {
            setSelectedVoucherVorlage(voucherVorlagen[0])
        }
    }, [selectedVoucherVorlage, voucherVorlagen])

    const onDownloadVoucher = (vorlagenId) => {
        downloadVoucher(db, checkoutid, vorlagenId)
    }

    const onChangeVoucher = id => {
        const voucherVorlage = voucherVorlagen.find(t => t.PDFVorlagenID === id)
        setSelectedVoucherVorlage(voucherVorlage)
    }

    return (<VoucherCompleted t={t}
                              selectedVoucherVorlage={selectedVoucherVorlage}
                              setSelectedVoucherVorlage={onChangeVoucher}
                              voucherVorlagen={voucherVorlagen}
                              isLoaded={isLoaded}
                              onDownloadVoucher={onDownloadVoucher}
                              voucherData={voucherData} lang={lang} waehrung={waehrung}/>)
}

export default VoucherCompletedContainer
import {replacePayloadFields} from "../utils";
import {
    CHANGE_VOUCHER_BETRAG,
    CHANGE_VOUCHER_EMAIL,
    CHANGE_VOUCHER_FUER,
    CHANGE_VOUCHER_MESSAGE,
    CHANGE_VOUCHER_VON,
    LOAD_PERSISTED_VOUCHER,
    VOUCHER_FROM_CHECKOUT_LOADED
} from "./voucherActions";
import {NEW_BOOKING} from "../appointment/appointmentActions";

const INITIAL = {
    von: '',
    fuer: '',
    email: '',
    betrag: 50,
    loadedFromCheckout: false
}

export default function VoucherReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_PERSISTED_VOUCHER:
        case CHANGE_VOUCHER_VON:
        case CHANGE_VOUCHER_FUER:
        case CHANGE_VOUCHER_BETRAG:
        case CHANGE_VOUCHER_EMAIL:
        case CHANGE_VOUCHER_MESSAGE:
            return replacePayloadFields(state, action);

        case VOUCHER_FROM_CHECKOUT_LOADED:
            return {
                ...replacePayloadFields(state, action)
                , loadedFromCheckout: true
            }
        case NEW_BOOKING:
            return INITIAL
        default:
            return state;
    }
}
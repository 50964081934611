import React from 'react';
import {Box, FormControl, MenuItem, Select} from "@mui/material";
import {getFlagEmoji} from "../../utils/Localization";

const availableLanguages = [
    {lng: 'de', flag: getFlagEmoji('DE')},
    {lng: 'en', flag: getFlagEmoji('GB')},
    {lng: 'it', flag: getFlagEmoji('IT')},
    {lng: 'fr', flag: getFlagEmoji('FR')}
]


const LangSelector = ({isoLang, onChange}) => {

    return (
        <FormControl sx={{ m: 1, minWidth: 50}} variant="standard" size="small">
            <Select sx={{paddingBottom: 0}}
                id="languageSelector"
                value={isoLang || 'de'}
                onChange={e => onChange && onChange(e.target.value)}
                disableUnderline
            >
                {availableLanguages.map(item => (<MenuItem key={item.lng} value={item.lng}><Box sx={{paddingTop: '5px'}}>{item.flag}</Box></MenuItem>) )}
            </Select>
        </FormControl>
    );
};

export default LangSelector;
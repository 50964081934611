import {
    CHANGE_BIRTHDAY,
    CHANGE_DESEASES,
    CHANGE_EMAIL,
    CHANGE_KONTAKTART,
    CHANGE_MOBILE,
    CHANGE_NACHNAME,
    CHANGE_VORNAME,
    EMAIL_CHECK_BAD_REQUEST,
    EMAIL_CHECK_RESEND,
    EMAIL_CHECK_STARTED,
    EMAIL_HASH_RECEIVED,
    LOAD_PERSISTED_KUNDE,
    VERIFY_EMAIL_FAILED,
    VERIFY_EMAIL_STARTED,
    VERIFY_EMAIL_SUCCESS
} from "./kundeActions";
import {replacePayloadFields} from "../utils";

const INITIAL = {
    vorname: '',
    nachname: '',
    email: '',
    mobile: '',
    kontaktart: 2,
    deseases: '',
    birthday: new Date().toISOString()
};

export default function KundeReducers(state = INITIAL, action) {
    switch (action.type) {
        case CHANGE_VORNAME:
        case CHANGE_NACHNAME:
        case CHANGE_BIRTHDAY:
        case LOAD_PERSISTED_KUNDE:
        case CHANGE_MOBILE:
        case CHANGE_KONTAKTART:
        case CHANGE_DESEASES:
            return replacePayloadFields(state, action);

        case EMAIL_CHECK_STARTED:
            return {
                ...state,
                emailCheckBadRequest: undefined
            }
        case EMAIL_HASH_RECEIVED:
            return {
                ...replacePayloadFields(state, action),
                emailVerificationSuccess: undefined,
                emailCheckBadRequest: undefined
            };
        case EMAIL_CHECK_BAD_REQUEST:
            return {
                ...state,
                ...replacePayloadFields(state, action)
            }
        case CHANGE_EMAIL:
            return {
                ...replacePayloadFields(state, action),
                emailVerificationSuccess: undefined,
                emailHash: undefined,
                emailCheckBadRequest: undefined
            };
        case VERIFY_EMAIL_STARTED:
            return {
                ...state
                , verifyEmailOngoing: true
            }
        case VERIFY_EMAIL_FAILED:
            return {
                ...state,
                emailVerificationSuccess: false
                , verifyEmailOngoing: undefined
            };
        case VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                emailVerificationSuccess: true
                , verifyEmailOngoing: undefined
            };
        case EMAIL_CHECK_RESEND:
            return {
                ...state,
                emailVerificationSuccess: undefined,
                emailHash: undefined
            }
        default:
            return state;
    }
}

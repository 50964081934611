export const italiano = {
    "Produktkategorie": "Categoria di Prodotto",
    "Produkt": "Prodotto",
    "PRODUKTI": "Prodotto I",
    "PRODUKTII": "Prodotto II",
    "Termin": "Appuntamento",
    "BESTAETIGUNG": "Controlla",
    "Egal": "Non importa",
    "TERMIN": "Appuntamento",
    "Rubrik": "Categoria",
    "RUBRIK": "Categoria",
    "Terminart": "Appuntamento Tip",
    "RESERVATIONHINT": "Riserveremo lo slot per 30 minuti dalla selezione per te. Si prega di completare la prenotazione fino ad allora.",
    "PREISNACHVEREINBARUNG": "n.V.: FPer questi prodotti il ​​prezzo sarà stabilito previo accordo.",
    "ANZAHLUNG": "Acconto: {{ anzahlung }}.",
    "KOSTENPFLICHTIG_BUCHEN": "prenota con costi",
    "VERBINDLICH_BUCHEN": "prenotazione vincolante",
    "ANFRAGE_SENDEN": "invia richiesta",
    "(Preis nach Vereinbarung)": "(prezzo previo accordo)",
    "Bezahlung": "Pagamento",
    "BEZAHLUNG_ABGESCHLOSSEN": "Pagamento riuscito.",
    "BEZAHLE_SPAETER": "Pagherò più tardi",
    "ZAHLUNGSPROZESS_LAEUFT": "Elaborazione del pagamento",
    "BUCHUNGSPROZESS_LAEUFT": "Elaborazione d. prenotazione",
    "VERBINDUNGSFEHLER": "Errore di connessione",
    "VERBINDUNGSAUFBAU_NICHT_MOEGLICH": "Spiacenti, non è possibile stabilire una connessione al server. Per favore prova più tardi...",
    "VERBINDUNG_PRUEFEN": "Controlla Connessione",
    "BUCHUNG_ERFOLGREICH": "La prenotazione è andata a buon fine. È stata inviata un'email a {{email}}.",
    "GUTSCHEIN_VERSANDT": "Un'email con il voucher è stata inviata a {{email}}.",
    "WEITERE_BUCHUNG": "Nuova Prenotazione",
    "WEITERER_GUTSCHEIN": "Nuovo Buono",
    "CHECK_ABO_FAILED": "Sembra esserci un problema di configurazione. Si prega di contattare lo studio. (Code={{error}})",
    "NO_STUDIO_GIVEN": "Sembra esserci un problema di configurazione. Si prega di contattare lo studio. (Code={{error}})",
    "CONFIGURATION_ERROR": "Errore",
    "ZAHLUNG_FEHLGESCHLAGEN": "Il pagamento è stato annullato o non è riuscito. Nessun pagamento è stato elaborato. Provaci ancora.",
    "PAYMENT_SERVICE_MISSING": "Sembra esserci un problema di configurazione. Si prega di contattare lo studio. (Code={{error}})",
    "VOUCHER_ISSUER_ARTIST_MISSING": "Sembra esserci un problema di configurazione. Si prega di contattare lo studio. (Code={{error}})",
    "MAIL_CONFIGURATION": "Sembra esserci un problema di configurazione. Si prega di contattare lo studio. (Code={{error}})",
    "Betrag": "Quantità",
    "VOUCHER_MESSAGE_LABEL": "Il tuo messaggio",
    "KAUFE_GUTSCHEIN": "Acquista Buono",
    "GUTSCHEIN": "Buono da {{von}} a {{fuer}}",
    "GUTSCHEIN_DOWNLOAD": "Buono - Scarica",
    "VOUCHER_TEMPLATE": "Modello di buono",
    "VOUCHER_EMAIL": "Email",
    "VOUCHER_NACHRICHT": "Messaggio",
    "CONFIRMATION.HEADER": "Controlla e conferma",
    "KUNDE": "Cliente",
    "PRODUKT": "Prodotto",
    "TERMINART": "Categoria",
    "MESSAGE": "Messaggio",
    "WEITERE_INFOS": "Inviaci un messaggio o un'immagine modello!",
    "KUNDE_MINDESTALTER_HINT": "Dovresti avere almeno 14 anni!",
    "EMAIL_CONFIRMATION_INITIATED": "Abbiamo inviato un codice di conferma all'indirizzo e-mail fornito. Si prega di compilare qui per confermare.",
    "EMAIL_CONFIRMATION_PROBLEM": "Sembra che ci sia un problema nella conferma dell'indirizzo e-mail. Si prega di contattare lo studio!",
    "EMAIL_CONFIRMATION_SUCCESS": "L'indirizzo email è stato confermato. Puoi chiudere la finestra di dialogo!",
    "EMAIL_CONFIRMATION_FAILED": "Il segreto non è corretto!",
    "EMAIL_CONFIRMATION_TITLE": "Email Conferma",
    "EMAIL_CONFIRMATION_CODE": "Codice di Conferma",
    "CONFIRMATION_USER_MESSAGE": "Il tuo messaggio per noi",
    "KUNDE_VORNAME_LABEL": "Nome",
    "KUNDE_NACHNAME_LABEL": "Cognome",
    "KUNDE_EMAIL_LABEL": "Email",
    "KUNDE_BIRTHDAY_LABEL": "Compleanno",
    "KUNDE_MOBILE_LABEL": "Mobile",
    "KUNDE_DESEASES_LABEL": "Malattie/Allergie",
    "TERMINWAHL_TERMIN_BLOCKED": "Purtroppo questa data è stata presa. BPer favore, scegline un altro!",
    "CHECKOUT_TYPE": "Tipo",
    "CHECKOUT_TERMINKAUTION": "Acconto",
    "CUSTOMER_MESSAGE_HEADING": "Il tuo messaggio per noi",
    "BOOKING_ERROR": "Errore di prenotazione",
    "BOOKING_ERROR_MESSAGE": "Probabilmente la prenotazione è stata effettuata, ma si è verificato un problema nell'invio della mail di conferma. Si prega di contattare lo studio.",
    "BOOKING_ERROR_MESSAGE_WITH_CHECKOUTID": "Probabilmente la prenotazione è stata effettuata, ma si è verificato un problema nell'invio della mail di conferma. Si prega di contattare lo studio e fornire il numero di pagamento {{paymentId}}.",
    "(Kostenfrei)": "gratuito",
    "NO_TIMESLOT_AVAILABLE": "Purtroppo non è disponibile una fascia oraria",
    "KUNDE_HEADING": "I tuoi dati",
    "PRODUKTWAHL_CHOOSE_MAX_ONE_PRODUKT_ALERT": "Si prega di scegliere un prodotto!",
    "PRODUKTWAHL_CHOOSE_MAX_PRODUKT_ALERT": "Si prega di scegliere i prodotti - {{maxProdukte}} massimo!",
    "PRODUKTWAHL_CHOOSE_PRODUKT_ALERT": "Per favore scegli i tuoi prodotti!",
    "TERMINWAHL_CHOOSE_LOCATION_ALERT": "Per favore, prima scegli una filiale!",
    "TERMINWAHL_HEADING": "Selezione data",
    "FILEINPUT.BUTTON_TEXT_ADD_PIC":"Allegare Foto",
    "KUNDE.EMAIL_CHECK_HELPER_TEXT": "L'email deve essere verificata!",
    "KUNDE.EMAIL_CHECK_BUTTON_TEXT": "Verificare",
    "KUNDE.CONTACT_LABEL_TEXT": "Tipo di contatto preferito",
    "BUTTON_TEXT_CLOSE": "Chiudere",
    "BUTTON_TEXT_CHECK": "Verificare",
    "BUTTON_TEXT_RESEND": "inviare nuovamente",
    "CONFIRMATION.PRIVACY_AGREEMENT_READ": "Ho letto l'accordo sulla protezione dei dati.",
    "VOUCHER_FUER": "per",
    "VOUCHER_VON": "da",
    "LEGAL_FOOTER.LINK_TEXT_DATENSCHUTZ": "Protezione dei dati",
    "LEGAL_FOOTER.LINK_TEXT_IMPRESSUM": "Informazione legale"
}
import React from "react";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const VoucherDownload = ({
                             voucherVorlagen,
                             onChangeVoucherVorlage,
                             selectedVoucherVorlage,
                             onDownloadVoucher,
                             t
                         }) => {

    const SelectionItem = (id, bezeichnung) => {
        return (
            <MenuItem key={id} value={id}>{bezeichnung}</MenuItem>
        )
    }

    const renderVorlagenSelection = () => {
        if (!voucherVorlagen || !voucherVorlagen.length) {
            return null;
        }
        return (
            <Grid container alignItems="center" style={{margin: '20px 0'}}>
                <Grid item xs={9}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="voucher-label">{t('VOUCHER_TEMPLATE')}</InputLabel>
                        <Select
                            labelId="voucher-label"
                            id="voucher-Selection"
                            value={selectedVoucherVorlage?.PDFVorlagenID}
                            onChange={e => onChangeVoucherVorlage(e.target.value)}
                        >
                            {voucherVorlagen.map(t => SelectionItem(t.PDFVorlagenID, t.Bezeichnung))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} justifyContent="center" style={{paddingLeft: "10px"}}>
                    <Button aria-label="download" variant="contained"
                            onClick={() => onDownloadVoucher(selectedVoucherVorlage.PDFVorlagenID)}
                            size="large" fullWidth>
                        <DownloadIcon/>
                    </Button>
                </Grid>
            </Grid>

        )
    }

    const renderDownloadButton = () => {
        return (
            <Button style={{marginBottom: "20px"}}
                    aria-label="download" variant="contained" size="large"
                    onClick={() => onDownloadVoucher(selectedVoucherVorlage.PDFVorlagenID)}
                    endIcon={<DownloadIcon/>} fullWidth>
                Download
            </Button>
        )
    }

    return (
        <React.Fragment>
            {!!voucherVorlagen && voucherVorlagen.length > 1 ? renderVorlagenSelection() : renderDownloadButton()}
        </React.Fragment>
    )
}

export default VoucherDownload